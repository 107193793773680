import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CartIcon from '../../Assets/images/CartIcon.png';
import CartSearch from '../../Assets/images/Cart-search.png';
import Location from '../../Assets/images/distance.svg';
import Recommended_center from '../../Assets/images/recommended-center.png';
import Stars from '../../Assets/images/stars.png';

const Packages = () => {
    // Initialize an array of liked states for each package item
    const [likedItems, setLikedItems] = useState(Array(8).fill(false));

    // Toggle the liked status of a specific item
    const handleHeartClick = (index) => {
        setLikedItems((prevState) => 
            prevState.map((liked, i) => (i === index ? !liked : liked))
        );
    };

    return (
        <div className='homepageWrapper'>
            <div className='client-container'>
                <div className='clientprofile-sec'>
                    <div className='profile-title'>
                        <Link to='/'>
                            <img src={Location} alt='Location-img' />
                            <div className="Address-cont">
                                <h3 className='client-name'>Nizampet</h3>
                                <span>Hyderabad, Telangana</span>
                            </div>
                        </Link>
                    </div>
                    <div className='cart-cont'>
                        <Link to='/'>
                            <img src={CartIcon} alt='cart-icon' />
                            <span className='count-t'>2</span>
                        </Link>
                    </div>
                </div>
                <div className='GlobalSearch'>
                    <form className='needs-validation clearfix'>
                        <div className='flex-cont dateinput-cont'>
                            <div className='form-group offer-rel'>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='offer_name'
                                    defaultValue=''
                                    placeholder='Search Medicine & Diagnosis'
                                    required
                                />
                                <span className='search-btn'>
                                    <img src={CartSearch} alt='search' />
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='packages-cont'>
                    {likedItems.map((liked, index) => (
                        <div className='package-det' key={index}>
                            <figure>
                                <Link to=''>
                                    <div className='img-wrap'>
                                        <img src={Recommended_center} title='alt-ads' alt='alt-ads'></img>
                                        <div className='star-rating'>
                                            <img src={Stars} alt='star' title='star'></img>
                                            <span className='ratting-count'>4.6</span>
                                            <span>(456)</span>
                                        </div>
                                    </div>
                                    <figcaption>                                
                                        <div className='name-like'>
                                            <h3>Lucid Medical Diagnostics</h3>
                                            <span
                                                id={`heart-${index}`}
                                                onClick={() => handleHeartClick(index)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {liked ? (
                                                    <i className="fa fa-heart" aria-hidden="true"></i>
                                                ) : (
                                                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                                                )}
                                            </span>
                                        </div>
                                        <span className='category'>Pragatinagar</span>
                                        <ul className='delevery-msg'>
                                            <li>4.6Km Distance</li>
                                            <li>Delivery in 24 h</li>
                                        </ul>
                                    </figcaption>
                                </Link>
                            </figure>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Packages;
