import React, { useState, useEffect,useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Table, Button, Space,Select } from 'antd';
import EditIcon from '../Usermanagement/EditIcon';
import Trash from '../../Assets/images/recycle-bin.png';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService'; 
import TabMenu from './TabMenu';
import ViewIcon from './View';
import PackagesData from './PackagesData';
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Filter from "../../Assets/images/share.png";
import Close from '../../Assets/images/close.png';
import { usePermissions } from '../../../Services/PermissionsContext'; // Import the permissions context
import { hasPermission } from '../../../Services/checkPermissions';   // Import the helper function

const Packages = () => {
    const navigate = useNavigate();
    const permissions = usePermissions(); 
    const dateRef = useRef(null);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isActive, setActive] = useState(false);
    const { organization } = useContext(OrganizationContext); 
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedPackage, setselectedPackage] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState(false);
    const [formData, setFormData] = useState({
        search: '',
    });

    const [status, setStatus] = useState("Status");
    useEffect(() => {
        if (organization ) {
            loadPackages(status);
        }
      }, [organization,status]);
    
      const loadPackages = async () => {
        const token = authService.getCurrentadmin().token; 
        const url = new URL(authService.getApiUrl() + 'labtestpackage');
        url.searchParams.append('organization_id', organization);
    
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: `Bearer ${token}`, 
                },
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch packages');
            }
    
            const result = await response.json();
            console.log('Fetched packages:', result); 
            setPackages(result.data); 
            setLoading(false);
        } catch (error) {
            console.error('Error fetching packages:', error);
            setLoading(false);
        }
    };
    
    const handleMenuItemClick = () => {
        setActiveMenuItem(!activeMenuItem);
    }
    const showModal = (record) => {
      setselectedPackage(record);
      setIsModalVisible(true);
      };
  
 
    const closeModal = (record) => {
        setIsModalVisible(false);
        setselectedPackage(record); 
        };

    const handleNotifyClick = () => {
          setDropdownVisible(!isDropdownVisible);
          setActive(!isActive);
        };

    const handleInputChange = (event) => {
          const { name, value } = event.target;
          setFormData({
              ...formData,
              [name]: value,
          });

          if (value.trim() === '') {
              loadPackages();
          }
      };

      const handleStatusChange = (value) => {
        const token = authService.getCurrentadmin().token; 
    
        fetch(authService.getApiUrl() + "labtestpackagesstatus", {
          method: "PUT",
          body: JSON.stringify({
            Is_Active: value=='Active'?'A':'I',
            labtestpackages_id: selectedRows,
            organization_id:organization,
          }), 
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`, 
          },
        })
          .then((res) => res.json())
          .then((result) => {
            loadPackages();
          })
          .catch((err) => {
            console.log(err.message); 
          });
      };

 
    
      const handleEdit = (record) => {
          navigate(`/admin/lab-management/edit-package/${record.id}`); 
      };
  
      const handleSearch = async () => {
          const token = authService.getCurrentadmin().token;

          try {
              const response = await fetch(authService.getApiUrl() + 'labtestpackagefilter', {
                  method: 'POST',
                  body: JSON.stringify({
                      search: formData.search,
                      organization_id: organization,
                  }),
                  headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json; charset=UTF-8',
                      Authorization: `Bearer ${token}`,
                  },
              });

              if (!response.ok) {
                  throw new Error('Failed to search packages');
              }

              const result = await response.json();
              setPackages(result.data);  
          } catch (error) {
              console.error('Error searching packages:', error);
          }
      };
    
      const handleDelete = async (id) => {
          const token = authService.getCurrentadmin().token; 
          try {
              const response = await fetch(`${authService.getApiUrl()}labtestpackage/${id}`, {
                  method: 'DELETE',
                  headers: {
                      'Authorization': `Bearer ${token}`,
                  },
              });

              if (!response.ok) {
                  throw new Error('Failed to delete package');
              }

              loadPackages(); 
          } catch (error) {
              console.error('Error deleting package:', error);
          }
      };

      const columns = [
          {
              title: "#",
              dataIndex: "id",
              key: "index",
              render: (text, record, index) => <span>{index + 1}</span>,
            },
          {
              title: 'Package Name',
              dataIndex: 'package_name',
              key: 'package_name',
              sorter: (a, b) => a.package_name.localeCompare(b.package_name),
          },
          {
              title: 'Description',
              dataIndex: 'description',
              key: 'description',
              sorter: (a, b) => a.description.localeCompare(b.description),
          },
    
          {
              title: 'Price',
              dataIndex: 'price',
              key: 'price',
              sorter: (a, b) => a.price - b.price,
              render: (price) => `${price}`,
          }, 
          {
              title: 'Status',
              dataIndex: 'Is_Active',
              key: 'Is_Active',
              render: (text) => (
                  <span className={text === 'A' ? 'status-btn-green' : 'status-btn-red' }>
                      {text === 'A' ? 'Active' : 'Inactive'}
                  </span>
              ),
            },
          {
              title: 'Action',
              key: 'action',
              render: (_, record) => (
                  <Space size="middle">
                      {hasPermission(permissions, 'packages', 'edit') && 
                        <Button
                            type="text"
                            icon={<EditIcon />}
                            onClick={() => handleEdit(record)}
                        />
                      }
                      <Button
                          type="text"
                          icon={<ViewIcon />}
                          onClick={() => showModal(record)} 
                      />
                      {hasPermission(permissions, 'packages', 'delete') && 
                        <Button
                            type="text"
                            icon={<img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />}
                            onClick={() => handleDelete(record.id)} 
                        />
                      }
                  </Space>
              ),
          },
      ];
      const Packagedata = [
        {
            id: selectedPackage?.id,
            name: selectedPackage?.package_name,
            description: selectedPackage?.description,
            price: `₹${selectedPackage?.price}`,
            active: selectedPackage?.Is_Active === "A" ? "Active" : "Inactive",
            organization_name: selectedPackage?.organization_name,
            categorynames: Array.isArray(selectedPackage?.category_names) 
                ? selectedPackage.category_names.map(category => ({
                    categoryname: category.category_name,
                    testnames: Array.isArray(category.test_names) ? category.test_names : []
                })) 
                : [] 
        }
    ];
      // Define row selection configuration
  const rowSelection = {
    onChange: (selectedRowKeys, newSelectedRowKeys) => {
      console.log(
        `Selected Row Keys: ${selectedRowKeys}`,
        "Selected Rows: ",
        selectedRows
      );
      setSelectedRows(selectedRowKeys);
    },
  };

    
    return (
        <div className="main-container">
            <div className="container-fluid">
            <Breadcrumb/>
            <TabMenu name="packages"/>

                <div className="category-table">
                <div className="flex-cont">
                  <div className="heading">
                    <h3>Packages</h3>
                  </div>

              <div className="flex-cont add-cat category-filter">
                <div className="add-btn">
                {hasPermission(permissions, 'packages', 'add') && 
                  <Link to="/admin/lab-management/add-package">
                    Add Package
                  </Link>
                }
                </div>
                <div className="date-filter category-filter">
                  <div className="export-date">
                    <img src={Filter} alt="export" title="export"></img>
                    <span>Export Data</span>
                  </div>
                  <div
                    ref={dateRef}
                    className={`filter-drop-down ${isActive ? "active" : ""}`}
                    onClick={handleNotifyClick}
                  >
                    <div className="filter-element">
                      <h3 className="filter-title">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="18"
                          height="18"
                          viewBox="0 0 20 20"
                        >
                          <defs>
                            <clipPath id="reportsvg">
                              <rect
                                id="Rectangle_39"
                                data-name="Rectangle 39"
                                width="20"
                                height="20"
                                transform="translate(5770 1086)"
                                fill="#fff"
                              ></rect>
                            </clipPath>
                          </defs>
                          <g
                            id="reports_1"
                            data-name="reports 1"
                            transform="translate(-5770 -1086)"
                            clipPath="url(#reportsvg)"
                          >
                            <path
                              className="svg-stroke-color"
                              id="Path_99"
                              data-name="Path 99"
                              d="M5774.489,1089.638h11.585v12.731h-11.585v-9.835"
                              transform="translate(-0.281 -0.004)"
                              fill="none"
                              stroke="#333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              className="svg-stroke-color"
                              id="Path_100"
                              data-name="Path 100"
                              d="M5777.506,1093.44h5.309"
                              transform="translate(-0.281 -0.004)"
                              fill="none"
                              stroke="#333"
                              strokeLinecap="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              className="svg-stroke-color"
                              id="Path_101"
                              data-name="Path 101"
                              d="M5777.506,1093.44h5.309"
                              transform="translate(-0.281 2.56)"
                              fill="none"
                              stroke="#333"
                              strokeLinecap="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              className="svg-stroke-color"
                              id="Path_102"
                              data-name="Path 102"
                              d="M5777.506,1093.44h5.309"
                              transform="translate(-0.281 5.125)"
                              fill="none"
                              stroke="#333"
                              strokeLinecap="round"
                              strokeWidth="2"
                            ></path>
                          </g>
                        </svg>
                        Export/Import
                      </h3>
                    </div>
                    <div
                      className="filter-dropdown"
                      style={{ display: isDropdownVisible ? "block" : "none" }}
                    >
                      <ul>
                        <li className="active">Export Category</li>
                        <li>Import Category</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-heading tabs"></div>
                    <div className="filter-search">
                        <input type="text" className="category-search"  onChange={handleInputChange}
                    name="search" 
                    id="search" placeholder="Search" />
                         <Button onClick={handleSearch} type="submit">Search</Button>
                    </div>
                    <Select
                defaultValue={status}
                style={{ width: 150, marginLeft: 10,float:'right' }}
                onChange={handleStatusChange}
              >
                <option value="" selected='selected'>--Select--</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
            </Select>
                    <div className="hospitals-table" id="hospitals_table">
                        <Table
                         rowSelection={rowSelection} 
                            dataSource={packages}
                            columns={columns}
                            pagination={{ pageSize: 10 }}
                            loading={loading} 
                            rowKey="id" 
                        />
                    </div>
                </div>
            </div>
            {isModalVisible && selectedPackage && (
                <div className='user-popup'>
                    <div className="modal" onClick={closeModal}>
                        <div className='modal-dialog modal-lg'>
                            <div className="modal-content">
                                <div className='popup-headings'>
                                    <h3>Package Details</h3>
                                    <button type="button" className="close" onClick={closeModal}>
                                        <img src={Close} alt="close" title="close" />
                                    </button>
                                </div>
                                <div className='user-table first-table'>
                                    <div className='user-role'>
                                        <PackagesData data={Packagedata} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Packages;
