import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
// import CartIcon from '../../Assets/images/CartIcon.png';
// import Location from '../../Assets/images/distance.svg';
import Backbtn from '../../Assets/images/Back-btn.png';
import Down from '../../Assets/images/down.png'
import Calender from '../../Assets/images/Calendar.png';
import Phonepe from '../../Assets/images/phonepe.png'; 
import authService from '../../../Services/authService';
import Done from '../../Assets/images/Done.png';
import Address from '../Profile/Address';
import Close from '../../Assets/images/close.png';

const Cart = () => { 
    
    const location = useLocation(); // React Hook
    let Organization_id = location.pathname.split('/')[3];
    const [cartItems, setCartItems] = useState([]);
    const [organizationData, setOrganizationData] = useState([]);
    const [members, setMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false); // State to handle modal visibility
    const [errors, setErrors] = useState({});
    const [address, setAddress] = useState([]);
    const [defaultAddress, setDefaultAddress] = useState([]);

    useEffect(() => {

        loadCart();
        familyMembers();
        addressList();

    }, []); 

    useEffect(() => {
        // Calculate the total price whenever cartItems changes
        const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
        setTotalPrice(total);
    }, [cartItems]);

    const addressList = () => {
        const url = new URL(authService.getApiUrl() + 'Addresslist');
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ 
                user_id: authService.getCurrentuser().id,

            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((result) => {
    
                setAddress(result.data); // Set counts to the fetched quantities
                setDefaultAddress(result.default_address?.[0] || {});
                setActiveTab(null);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const loadCart = () => {
        const url = new URL(authService.getApiUrl() + 'cartdata');
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ 
                user_id: authService.getCurrentuser().id,
                organization_id: Organization_id
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((result) => {
    
                setCartItems(result.data.cartitems); // Set counts to the fetched quantities
                setOrganizationData(result.data.organization)
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const familyMembers = () => {
        const url = new URL(authService.getApiUrl() + 'userfamilylist');
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ 
                user_id: authService.getCurrentuser().id,
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((result) => {
    
                setMembers(result.data); // Set counts to the fetched quantities
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const handleInputChange = (event, key) => {
        const { value } = event.target;
        setSelectedMembers((prev) => ({
            ...prev,
            [key]: value, // Use the unique key
        }));
    };

    const now = new Date();

    const formatDateTime = (date) => {
        const yy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month (01-12)
        const dd = String(date.getDate()).padStart(2, '0'); // Day (01-31)
        const hours = String(date.getHours()).padStart(2, '0'); // Hours (00-23)
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Minutes (00-59)
        const seconds = String(date.getSeconds()).padStart(2, '0'); // Seconds (00-59)

        return `${yy}-${mm}-${dd} ${hours}:${minutes}:${seconds}`;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = {};
        const formattedDateTime = formatDateTime(now);

        const tests = cartItems.flatMap((item, index) => {
            const instances = [];
            for (let i = 0; i < item.quantity; i++) {
                const key = `${index}-${i}`;
                const selectedMember = selectedMembers[key];
                
                // Validation: Check if member is selected
                if (!selectedMember) {
                    validationErrors[key] = "Please select a family member.";
                }

                instances.push({
                    test_name: item.name,
                    test_id: item.test_id,
                    quantity: 1,
                    price: item.price,
                    preparation: item.preparation,
                    type: item.type,
                    family_member_id: selectedMember || "Unassigned",
                });
            }
            return instances;
        });

        // Update error state and stop submission if there are errors
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setErrors({}); // Clear errors if validation passes

        const payload = {
            user_id: authService.getCurrentuser().id,
            organization_id: Organization_id,
            order_items: tests,
            total_price: totalPrice,
            price: totalPrice,
            tax_amount: 30,
            date_time: formattedDateTime,
        };

        try {
            const response = await fetch(authService.getApiUrl() + 'Addorder', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            });
            const result = await response.json();

            if (response.ok) {
                //alert("Order placed successfully!");
                setIsModalVisible(true);
            } else {
                console.error(result.message || "Failed to place order");
            }
        } catch (error) {
            console.error(error.message);
        }
    };
    
    const showModal = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const [activeTab, setActiveTab] = useState(null);
    const openTab = (cityName) => {
        setActiveTab(cityName);
    };
   
    const closeTab = () => {
        setActiveTab(null);
    };
    const [activeDropdown, setActiveDropdown] = useState(null);
 
  const savedAddresses = [
    {
      title: "Home",
      address:
        "Veegam Software Pvt Ltd, Hydernagar, Akhila Excotica, Hyderabad",
      phoneNumber: "+91-9014232643",
    },
    {
      title: "Office",
      address: "Plot No. 12, Sector 1, IT Park, Gachibowli, Hyderabad",
      phoneNumber: "+91-9876543210",
    },
    {
      title: "Other",
      address: "Flat No. 102, Green Apartments, Kukatpally, Hyderabad",
      phoneNumber: "+91-1234567890",
    },
  ];
 
  const handleNotifyClick = (index) => {
    setActiveDropdown((prevIndex) => (prevIndex === index ? null : index));
  };
 

    return (
        <div className='homepageWrapper cartWrapper'>
            <div className='client-container cart-container'>
                <div className='clientprofile-sec cart-sec'>
                    <div className='back-btn-add'>
                        <Link to='#' onClick={() => window.history.back()}>
                            <img src={Backbtn} alt='Backbtn' />
                        </Link>
                    </div>
                    <div className='cart-add-title' >
                        <h1>{organizationData.name}</h1>
                        <div className='address-detail' >
                            <h3>{organizationData.landmark}</h3>
                            <p>{organizationData.area_name}, {organizationData.city_name}</p>
                            {/* <img className="view-more-icon" src={Down} alt='view-more'></img> */}
                        </div>
                    </div>
                    
                </div>
                {/* <div className="order-details-cont">
                    <div className='oderID-status'>
                        <h3>Order ID: R1X4KC</h3>
                        <h4>Status: <span>Upcoming</span></h4>
                    </div>
                </div> */}
                <div className='appointment-time'>
                    <div className='date-head'>
                        <h3>Date</h3>
                        <Link to="/lab-tests/appointment-time">Change</Link>
                    </div>
                    <div className='timmings'>
                        <img src={Calender} alt='calender' title='calender'></img>
                        <p>Wednesday, Jun 23, 2021 | 10:00 AM</p>
                    </div>
                </div>

                <div className='appointment-time' >
                    <div className='date-head'>
                        <h3>Address</h3>
                       <span onClick={() => openTab('address')} style={{cursor:'pointer',color:'#199A8E'}}>Change</span> 
                    </div>
                    <div className='timmings'>
                        <img src={Calender} alt='calender' title='calender'></img>
                        <p>
                            {defaultAddress.building_name
                                ? `${defaultAddress.building_name}, ${defaultAddress.area}, ${defaultAddress.landmark}, ${defaultAddress.pincode}`
                                : 'No default address set'}
                        </p>
                    </div>
                </div>

                <div className="cart-list">
                    {cartItems.map((data, index) => {
                         const items = [];

                        for (let i = 0; i < data.quantity; i++) {
                            const key = `${index}-${i}`; // Unique key for each dropdown

                            items.push(
                                <div className='cart-item' key={key}>
                                    <div className='cart-top'>
                                        <h3>{data.name} {data.quantity}</h3>
                                        <span className='test-cost'>₹{data.price}</span>
                                    </div>
                                    <div className='test-bottom'>
                                        <div className='select-time-member'>
                                            <select
                                                className="form-select form-control special-select"
                                                name="member"
                                                required
                                                value={selectedMembers[key] || ""}
                                                onChange={(e) => handleInputChange(e, key)}
                                            >
                                                <option value="">Select Member</option>
                                                {members.map((item) => (
                                                    <option value={item.id} key={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                            {errors[key] && (
                                                <span className="error-message" style={{ color: "red", fontSize: "12px" }}>
                                                    {errors[key]}
                                                </span>
                                            )}
                                        </div>
                                        <div className='select-time-member'>
                                            <div className='time-slot'>
                                                <span onClick={() => openTab('add_mem')}>Add Member</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        
                        return items;
                    })}
                    
                </div>
                <div className="payment-detail-container">
                    <h3 className="payment-detail-header">Payment Detail</h3>
                    <div className="payment-detail-row">
                        <span className="payment-detail-label">Price</span>
                        <span className="payment-detail-value">₹{totalPrice}</span>
                    </div>
                    <div className="payment-detail-row">
                        <span className="payment-detail-label">GST 18%</span>
                        <span className="payment-detail-value">₹30.00</span>
                    </div>
                    <div className="payment-detail-row faded">
                        <span className="payment-detail-label">Additional Discount</span>
                        <span className="payment-detail-value">-</span>
                    </div>
                    <div className="payment-detail-row total">
                        <span className="payment-detail-label">Total</span>
                        <span className="payment-detail-value">₹{totalPrice}</span>
                    </div>
                </div>
                <div className='payment-method-cont'>
                    <h3 className="payment-detail-header">Payment Method</h3>
                    <div className='payment-method'>
                        <img src={Phonepe} alt='' title=''></img>
                        <Link to="/">Change</Link>
                    </div>
                </div>

            </div>
            <div className='payment-btn-cont'>
                <div className="client-container">
                    <div className='payment-btn-detail'>
                        <h3>Total</h3>
                        <span className='test-cost'>₹ {totalPrice}.00</span>
                    </div>
                    <div className='payment-btn'>
                        <button className='add-test-btn' type="button" onClick={handleSubmit}>Place order & Pay</button>
                    </div>
                </div>
            </div>
            
            <div className='test-filter'>
                <div className={`tabcontent1 ${activeTab === 'add_mem' ? 'active' : ''}`} id="add_mem">
                    <div className="black-bg" onClick={closeTab}></div>
                    <div className="add-mem-modal">
                        <strong>Add Member</strong>
                        <form className="needs-validation clearfix" onSubmit={handleSubmit} key="addform">
                        <div className="addtest-fields mb-3">
                            <div className='flex-cont'>
                                <div className="half-cont">
                                    <div className="form-group">
                                    <label>
                                        Name
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="member_name"
                                        id="member_name"
                                        required
                                        value=""
                                    />
                                    </div>
                                </div>
                                <div className="half-cont">
                                    <div className="form-group">
                                    <label>
                                        Age
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="member_age"
                                        id="member_age"
                                        required
                                        value=""
                                    />
                                    </div>
                                </div>
                                <div className="half-cont">
                                    <div className="form-group">
                                    <label>
                                        Relation
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="member_name"
                                        id="member_name"
                                        required
                                        value=""
                                    />
                                    </div>
                                </div>
                                <div className="half-cont">
                                    <div className="form-group">
                                    <label>
                                        Gender <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        className="form-select form-control"
                                        name="gender"
                                        required
                                        value= ""
                                       
                                    >
                                        <option value="">--Select--</option>
                                        <option value="">Male</option>
                                        <option value="">Female</option>
                                    </select>
                                    </div>
                                </div>                      
                               
 
                               
                                           
                                <div className="submit-section">
                                    <button
                                    className="btn btn-primary submit-btn"
                                    type="submit"
                                    name="form_submit"
                                    >
                                    Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        </form>
                        <div className="close1" onClick={closeTab}>
                            <img src={Close} alt="close" title="close" />
                        </div>
                    </div>
                </div>              
            </div>
            <div className='test-filter address-popup'>
                <div className={`tabcontent1 ${activeTab === 'address' ? 'active' : ''}`} id="address">
                    <div className="black-bg" onClick={closeTab}></div>
                    <div className="white-modal">
                        <strong>Select an address</strong>
                        <div className='address-selection-pop'>
                            <div className='add-address-cont'>
                                <Link to='/'>
                                    Add Address
                                </Link>
                            </div>
                            <div className='saved-address-cont'>
                                <div className='heading-add'>
                                    <h3>Saved Addresses</h3>
                                </div>
                                <div className='saved-addresses'>
                               
                                    {address.map((item, index) => (
                                        <Address
                                        key={index}
                                        data={item}
                                        isActive={activeDropdown === index}
                                        handleNotifyClick={() => handleNotifyClick(index)}
                                        refreshAddressList={addressList}
                                        />
                                    ))}
                                   
                                </div>
                            </div>
                        </div>
                        <div className="close1" onClick={closeTab}>
                            <img src={Close} alt="close" title="close" />
                        </div>
                    </div>
                </div>              
            </div>

            <div className='success-popup'>
                {/* Modal */}
                {isModalVisible && (
                        <div className='user-popup'>
                            <div className="modal">
                                <div className='modal-dialog modal-lg' >
                                    <div className="modal-content">
                                    <div className='sucess-cont'>                              
                                        <img src={Done} alt='done-img'></img>
                                        <p>Your placed successfully!</p>
                                        <div className="login-btn"><Link to='/'><button type="submit" className="btn">OK</button></Link></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className='popup-bg'></div> 
                        </div>
                    )}
            </div>
        </div>
    );
};

export default Cart;
