import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Backbtn from '../../Assets/images/Back-btn.png';


const AddAddress = () => { 

      const handleSubmit = async (event) => {

      };
    
    return (
        <div className='homepageWrapper'>
            <div className='client-container'>
                <div className='add-member-cont'>
                    <div className='back-heading-add'>
                        <div className='back-btn-add'>
                          <Link to='#' onClick={() => window.history.back()}>
                            <img src={Backbtn} alt='back-btn' title='back-btn'></img>
                          </Link>
                        </div>
                        <h1>Add Address</h1>
                    </div>
                    <div className="add-address">
                        {/* <strong>Add Member</strong> */}
                        <form className="needs-validation clearfix" onSubmit={handleSubmit} key="addform">
                        <div className="addtest-fields mb-3">
                            <div className='flex-cont'>
                                <div className="half-cont">
                                    <div className="form-group">
                                    <label>
                                        Name
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="member_name"
                                        id="member_name"
                                        required
                                        value=""
                                    />
                                    </div>
                                </div>
                                <div className="half-cont">
                                    <div className="form-group">
                                    <label>
                                        Age
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="member_age"
                                        id="member_age"
                                        required
                                        value=""
                                    />
                                    </div>
                                </div>
                                <div className="half-cont">
                                    <div className="form-group">
                                    <label>
                                        Relation
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="member_name"
                                        id="member_name"
                                        required
                                        value=""
                                    />
                                    </div>
                                </div>
                                <div className="half-cont">
                                    <div className="form-group">
                                    <label>
                                        Gender <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        className="form-select form-control"
                                        name="gender"
                                        required
                                        value= ""
                                        
                                    >
                                        <option value="">--Select--</option>
                                        <option value="">Male</option>
                                        <option value="">Female</option>
                                    </select>
                                    </div>
                                </div>                      
                                            
                                <div className="submit-section">
                                    <button
                                    className="btn btn-primary submit-btn"
                                    type="submit"
                                    name="form_submit"
                                    >
                                    Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAddress;
