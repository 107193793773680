import React from 'react';

const PackagesData = ({ data }) => {
    return (
        <div style={{ width: '100%', margin: '20px 0' }}>
            {data.map((packageData, packageIndex) => (
                <table
                    key={packageIndex}
                    border="1"
                    cellSpacing="0"
                    cellPadding="10"
                    style={{ width: '100%', textAlign: 'left', marginBottom: '20px' }}
                >
                    <thead>
                        <tr>
                            <td colSpan="2"><strong>Package Name :</strong> {packageData.name}</td>
                        </tr>
                        <tr>
                            <td><strong>Category Name</strong></td>
                            <td><strong>Test Name</strong></td>
                        </tr>
                    </thead>
                    <tbody>
                        {packageData.categorynames.map((category, categoryIndex) => (
                                <React.Fragment key={categoryIndex}>
                                    {category.testnames.length > 0 ? (
                                        category.testnames.map((test, testIndex) => (
                                            <tr key={testIndex}>
                                                <td>{category.categoryname}</td> 
                                                <td>{test}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td>{category.categoryname}</td> 
                                            <td>No tests available</td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        <tr>
                            <td colSpan="2"><strong>Description :</strong> {packageData.description}</td>
                        </tr>
                        <tr>
                            <td colSpan="2"><strong>Price :</strong> {packageData.price}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <strong>Status : </strong>
                                <span style={{ color: packageData.active === "Active" ? "green" : "red" ,fontWeight:'bold'}}>
                                    {packageData.active}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            ))}
        </div>
    );
};

export default PackagesData;
