import React, { useState, useEffect, useRef,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Table, Button, Space } from 'antd';
import EditIcon from './EditIcon';
import Trash from '../Assets/images/recycle-bin.png'
import { OrganizationContext } from '../../Services/OrganizationContext';

const Orders = () => {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const { organization } = useContext(OrganizationContext);
    alert(organization);
    const dataSource = [
    {
        key: '1',
        InvoiceNo: '#IN0001',
        PatientId: '#PT001',
        PatientName: 'Charlene Reed',
        TotalAmount: '$100.00',
        CreatedDate: '13 Aug 2023',
        Status: 'paid',
    },

    
    
    ];

    const toggleActiveClass = () => {
        setIsActive(!isActive);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form submitted');
        // Implement your form submission logic here
      };
    
      const handleDelete = (key) => {
        console.log('Deleting key:', key);
        // Implement your delete logic here
      };
    
    const columns = [
    {
        title: '#',
        dataIndex: 'InvoiceNo',
        key: 'InvoiceNo',
        width: 200,
    },
    {
        title: 'PatientId',
        dataIndex: 'PatientId',
        key: 'PatientId',
        width: 200,
    },
    {
        title: 'PatientName',
        dataIndex: 'PatientName',
        key: 'PatientName',
        width: 300,
    },
    {
        title: 'TotalAmount',
        dataIndex: 'TotalAmount',
        key: 'TotalAmount',
        width: 200,
    },
    {
        title: 'CreatedDate',
        dataIndex: 'CreatedDate',
        key: 'CreatedDate',
        width: 200,
    },
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        width: 200,
    },
    {
        title: 'Action',
        key: 'action',
        align: 'right',
        render: (_, record) => (
            <Space size="middle">
              <Button
                type="text"
                icon={<img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />}
                onClick={() => handleDelete(record.key)}
              />
            </Space>
        ),
    },
    ];
  return (
    <>
        <div className='main-container'>
            <div className='container-fluid'>
                <div className='main-banner'>
                    <div className='flex-cont'>
                        <div className='breadcrum-cont'>
                            <span><a href='#'>Home</a></span>
                            <span className='seperator'></span>
                            <span className='active-category'><a href='#'>Orders</a></span>
                        </div>
                        <div className='back-btn'>
                        <Link to="/">Back</Link>
                        </div>
                    </div>
                </div>
            <div className='category-table reports-table'>
                <div className='flex-cont add-cat'>
                    <div className='heading'>
                        <h3>Orders</h3>
                    </div>
                </div>
                <div className={`filter-search datepicker-search ${isActive ? 'active' : ''}`}>
                    <div className='search-heading' onClick={toggleActiveClass}>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#212121">
                        <path
                        d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                        stroke="#212121"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        />
                    </svg>
                        <h2>Search</h2>
                    </div>
                    <div className="dateSearch">
                        <form className="needs-validation clearfix" onSubmit={handleSubmit}>
                            <div className='flex-cont dateinput-cont'>
                                <div className="form-group">
                                    <label>Start Date</label>
                                    <input
                                    type="date"
                                    className="form-control"
                                    name="start_date"
                                    defaultValue="2024-08-20"
                                    required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>End Date</label>
                                    <input
                                    type="date"
                                    className="form-control"
                                    name="end_date"
                                    defaultValue="2024-08-21"
                                    required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Patient ID</label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    name="patient_id"
                                    defaultValue=""
                                    required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Patient Name</label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    name="patient_name"
                                    defaultValue=""
                                    required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Patient Phone</label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    name="patient_number"
                                    defaultValue=""
                                    required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Payment Type</label>
                                    <select
                                        className="form-select form-control"
                                        name="category_id"
                                        required
                                        defaultValue=""
                                        
                                    >
                                        <option value="">All</option>
                                        <option value="">Card</option>
                                        <option value="">UPI</option>
                                        <option value="">Due Payment</option>
                                        
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Order Type</label>
                                    <select
                                        className="form-select form-control"
                                        name="category_id"
                                        required
                                        defaultValue=""
                                        
                                    >
                                        <option value="">All</option>
                                        <option value="">Home Visit</option>
                                        <option value="">Lab Visit</option>
                                        
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Order Status</label>
                                    <select
                                        className="form-select form-control"
                                        name="category_id"
                                        required
                                        defaultValue=""
                                        
                                    >
                                        <option value="">All</option>
                                        <option value="">Sample Collected</option>
                                        <option value="">Report Delivered</option>
                                        <option value="">Cancelled</option>
                                        
                                    </select>
                                </div>
                                <div className="submit-section">
                                    <Button type='submit'>Search</Button>
                                </div>
                                <div className="submit-section">
                                    <Button type='submit'>Show All</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='hospitals-table reports-table-cont' id='reports_table'>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ pageSize: 5 }}
                    
                />
                </div>
            </div>
            </div>
        </div>
    </>
  );
};

export default Orders;
