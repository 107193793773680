import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../Assets/css/Client.css';
import ProfileIcon from '../../Assets/Icons/ProfileIcon';
import OfferIcon from '../../Assets/Icons/OfferIcon';
import LabtestIcon from '../../Assets/Icons/LabtestIcon';
import ReportsIcon from '../../Assets/Icons/ReportsIcon';
import HomeIcon from '../../Assets/Icons/HomeIcon';

const Menu = () => {
  const location = useLocation();

  const getFirstSegment = () => {
    const segments = location.pathname.split('/').filter(Boolean); // Split and filter out empty segments
    return segments.length > 0 ? segments[0] : ''; // Return the first segment or empty string
  };
  
  // Example usage
  const firstSegment = getFirstSegment();
  console.log(getFirstSegment());

  return (
    <div className='fixed-menu'>
      <div className={firstSegment === 'home' ? 'menu-item active' : 'menu-item'}>
        <Link to='/lab-tests'>
          <HomeIcon />
          <h3>Home</h3>
        </Link>
      </div>
      <div className={firstSegment === 'reports' ? 'menu-item active' : 'menu-item'}>
        <Link to='/reports'>
          <ReportsIcon />
          <h3>Reports</h3>
        </Link>
      </div>
      <div className={firstSegment === 'lab-tests' ? 'menu-item active' : 'menu-item'}>
        <Link to='/lab-tests'>
          <LabtestIcon />
          <h3>Lab Test</h3>
        </Link>
      </div>
      <div className={firstSegment === 'offers' ? 'menu-item active' : 'menu-item'}>
        <Link to='/offers'>
          <OfferIcon />
          <h3>Offers</h3>
        </Link>
      </div>
      <div className={firstSegment === 'profile' ? 'menu-item active' : 'menu-item'}>
        <Link to='/profile'>
          <ProfileIcon />
          <h3>Profile</h3>
        </Link>
      </div>
    </div>
  );
};

export default Menu;
