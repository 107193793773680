import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Space, Input } from 'antd';
import EditIcon from '../EditIcon';
import Trash from '../../Assets/images/recycle-bin.png';
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const Cities = () => {
    const [cities, setCities] = useState([]); 
    const [filteredCities, setFilteredCities] = useState([]); 
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchCities = async () => {
        const token = authService.getCurrentadmin()?.token;
        const apiUrl = authService.getApiUrl() + 'cities'; 

        if (!token) {
            setError('Authentication token is missing.');
            return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }

            const result = await response.json();
            setCities(result.data); 
            setFilteredCities(result.data); 
            setError(null); 
        } catch (error) {
            console.error('Error fetching cities:', error);
            setError('Failed to fetch cities. ' + error.message);
        }
    };

    useEffect(() => {
        fetchCities(); 
    }, []);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value); 
        if (e.target.value.trim() === '') {
            setFilteredCities(cities); 
        }
    };

    const handleSearch = () => {
        const value = searchTerm.trim().toLowerCase();
        if (value === '') {
            setFilteredCities(cities);
        } else {
            const filtered = cities.filter((city) =>
                city.city_name.toLowerCase().includes(value)
            );
            setFilteredCities(filtered);
        }
    };

    const handleDelete = (id) => {
        console.log('Delete:', id);
    };

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "index",
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: "City Name",
            dataIndex: "city_name",
            key: "city_name",
        },
        {
            title: "State Name",
            dataIndex: "state_name",
            key: "state_name",
        },
        {
            title: 'Status',
            dataIndex: 'Is_Active',
            key: 'Is_Active',
            render: (text) => (
                <span className={text === 'A' ? 'status-btn-green' : 'status-btn-red' }>
                    {text === 'A' ? 'Active' : 'Inactive'}
                </span>
            ),
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/admin/cities/edit-city/${record.id}`} className="me-1 btn btn-sm bg-success-light">
                        <Button
                            type="text"
                            icon={<EditIcon />}
                        />
                    </Link>
                    <Button
                        type="text"
                        icon={<img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />}
                        onClick={() => handleDelete(record.id)}
                    />
                </Space>
            ),
        },
    ];

    return (
        <div className='main-container'>
            <div className='container-fluid'>
                <Breadcrumb/>
                <div className='category-table'>
                    <div className='flex-cont add-cat'>
                        <div className='heading'>
                            <h3>Cities</h3><br></br>
                        </div>
                        <div className='add-btn'>
                            <Link to="/admin/cities/add-city">Add</Link>
                        </div>
                    </div>
                    <div className='filter-search'>
                        <Input
                            type='text'
                            className='category-search'
                            placeholder='Search Cities'
                            onChange={handleInputChange}  
                            value={searchTerm}
                        />
                        <Button onClick={handleSearch} type='primary'>
                            Search
                        </Button>
                    </div>
                    {/* Display error message if there's an error */}
                    {error && <p className='error-message'>{error}</p>}
                    <div className='cities-table' id='cities_table'>
                        <Table
                            dataSource={filteredCities} 
                            columns={columns}
                            pagination={{ pageSize: 10 }}
                            rowKey="id"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cities;
