// src/AdminRoutes.js
import React from 'react';
import { Routes,Route } from "react-router-dom";
// import Dashboard from './Admin/Components/Dashboard';
import Login from './Admin/Components/Login';
import authService from '../src/Services/authService';
import Home from './Admin/Components/Home';
// import About from './Admin/Components/About';
// import Contact from './Admin/Components/Contact';
import Header from './Admin/Components/DashMenu';
// import Category from './Admin/Components/Category/Categories';
import AddCategory from './Admin/Components/Menumanagement/AddCategorie';
import ImportCategory from './Admin/Components/Menumanagement/ImportCategory';
import EditCategories from './Admin/Components/Menumanagement/EditCategorie';
import BranchDashboard from './Admin/Components/BranchDashboard';
import AddTest from './Admin/Components/Menumanagement/AddTest';
import ImportTest from './Admin/Components/Menumanagement/ImportTest';
import EditTest from './Admin/Components/Menumanagement/EditTest';
import Locations from './Admin/Components/Locations/Locations';
import AddLocation from './Admin/Components/Locations/AddLocation';
import EditLocation from './Admin/Components/Locations/EditLocation';
import Organizations from './Admin/Components/organizations/Organizations';
import AddOrganizations from './Admin/Components/organizations/AddOrganization';
import EditOrganization from './Admin/Components/organizations/EditOrganization';
import Countries from './Admin/Components/Countries/Countries';
import AddCountry from './Admin/Components/Countries/AddCountry';
import EditCountry from './Admin/Components/Countries/EditCountry';
import Cities from './Admin/Components/Cities/Cities';
import AddCity from './Admin/Components/Cities/AddCity';
import EditCity from './Admin/Components/Cities/EditCity';
import States from './Admin/Components/States/States';
import AddStates from './Admin/Components/States/AddState';
import EditStates from './Admin/Components/States/EditState';
import Reports from './Admin/Components/Reports';
import Orders from './Admin/Components/Orders';
import SingleBranchDashboard from './Admin/Components/SingleBranchDashboard';
import './Admin/Assets/css/Admin.css';
import PrivateRoute from '../src/Services/PrivateRoute';
import { OrganizationProvider } from '../src/Services/OrganizationContext';
import MenuManagement from './Admin/Components/Menumanagement/MenuManagement';
import HomeVisit from './Admin/Components/Menumanagement/LabTests';
import UserMangement from './Admin/Components/Usermanagement/UserManagement';
import AddRolesPermissions from './Admin/Components/Usermanagement/AddRolesPermissions';
import EditRolesPermissions from './Admin/Components/Usermanagement/EditRolesPermission';
import AddUser from './Admin/Components/Usermanagement/AddUser';
import RolesPermissions from './Admin/Components/Usermanagement/RolesPermissions';
import EditUser from './Admin/Components/Usermanagement/EditUser';
import Categories from './Admin/Components/Menumanagement/Categories';
import Packages from './Admin/Components/Menumanagement/Packages';
import EditPackage from './Admin/Components/Menumanagement/Editpackage';
import AddPackage from './Admin/Components/Menumanagement/Addpackge';
import { PermissionsProvider } from '../src/Services/PermissionsContext';
import Unautherized from './Admin/Components/404/Unautherized';

const AdminRoutes = () => {
    const isAuthenticated=authService.getCurrentadmin()?true:false;

  return (
    <OrganizationProvider>
      <PermissionsProvider>
        <div>
          {isAuthenticated && <Header />}
          <Routes>
            <Route path="/" element={<Login />} />
            
            <Route
              path="/dashboard"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Home} modulePath="dashboard" 
              action="view" />}
            />
            {/* <Route
              path="/about"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={About} />}
            /> */}
            {/* <Route
              path="/contact"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Contact} />}
            /> */}
            {/* <Route
              path="/categories"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Category} />}
            /> */}
            <Route
              path="/lab-management/add-category"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={AddCategory} modulePath="category" 
              action="add"/>}
            />
             <Route
              path="/lab-management/import-category"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={ImportCategory} modulePath="category"
              action="add"/>}
            />
            <Route
              path="/lab-management/edit-category/:id"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={EditCategories} modulePath="category" 
              action="edit"/>}
            />
            <Route
              path="/branchdashboard"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={BranchDashboard} />}
            />
            <Route
              path="/lab-management/addtest"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={AddTest} modulePath="labtests" 
              action="add"/>}
            />
            <Route
              path="/lab-management/import-tests"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={ImportTest} modulePath="labtests" 
              action="add"/>}
            />
            <Route
              path="/lab-management/edit-test/:id"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={EditTest} modulePath="labtests" 
              action="edit"/>}
            />
            <Route
              path="/locations"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Locations} modulePath="locations" 
              action="view"/>}
            />
            <Route
              path="/locations/add-location"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={AddLocation} modulePath="locations" 
              action="add"/>}
            />
            <Route
              path="/locations/edit-location/:id"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={EditLocation} modulePath="locations" 
              action="edit"/>}
            />
            <Route
              path="/organizations"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Organizations}  modulePath="organizations" 
              action="view"/>}
            />
            <Route
              path="/organizations/add-organization"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={AddOrganizations} modulePath="organizations" 
              action="add"/>}
            />
            <Route
              path="/organizations/edit-organization/:id"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={EditOrganization} modulePath="organizations" 
              action="edit"/>}
            />
            <Route
              path="/countries"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Countries} modulePath="countries" 
              action="view"/>}
            />
            <Route
              path="/countries/add-country"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={AddCountry} modulePath="countries" 
              action="add"/>}
            />
            <Route
              path="/countries/edit-country/:id"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={EditCountry} modulePath="countries" 
              action="edit"/>}
            />
            <Route
              path="/cities"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Cities} modulePath="cities" 
              action="view" />}
            />
            <Route
              path="/cities/add-city"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={AddCity} modulePath="cities" 
              action="add"/>}
            />
            <Route
              path="/cities/edit-city/:id"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={EditCity} modulePath="cities" 
              action="edit"/>}
            />
            <Route
              path="/states"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={States} modulePath="states" 
              action="view"/>}
            />
            <Route
              path="/states/add-state"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={AddStates} modulePath="states" 
              action="add"/>}
            />
            <Route
              path="/states/edit-state/:id"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={EditStates} modulePath="states" 
              action="edit" />}
            />
    
            <Route
              path="/reports"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Reports} />}
            />
            <Route
              path="/orders"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Orders} />}
            />
            <Route
              path="/branch-dashboard"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={SingleBranchDashboard} />}
            />
            {/* <Route
              path="/lab-management"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={MenuManagement} />}
            /> */}
            {/* <Route
              path="/lab-management/home-visit"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={HomeVisit} />}
            /> */}
            <Route
              path="/lab-management"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={HomeVisit} modulePath="labtests" 
              action="view"/>}
            />
            <Route
              path="/lab-management/labtests"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={HomeVisit} modulePath="labtests" 
              action="view"/>}
            />
            <Route
              path="/user-management"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={UserMangement} modulePath="users" 
              action="view"/>}
            />
            <Route
              path="/user-management/users"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={UserMangement} modulePath="users" 
              action="view" />}
            />
            <Route
              path="/user-management/roles-permissions"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={UserMangement} modulePath="roles-permissions" 
              action="view"/>}
            />
            <Route
              path="/user-management/add-user"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={AddUser}  modulePath="users" 
              action="add" />}
            />
            <Route
              path="/user-management/edit-user/:id"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={EditUser}  modulePath="users" 
              action="edit" />}
            />
            <Route
              path="/user-management/add-roles-permissions"
            element={<PrivateRoute isAuthenticated={isAuthenticated} component={AddRolesPermissions} modulePath="roles-permissions" 
            action="add"/>}
            />
            <Route
             path="/user-management/edit-roles-permissions/:id"
             element={<PrivateRoute isAuthenticated={isAuthenticated} component={EditRolesPermissions} modulePath="roles-permissions" 
             action="edit"/>}
            />
            <Route
              path="/lab-management/category"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Categories} modulePath="category" 
              action="view"/>}
            />
          <Route
              path="/lab-management/packages"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={Packages} modulePath="packages" 
              action="view" />}
            />
            <Route
              path="/lab-management/add-package"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={AddPackage} modulePath="packages" 
              action="add"/>}
            />
            <Route
              path="/lab-management/edit-package/:id"
              element={<PrivateRoute isAuthenticated={isAuthenticated} component={EditPackage} modulePath="packages" 
              action="edit" />}
            />
            <Route
              path="/404"
              element={<Unautherized/>}
            />
    
          </Routes>
        </div>
      </PermissionsProvider>
    </OrganizationProvider>
  );
};

export default AdminRoutes;
