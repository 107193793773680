export const hasPermission = (permissions, modulePath, action) => {
    if (!permissions || permissions.length === 0) {
        console.log("No permissions found");
        return false;
    }

    const module = permissions.find(mod => mod.module_path === modulePath);
    if (!module) {
        console.log(`No module found for path: ${modulePath}`);
        return false;
    }

    const hasActionPermission = module[action] === 1;
    console.log(`Checking permissions for ${modulePath} with action ${action}:`, hasActionPermission);
    return hasActionPermission;
};
