import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'antd'; // Ant Design Button component
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const AddCity = () => {
  const navigate = useNavigate(); // Hook to navigate after submission

  // Initialize form data state
  const [formData, setFormData] = useState({
    city_name: '',
    state_id: '', // New state for state selection
  });

  // State to handle submission status and error messages
  const [status, setStatus] = useState({
    success: false,
    message: '',
  });

  // State to store the list of states
  const [states, setStates] = useState([]);
const[errors,setErrors]=useState("");
  // Fetch states when the component is mounted
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const token = authService.getCurrentadmin().token;
        const response = await fetch(authService.getApiUrl() + 'states', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch states');
        }

        const result = await response.json();
        setStates(result.data); // Assuming API returns { data: [...] }
      } catch (err) {
        console.error('Error fetching states:', err);
      }
    };

    fetchStates(); // Call the function to fetch states
  }, []);

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value, // Update the corresponding form data state
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const ValidateErrors=ValidateForm(formData);
    setErrors(ValidateErrors);

    if(Object.keys(ValidateErrors).length ===0){
    const token = authService.getCurrentadmin().token;

    try {
      const response = await fetch(authService.getApiUrl() + 'cities', {
        method: 'POST',
        body: JSON.stringify({
          city_name: formData.city_name,
          state_id: formData.state_id,
          Is_Active: "A",
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.statusText}. Details: ${errorText}`);
      }

      const result = await response.json();
      console.log('City added:', result);

      // Set the status message
      setStatus({
        success: true,
        message: 'City added successfully!',
      });

      // Redirect to the cities page after 1 second
      setTimeout(() => {
        navigate('/admin/cities');
      }, 1000);

    } catch (err) {
      console.error('Error adding city:', err.message);
      setStatus({
        success: false,
        message: `Error: ${err.message}`,
      });
    }
  }else{
    console.log("form failed to submit due to errors");
  }
  };
const ValidateForm=()=>{
  const errors={};
  const nameRegex = /^[a-z A-Z]+$/;
  // Name validation
  if (!formData. city_name) {
    errors. city_name = 'City name is required';
  } else if (formData.city_name.length < 4) {
    errors. city_name = 'City name must be at least 4 characters';
  } else if (!nameRegex.test(formData.city_name)) {
    errors. city_name = 'City name can only contain letters';
  }
  if(!formData.state_id){
    errors.state_id='Select state';
  }
  return errors;
}
  return (
    <div className='main-container'>
      <div className='container-fluid'>
        <Breadcrumb/>
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Add City</h3>
            </div>
          </div>
          <div className='card'>
            <div className='card-body custom-edit-service'>
              <form className='needs-validation clearfix' onSubmit={handleSubmit}>
                <div className='addtest-fields mb-3'>
                  <div className='flex-cont'>
                   
                    <div className='half-cont'>
                      <div className='form-group'>
                        <label>
                          State <span className='text-danger'>*</span>
                        </label>
                        <select
                          className='form-select form-control'
                          name='state_id'
                          value={formData.state_id}
                          onChange={handleInputChange}
                          // required
                        >
                          <option value=''>--Select--</option>
                          {states.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.state_name}
                            </option>
                          ))}
                        </select>
                        {errors.state_id &&(<span className='errors' >{errors.state_id}</span>)}
                      </div>
                    </div>
                 
                <div className='half-cont'>
                      <div className='form-group'>
                        <label>
                          City Name
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='city_name'
                          value={formData.city_name}
                          onChange={handleInputChange}
                          // required
                        />
                          {errors.city_name&&(<span className='errors' >{errors.city_name}</span>)}
                      </div>
                    </div>
                    </div>
                    </div>
                {/* <div className='submit-section'>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </div> */}
                 <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    name="form_submit"
                    onSubmit={ValidateForm()}
                  >
                    Submit
                  </button>
                </div>
                {status.message && (
                  <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'}`}>
                    {status.message}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCity;
