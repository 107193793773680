import React, { useState, useEffect, useRef ,useContext} from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Space } from 'antd';
import axios from 'axios';  // Ensure axios is imported
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import TabMenu from './TabMenu';
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const AddCategories = () => {
   const navigate = useNavigate();
   const [formData, setFormData] = useState({
    category_name: '',
    Is_Active:'',
    });
    const { organization } = useContext(OrganizationContext);

    const handleInputChange = (event) => {
      const { name, value, type, checked } = event.target;
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    };
const[errors,setErrors]=useState("");
const [file, setFile] = useState(null);
const handleImageChange = (e) => {
  const file = e.target.files[0];
  setFile(e.target.files[0]);
};
const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    
    // Resolve the base64 string when the file is successfully read
    reader.onload = () => resolve(reader.result);
    
    // Reject in case of any errors
    reader.onerror = error => reject(error);
  });
};
    const handleSubmit = async (event) => {
        event.preventDefault();
        const ValidateErrors=ValidateForm(formData);
        setErrors(ValidateErrors);
        if(Object.keys(ValidateErrors).length===0){
          const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
          const base64String = file?await convertImageToBase64(file):"";
        fetch(authService.getApiUrl() + 'category', {
        method: 'POST', // Change method to PUT
        body: JSON.stringify({ category_name: formData.category_name,
                               organization_id:organization,
                               Is_Active:formData.Is_Active ? "A" : "I",
                               image:base64String,
         }), // Include the data to be updated in the body
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
        },
        })
        .then((res) => res.json())
        .then((result) => {
          console.log(result.data)
            //setFormData(result.data); // Set the form data with the result
            navigate(`/admin/lab-management/category`);
        })
        .catch((err) => {
            console.log(err.message); // Handle any errors
        });
      }
      else{
        console.log("Form submission failed due to validation errors.");
      }
    };
    const ValidateForm = () => {
      const errors = {};
      const nameRegex = /^[a-zA-Z]+[\-'\s]?[a-zA-Z ]+$/;
      if (!formData.category_name) {
        errors.category_name = 'Category Name is required';
      } 
       
      else if (!nameRegex.test(formData.category_name)) {
        errors.category_name = 'Category Name can only contain letters';
      }
      // if(!formData.Is_Active){
      //   errors.Is_Active='Status is required';
      // }
      return errors;
    };


  return (
    <>
        <div className='main-container'>
            <div className='container-fluid'>
            <Breadcrumb/>
            <TabMenu name="category"/>
            <div className='category-table'>
                <div className='flex-cont add-cat'>
                    <div className='heading'>
                        <h3>Add Categorie</h3>
                    </div>
                    {/* <div className='add-btn'>
                       <a href='#' title="add-btn">Add</a>
                    </div> */}
                </div>
                <div className="card">
              <div className="card-body custom-edit-service">
                <form className="needs-validation clearfix" onSubmit={handleSubmit} key="addform">
                  <div className="addtest-fields mb-3">
                    <div className="flex-cont">
                      <div className="half-cont">
                        <div className="form-group">
                          <label>
                            Category Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="category_name"
                            id="category_name"
                           
                            value={formData.category_name}
                            onChange={handleInputChange}
                          />
                           {errors.category_name && <span className='errors' >{errors.category_name}</span>}
                     
                         
                           </div>
                           </div>
                         
                           <div className="half-cont">
                      <div className='upload-img-cont'>
                        {/* <span>Upload Image *</span> */}
                        <label>
                      Upload Image
                            <span className="text-danger">*</span>
                          </label>
                        <input
                          type="file"
                          accept="image/jpeg, image/png, image/gif"
                          name="image"
                          onChange={handleImageChange}
                          className="form-control"
                          
                        />
                        <small className="text-secondary">
                          Recommended image size is <b>300 × 300 px</b>
                        </small>
                      </div>
                      {formData.image && (
                        <div id="uploadPreview">
                          <ul className="upload-wrap">
                            <li>
                              <div className="upload-images">
                                <img
                                  alt="Category"
                                  src={authService.getApiDomainUrl() + formData.image}
                                  style={{ maxWidth: '100px', maxHeight: '100px' }}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    </div>
                      

                  
                    <div className="flex-cont">
                      {/* Radio buttons for Home and Hospital Visit */}
                      <div className="full-cont special-case-cont">
                        <div className='form-group'>
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              name="Is_Active"
                              checked={formData.Is_Active}
                              onChange={handleInputChange}
                              value="A"
                              
                            />
                            <span className="checkmark"></span>
                            <label>Status</label>
                          </label>
                          {/* {errors.Is_Active && <span className='errors' >{errors.Is_Active}</span>} */}
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                        <button
                          className="btn btn-primary submit-btn"
                          type="submit"
                          name="form_submit"
                          onChange={ValidateForm()}
                        >
                          Submit
                        </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
                
            </div>
            </div>
        </div>
    </>
  );
};

export default AddCategories;
