// import React, { useState } from 'react';

// function Test() {
//   const [file, setFile] = useState(null);

//   const handleFileChange = (event) => {
//     const selectedFile = event.target.files[0];
//     setFile(selectedFile);
//     console.log("File Selected:", selectedFile);
//   };

//   const handleUploadClick = () => {
//     if (file) {
//       // Perform upload logic here, e.g., send it to the server
//       console.log("Uploading file:", file);
//     } else {
//       console.log("No file selected.");
//     }
//   };

//   return (
//     <div>
//       <input
//         type="file"
//         onChange={handleFileChange}
//         style={{ display: 'none' }}
//         id="file-input"
//       />
//       <label htmlFor="file-input">
//         <button>Select File</button>
//       </label>
//       {file && <p>Selected File: {file.name}</p>}
//       <button onClick={handleUploadClick}>Upload</button>
//     </div>
//   );
// }

// export default Test;


import React, { useState, useEffect } from 'react';

const Test = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if the Geolocation API is available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <div>
      {error && <p>Error: {error}</p>}
      {location.latitude && location.longitude ? (
        <p>
          Your current location: <br />
          Latitude: {location.latitude} <br />
          Longitude: {location.longitude}
        </p>
      ) : (
        <p>Fetching location...</p>
      )}
    </div>
  );
};

export default Test;
