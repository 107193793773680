import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const EditCity = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get city ID from the URL params

  const [formData, setFormData] = useState({
    city_name: '',
    state_id: '',
  });
  const [states, setStates] = useState([]); // Store state options
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState(null); // Manage error state
const[errors,setErrors]=useState("");
  // Handle input changes for form
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const ValidateForm=()=>{
    const errors={};
    const nameRegex = /^[a-z A-Z]+$/;
   
    // Name validation
    if (!formData. city_name) {
      errors. city_name = 'City name is required';
    } else if (formData.city_name.length < 4) {
      errors. city_name = 'City name must be at least 4 characters';
    } else if (!nameRegex.test(formData.city_name)) {
      errors. city_name = 'City name can only contain letters';
    }
    if(!formData.state_id){
      errors.state_id='Select state';
    }
    return errors;
  }
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const ValidateErrors=ValidateForm(formData);
    setErrors(ValidateErrors);
    if(Object.keys(ValidateErrors).length ===0){
    const token = authService.getCurrentadmin().token;

    try {
      const response = await fetch(`${authService.getApiUrl()}cities/${id}`, {
        method: 'PUT', // Update the city data with PUT
        body: JSON.stringify({
          city_name: formData.city_name,
          state_id: formData.state_id, 
          Is_Active: "A",
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
        },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Error updating city');
      }

      navigate('/admin/cities'); // Redirect to the cities list page after successful update
    } catch (err) {
      console.error('Error updating city:', err.message);
      setError('Error updating city: ' + err.message); // Display error message
    }
  }
  };

  // Load city data and states when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadStates(); // Load state options
        await loadCity(); // Load the city data for editing
      } catch (err) {
        setError('Error loading data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Load all states
  const loadStates = async () => {
    const token = authService.getCurrentadmin().token;

    try {
      const response = await fetch(`${authService.getApiUrl()}states`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP ${response.status}: ${errorText}`);
      }

      const result = await response.json();
      setStates(result.data || []);
    } catch (err) {
      console.error('Error loading states:', err.message);
      setError('Error loading states: ' + err.message);
    }
  };

  // Load the city data based on ID
  const loadCity = async () => {
    const token = authService.getCurrentadmin().token;

    try {
      const response = await axios.get(`${authService.getApiUrl()}cities/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setFormData({
        city_name: response.data.city_name || '',
        state_id: response.data.state_id || '',
      });
    } catch (err) {
      console.error('Error loading city data:', err.message);
      setError('Error loading city data: ' + err.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='main-container'>
      <div className='container-fluid'>
        <Breadcrumb/>
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Edit City</h3>
            </div>
          </div>
          <div className="card">
            <div className="card-body custom-edit-service">
              <form className="needs-validation clearfix" onSubmit={handleSubmit}>
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          State <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select form-control"
                          name="state_id"
                          // required
                          value={formData.state_id}
                          onChange={handleInputChange}
                        >
                          <option value="" key="0">--Select--</option>
                          {states.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.state_name}
                            </option>
                          ))}
                        </select>
                        {errors.state_id &&(<span className='errors' >{errors.state_id}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          City Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="city_name"
                          value={formData.city_name}
                          onChange={handleInputChange}
                          // required
                        />
                         {errors.city_name&&(<span className='errors' >{errors.city_name}</span>)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="submit-section">
                  <button type='submit' className='btn btn-primary'>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCity;
