import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Space, Input } from 'antd';
import EditIcon from '../EditIcon';
import Trash from '../../Assets/images/recycle-bin.png';
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const Countries = () => {
    const [countries, setCountries] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch countries from the API
    const fetchCountries = async () => {
        const token = authService.getCurrentadmin()?.token;
        const apiUrl = authService.getApiUrl() + 'countries';

        if (!token) {
            setError('Authentication token is missing.');
            return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }

            const result = await response.json();
            setCountries(result.data); // Store the full list of countries
            setFilteredCountries(result.data); // Set the filtered countries to the full list by default
            setError(null); // Clear any errors
        } catch (error) {
            console.error('Error fetching countries:', error);
            setError('Failed to fetch countries. ' + error.message);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    // Handle input change
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value); // Update the search term as the user types
    };

    // Handle search button click
    const handleSearch = () => {
        if (searchTerm.trim() === '') {
            // If search is empty, reset to full list
            setFilteredCountries(countries);
        } else {
            // Filter the countries by the search term
            const filtered = countries.filter((country) =>
                country.country_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredCountries(filtered);
        }
    };

    // Reset the filteredCountries list when the search term is cleared
    useEffect(() => {
        if (searchTerm === '') {
            setFilteredCountries(countries); // Reset to the full list when searchTerm is cleared
        }
    }, [searchTerm, countries]);

    // Handle delete action (not implemented here)
    const handleDelete = (id) => {
        console.log('Delete:', id);
        // Here, you'd make the API call to delete the record
    };

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "index",
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: "Country",
            dataIndex: "country_name",
            key: "country_name",
        },
        {
            title: 'Status',
            dataIndex: 'Is_Active',
            key: 'Is_Active',
            render: (text) => (
                <span className={text === 'A' ? 'status-btn-green' : 'status-btn-red' }>
                    {text === 'A' ? 'Active' : 'Inactive'}
                </span>
            ),
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/admin/countries/edit-country/${record.id}`} className="me-1 btn btn-sm bg-success-light">
                        <Button type="text" icon={<EditIcon />} />
                    </Link>
                    <Button
                        type="text"
                        icon={<img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />}
                        onClick={() => handleDelete(record.id)}
                    />
                </Space>
            ),
        },
    ];

    return (
        <div className='main-container'>
            <div className='container-fluid'>
                <Breadcrumb/>
                <div className='category-table'>
                    <div className='flex-cont add-cat'>
                        <div className='heading'>
                            <h3>Countries</h3><br></br>
                        </div>
                        <div className='add-btn'>
                            <Link to="/admin/countries/add-country">Add</Link>
                        </div>
                    </div>
                    <div className='filter-search'>
                        <input
                            type='text'
                            className='category-search'
                            placeholder='Search'
                            onChange={handleInputChange} // Update search term as user types
                            value={searchTerm}
                        />
                        <Button type="primary" onClick={handleSearch} style={{ marginLeft: '10px' }}>
                            Search
                        </Button>
                    </div>
                    {/* Display an error message if there's an error */}
                    {error && <p className='error-message'>{error}</p>}
                    <div className='countries-table' id='countries_table'>
                        <Table
                            dataSource={filteredCountries} // Use the filtered list of countries
                            columns={columns}
                            pagination={{ pageSize: 10 }}
                            rowKey="id"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Countries;
