// src/services/authService.js
import axios from 'axios';

const API_URL = 'https://api2.pranaamhospitals.com/api/';
const API_Domain_URL = 'https://api2.pranaamhospitals.com/';

sessionStorage.setItem('API_URL', API_URL);
sessionStorage.setItem('API_Domain_URL', API_Domain_URL);

const login = async (email, password) => {
  const response = await axios.post(API_URL + 'login', {
    email,
    password,
  });

  const data = response.data.data;

  if (data) {
    // Save the user information in sessionStorage
    sessionStorage.setItem('admininfo', JSON.stringify(data));

    // If the role_id is not 2, store the adminOrganizationId in localStorage
    if (data.UserInfo.role_id !== 2) {
      localStorage.setItem('adminOrganizationId', data.UserInfo.organization_id);
    }
    // Optionally, store the token in sessionStorage or localStorage
    sessionStorage.setItem('admintoken', data.token);
  }

  return data;
};

const logout = () => {
  //sessionStorage.removeItem('user');
  sessionStorage.removeItem('admininfo');
  localStorage.removeItem('adminOrganizationId');
};

const getCurrentadmin = () => {
  return JSON.parse(sessionStorage.getItem('admininfo'));
};

const getCurrentadmintoken = () => {
  return JSON.parse(sessionStorage.getItem('admintoken'));
};

const setAdminorganizationID = (organization) => {
  localStorage.setItem('adminOrganizationId', organization);
};

const getAdminOrganizationID = () => {
  return localStorage.getItem('adminOrganizationId');
};

// Updated getApiUrl function to use sessionStorage
const getApiUrl = () => {
  return sessionStorage.getItem('API_URL');
};

const getApiDomainUrl = () => {
  return sessionStorage.getItem('API_Domain_URL');
};

// const getApiImageUrl = () => {
//   return localStorage.getItem('API_IMAGE_URL');
// };

// const getCartApiUrl = () => {
//   return localStorage.getItem('Cart_API_URL');
// };


// client login
const Userlogin = async (phone, otp) => {
  try {
    const response = await axios.post(API_URL + 'verify-otp', {
      phone,
      otp,
    });
    const data = response.data.data;
    if (data) {
      sessionStorage.setItem('userInfo', JSON.stringify(data));
      return data; // Return the data if it exists
    }
  } catch (error) {
    console.error('API error:', error);
    throw new Error('Failed to verify OTP');
  }

  return null; // Return null if no data or an issue occurred
};


const getCurrentuser = () => {
  return JSON.parse(sessionStorage.getItem('userInfo'));
};

// const getLoggedinuser = () => {
//   return JSON.parse(localStorage.getItem('userInfo'));
// };


const authService =  {
  login,
  logout,
  getCurrentadmin,
  getCurrentadmintoken,
  // getCurrentUser,
  setAdminorganizationID,
  getAdminOrganizationID,
  getApiUrl,
  getApiDomainUrl,
  // getApiImageUrl,
  // getCartApiUrl,
  Userlogin,
  getCurrentuser
  // getLoggedinuser,
};

export default authService;