// src/ClientRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Client/Components/Home';
import Signup from './Client/Components/ClientLogin/Signup';
import './Client/Assets/css/Client.css';
import Menu from './Client/Components/Menu/Menu';
import Profile from './Client/Components/Profile/Profile';
import UploadPrescription from './Client/Components/Profile/UploadPrescription';
import Offers from './Client/Components/Offers/Offers';
import Test from './Client/Components/Test';
import MainHome from './Client/Components/Home/MainHome';
import authService from './Services/authService';
import Packages from './Client/Components/Hospital/Packages';
import Hospitals from './Client/Components/Hospital/Hospitals';
import HospitalDetail from './Client/Components/Hospital/HospitalDetail';
import Login from './Client/Components/ClientLogin/Login';
import PrivateRoute from './Services/ClientPrivateRoute';
import AddMember from './Client/Components/Profile/AddMember';
import AppointmentTime from './Client/Components/Hospital/AppointmentTime';
import Cart from './Client/Components/Order/Cart';
import Bookmark from './Client/Components/Profile/Bookmark';
import AddAddress from './Client/Components/Profile/AddAddress';
import Address from './Client/Components/Profile/UserAddress';

const ClientRoutes = () => {
  const isAuthenticated=authService.getCurrentuser()?true:false;

  return (
    <div>
      {isAuthenticated && <Menu />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/test" element={<Test />} />
        <Route path="/lab-tests" element={<PrivateRoute element={MainHome} /> } />
        <Route path="/packages" element={<PrivateRoute element={Packages} />}  />
        <Route path="/lab-tests/hospitals" element={<PrivateRoute element={Hospitals} />}  />
        <Route path="/lab-tests/:name" element={<PrivateRoute element={HospitalDetail} />}  />
        <Route path="/lab-tests/appointment-time" element={<PrivateRoute element={AppointmentTime} />} />
        <Route path="/lab-tests/cart/:name" element={<PrivateRoute element={Cart} />} />

        {/* Private routes */}
        <Route path="/profile" element={<PrivateRoute element={Profile} />} />
        <Route path="/profile/upload-prescription" element={<PrivateRoute element={UploadPrescription} />} />
        <Route path="/offers" element={<PrivateRoute element={Offers} />} />
        <Route path="/profile/add-member" element={<PrivateRoute element={AddMember} />}  />
        <Route path="/profile/wishlist" element={<PrivateRoute element={Bookmark} />}  />
        <Route path="/profile/addaddress" element={<PrivateRoute element={AddAddress} />}  />
        <Route path="/profile/address" element={<PrivateRoute element={Address} />}  />
      </Routes>
    </div>
  );
};

export default ClientRoutes;
