// src/Services/PermissionsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import authService from './authService';
import loader from '../Admin/Assets/images/loader.gif';

const PermissionsContext = createContext();

export const usePermissions = () => {
    return useContext(PermissionsContext);
};

export const PermissionsProvider = ({ children }) => {
    const [permissions, setPermissions] = useState(null); // Use null to represent loading state
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const admin = authService.getCurrentadmin();
        if (admin && admin.moduleAccess) {
            setPermissions(admin.moduleAccess);
        } else {
            setPermissions([]);
        }
        setLoading(false); // Permissions are now fully loaded
    }, []);

    if (loading) {
        // return <div>Loading...</div>; // Or a spinner component
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <img src={loader} alt="Loading..." />
            </div>
    }

    return (
        <PermissionsContext.Provider value={permissions}>
            {children}
        </PermissionsContext.Provider>
    );
};
