import React, { useState, useEffect, useContext} from 'react';
// import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Table, Button, Space, Select } from 'antd';
import Trash from '../../Assets/images/recycle-bin.png';
// import Logs from './Logs';
import ViewIcon from './View';
import Close from '../../Assets/images/close.png';
import UserData from './UserData';
// import UserRights from './UserRights';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import TabMenu from './TabMenu';
import EditIcon from '../Usermanagement/EditIcon';
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { usePermissions } from '../../../Services/PermissionsContext'; // Import the permissions context
import { hasPermission } from '../../../Services/checkPermissions';   // Import the helper function


const LabTests = () => {
    const permissions = usePermissions(); // Get global permissions

    // const [activeMenuItem, setActiveMenuItem] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { organization } = useContext(OrganizationContext);
    const [categoryData, setCategoryData] = useState([]);
    const [activeCategoryId, setActiveCategoryId] = useState(0);
    const [labTestsData, setLabTestsData] = useState([]);
    const [selectedLabTest, setSelectedLabTest] = useState([]);
    const [formData, setFormData] = useState({
        test_name: '',
        Is_Active: '',
    });
    // const navigate = useNavigate();
    const [status, setStatus] = useState("Status");
    useEffect(() => {
        if (organization) {
          loadCategory();
          loadLabtests(status);
        }
    }, [organization,status]);
    

    // if (permissions.length === 0) {
    //     return <div>No permissions available</div>; // Handle case when there are no permissions
    // }

    const loadCategory = () => {
          const token = authService.getCurrentadmin().token; 
        const url = new URL(authService.getApiUrl() + 'activecategory');
        url.searchParams.append('organization_id', organization); 
      
        fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`, 
          },
        })
          .then((res) => res.json())
          .then((result) => {
            setCategoryData(result.data); 
          })
          .catch((err) => {
            console.log(err.message); 
          });
      };
      

    
    const loadLabtests = () => {
      const token = authService.getCurrentadmin().token; 
    const url = new URL(authService.getApiUrl() + 'labtests');
    url.searchParams.append('organization_id', organization); 
    
    fetch(url, {
        method: 'GET', 
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => res.json())
        .then((result) => {
            setLabTestsData(result.data); 
        })
        .catch((err) => {
        console.log(err.message); 
        });
    };
      

    const showModal = (record) => {
        setSelectedLabTest(record);
        setIsModalVisible(true);
    };
    
   
    const closeModal = (record) => {
        setIsModalVisible(false);
        setSelectedLabTest(record); 
    };


    const handleStatusChange = (value) => {
        const token = authService.getCurrentadmin().token; 
    
        fetch(authService.getApiUrl() + "labtestsupdatestatus", {
          method: "PUT", 
          body: JSON.stringify({
            Is_Active: value=='Active'?'A':'I',
            labtest_id: selectedRows,
            organization_id:organization,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`, 
          },
        })
          .then((res) => res.json())
          .then((result) => {
            loadLabtests();
          })
          .catch((err) => {
            console.log(err.message); 
          });
      };
    const stripHtmlTags = (str) => {
        if (!str) return '';
        return str.replace(/<[^>]*>/g, '');
      };

    const toggleActiveClass = () => {
        setIsActive(!isActive);
    };

    // const handleMenuItemClick = () => {
    //     setActiveMenuItem(!activeMenuItem);
    // };

    const handleToggleMenuClick = () => {
        document.body.classList.toggle('categoriessidebar-close');
        setSidebarOpen(!isSidebarOpen);
    };

    const handleDelete = (key) => {
        console.log('Deleting key:', key);
        // Implement your delete logic here
    };

    // const handleShowAll = () => {
    //     console.log('Show all records');
    //     // Implement your logic to show all records here
    // };

    const columns = [
        {
            title: 'Test ',
            dataIndex: 'test_name',
            key: 'test_name',
            render: (text) => <span>{text}</span>,
            sorter: (a, b) => a.test_name.localeCompare(b.test_name),
            width:300
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (text) => {
              const plainText = stripHtmlTags(text);
              return <span>{plainText.length > 47 ? plainText.substring(0, 35) + "..." : plainText}</span>;
            },
            sorter: (a, b) => stripHtmlTags(a.description).localeCompare(stripHtmlTags(b.description)),
            width:300
          },
        {
            title: 'Preparation',
            dataIndex: 'preparation_description',
            key: 'preparation_description',
            
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        
            
        },
        
        {
            title: 'Status',
            dataIndex: 'Is_Active',
            key: 'Is_Active',
            align: 'center',
            render: (text) => (
                <span className={text === 'A' ? 'status-btn-green' : 'status-btn-red' }>
                    {text === 'A' ? 'Active' : 'Inactive'}
                </span>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            align: 'right',
            width:100,
            render: (_, record) => (
                <Space size="middle" style={{ columnGap: '0px' }}>
                    {hasPermission(permissions, 'labtests', 'edit') && 
                      <Link to={"/admin/lab-management/edit-test/"+record.id} className="me-1 btn btn-sm bg-success-light">
                        <Button
                            type="text"
                            icon={<EditIcon />}
                        />
                       </Link>
                    }
                     <Button
                        type="text"
                        icon={<ViewIcon />}
                        onClick={() => showModal(record)} 
                    />
                    {hasPermission(permissions, 'labtests', 'delete') && 
                        <Button
                            type="text"
                            icon={<img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />}
                            onClick={() => handleDelete(record.key)}
                        />
                    }
                </Space>
            ),
        },
    ];

    const userdata = [
        {
            id: selectedLabTest.id,
            name: selectedLabTest.test_name,
            image: <img src={`${authService.getApiDomainUrl()}${selectedLabTest.image}`} alt={selectedLabTest.test_name} style={{ width: '100px' }} />,
            shortDescription: selectedLabTest.short_description,
            description: selectedLabTest.description,
            preparationDescription: selectedLabTest.preparation_description,
            price: `₹${selectedLabTest.price}`,
             active: selectedLabTest.Is_Active === "A" ? "Active" : "Inactive"
        }
    ];


    const handlecategory=(catid)=>{
        setActiveCategoryId(catid);
        if(catid==0){
            loadLabtests();
        }else{
            loadCatbasedtests(catid);
        }
    }

    const loadCatbasedtests = (catid) => {
          const token = authService.getCurrentadmin().token; 
        const url = new URL(authService.getApiUrl() + 'catorgbasedtests');
        url.searchParams.append('organization_id', organization); 
        url.searchParams.append('category_id', catid);
        
        fetch(url, {
            method: 'POST', 
            headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`, 
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setLabTestsData(result.data); 
            })
            .catch((err) => {
            console.log(err.message); 
            });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
          const token = authService.getCurrentadmin().token; 
        fetch(authService.getApiUrl() + 'labtestsFilters', {
        method: 'POST', 
        body: JSON.stringify({ category_id: activeCategoryId,
                               organization_id:organization,
                               test_name:formData.test_name,
                               Is_Active:formData.Is_Active
         }), 
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`, 
        },
        })
        .then((res) => res.json())
        .then((result) => {
            setLabTestsData(result.data); 
        })
        .catch((err) => {
            console.log(err.message); 
        });
  
    };
    
    const rowSelection = {
        onChange: (selectedRowKeys, newSelectedRowKeys) => {
          console.log(
            `Selected Row Keys: ${selectedRowKeys}`,
            "Selected Rows: ",
            selectedRows
          );
          setSelectedRows(selectedRowKeys);
        },
      };

    return (
        <div className='main-container'>
            <div className='container-fluid'>
                <Breadcrumb/>

                <TabMenu name="labtest"/>
                <div className='menu-cat-cont'>
                    <div className='categorie-sidebar'>
                        <div className='heading'>
                            <h3>Categories</h3>
                        </div>
                        <ul className='side-menu'>
                            <li key={0}
                               className={`categorie-item ${activeCategoryId === 0 ? 'active' : ''}`} onClick={() => handlecategory(0)}>
                                <Link to='#' title='Thyroid Test' >All Tests</Link></li>
                           {categoryData.map((item) => (
                            <li key={item.id}
                               className={`categorie-item ${activeCategoryId === item.id ? 'active' : ''}`} onClick={() => handlecategory(item.id)}>
                                <Link to='#' title='Thyroid Test' >{item.category_name}</Link></li>
                            
                            ))}
                           {/* <li className='categorie-item'><Link to='#' title='Blood Test'>Blood Test</Link></li>
                            <li className='categorie-item'><Link to='#' title='HIV Test'>HIV Test</Link></li>
                            <li className='categorie-item'><Link to='#' title='Sugar Test'>Sugar Test</Link></li>
                            <li className='categorie-item'><Link to='#' title='Example Test'>Example Test</Link></li>
                            <li className='categorie-item'><Link to='#' title='Example1 Test'>Example1 Test</Link></li>
                            <li className='categorie-item'><Link to='#' title='Example2 Test'>Example2 Test</Link></li> */}
                        </ul>
                    </div>
                    <div className='right-table-cont'>
                        <div className='category-table reports-table'>
                            <div className='flex-cont add-cat'>
                                <div className='heading'>
                                    <h3>Lab Tests</h3>
                                </div>
                            </div>

                            <div className={`filter-search datepicker-search ${isActive ? '' : 'active'}`}>
                                <div className='top-toogle'>
                                    <div className='close-sidebar' onClick={handleToggleMenuClick}>
                                        <div className={`toogle-menu categorie-toogle ${isSidebarOpen ? '' : 'is-active'}`} id='Toogle-menu'>
                                            <span>toggle menu</span>
                                        </div>
                                    </div>
                                    <div className='heading-cont' onClick={toggleActiveClass}>
                                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#212121">
                                            <path
                                                d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                                                stroke="#212121"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <h4>Search</h4>
                                    </div>
                                </div>
                                
                                <div className="dateSearch">
                                   
                                        <div className='flex-cont dateinput-cont'>
                                            {/* <div className="form-group">
                                                <label>Start Date</label>
                                                <input
                                                type="date"
                                                className="form-control"
                                                name="start_date"
                                                defaultValue="2024-08-20"
                                                required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>End Date</label>
                                                <input
                                                type="date"
                                                className="form-control"
                                                name="end_date"
                                                defaultValue="2024-08-21"
                                                required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Patient ID</label>
                                                <input
                                                type="text"
                                                className="form-control"
                                                name="patient_id"
                                                defaultValue=""
                                                required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Patient Name</label>
                                                <input
                                                type="text"
                                                className="form-control"
                                                name="patient_name"
                                                defaultValue=""
                                                required
                                                />
                                            </div> */}
                                            <div className="form-group">
                                                <label>Test Name</label>
                                                <input
                                                type="text"
                                                className="form-control"
                                                name="test_name"
                                                defaultValue=""
                                                onChange={handleInputChange}
                                                id="test_name"
                                                />
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Payment Type</label>
                                                <select
                                                    className="form-select form-control"
                                                    name="category_id"
                                                    required
                                                    defaultValue=""
                                                    
                                                >
                                                    <option value="">All</option>
                                                    <option value="">Card</option>
                                                    <option value="">UPI</option>
                                                    <option value="">Due Payment</option>
                                                    
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Order Type</label>
                                                <select
                                                    className="form-select form-control"
                                                    name="category_id"
                                                    required
                                                    defaultValue=""
                                                    
                                                >
                                                    <option value="">All</option>
                                                    <option value="">Home Visit</option>
                                                    <option value="">Lab Visit</option>
                                                    
                                                </select>
                                            </div> */}
                                            <div className="form-group">
                                                <label>Status</label>
                                                <select
                                                    className="form-select form-control"
                                                    name="Is_Active"
                                                    id="Is_Active"
                                                    required
                                                    defaultValue=""
                                                    onClick={handleInputChange}
                                                    
                                                >
                                                    <option value="0">All</option>
                                                    <option value="A">Active</option>
                                                    <option value="I">Inactive</option>
                                                   
                                                    
                                                </select>
                                            </div>
                                            <div className="submit-section">
                                                <Button type='button' onClick={handleSubmit}>Search</Button>
                                            </div>
                                            {/* <div className="submit-section">
                                                <Button type='submit'>Show All</Button>
                                            </div> */}
                                            <div className="add-btn">
                                            {hasPermission(permissions, 'labtests', 'add') &&  <Link to="/admin/lab-management/addtest">+  Add Test</Link>}
                                            
                                            </div>
                                            <div className="add-btn">
                                                <Link to="/admin/lab-management/import-tests">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18" viewBox="0 0 20 20">
                                                    <defs>
                                                        <clipPath id="reportsvg">
                                                            <rect id="Rectangle_39" data-name="Rectangle 39" width="20" height="20" transform="translate(5770 1086)" fill="#fff"></rect>
                                                        </clipPath>
                                                    </defs>
                                                    <g id="reports_1" data-name="reports 1" transform="translate(-5770 -1086)" clipPath="url(#reportsvg)">
                                                        <path className="svg-stroke-color" id="Path_99" data-name="Path 99" d="M5774.489,1089.638h11.585v12.731h-11.585v-9.835" transform="translate(-0.281 -0.004)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                                                        <path className="svg-stroke-color" id="Path_100" data-name="Path 100" d="M5777.506,1093.44h5.309" transform="translate(-0.281 -0.004)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"></path>
                                                        <path className="svg-stroke-color" id="Path_101" data-name="Path 101" d="M5777.506,1093.44h5.309" transform="translate(-0.281 2.56)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"></path>
                                                        <path className="svg-stroke-color" id="Path_102" data-name="Path 102" d="M5777.506,1093.44h5.309" transform="translate(-0.281 5.125)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"></path>
                                                    </g>
                                                </svg>
                                                  Import Test</Link>
                                            </div>
                                        </div>
                                    
                                </div>
                            </div>
                            <Select
                                defaultValue={status}
                                style={{ width: 150, marginLeft: 10,float:'right' }}
                                onChange={handleStatusChange}
                            >
                                <option value="" selected='selected'>--Select--</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </Select>                        
                           <Table
                                rowSelection={rowSelection}
                                dataSource={labTestsData}
                                columns={columns}
                                pagination={{ pageSize: 10 }}
                                scroll={{ x: 1300 }}
                                rowKey="id" 
                            />
                            
                        </div>
                    </div>
                </div>
            </div>

            {isModalVisible && selectedLabTest && (
                <div className='user-popup'>
                    <div className="modal" onClick={closeModal}>
                        <div className='modal-dialog modal-lg'>
                            <div className="modal-content">
                                <div className='popup-headings'>
                                    <h3>Lab Test Details</h3>
                                    <button type="button" className="close" onClick={closeModal}>
                                        <img src={Close} alt="close" title="close" />
                                    </button>
                                </div>
                                <div className='user-table first-table'>
                                    <div className='user-role'>
                                        <UserData data={userdata} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LabTests;
