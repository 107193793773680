import React, { useState, useEffect, useRef ,useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Table, Button, Space } from 'antd';
import EditIcon from '../EditIcon';
import Trash from '../../Assets/images/recycle-bin.png'
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const Locations = () => {
    const navigate = useNavigate();
    const { organization } = useContext(OrganizationContext);
    const [locationData, setLocationData] = useState([]);
    const [formData, setFormData] = useState({
        search: '',
    });


    
      const columns = [
        {
          title: "#",
          dataIndex: "id",
          key: "index",
          render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
          title: "State",
          dataIndex: "state_name",
          render: (text) => <span>{text}</span>,
          sorter: (a, b) => {
            const lengthA = a.state_name ? a.state_name.length : 0;
            const lengthB = b.state_name ? b.state_name.length : 0;
            return lengthA - lengthB;
          },
        },
        {
          title: "City",
          dataIndex: "city_name",
          render: (text) => <span>{text}</span>,
          sorter: (a, b) => {
            const lengthA = a.city_name ? a.city_name.length : 0;
            const lengthB = b.city_name ? b.city_name.length : 0;
            return lengthA - lengthB;
          },
        },
        {
          title: "Area",
          dataIndex: "area_name",
          render: (text) => <span>{text}</span>,
          sorter: (a, b) => {
            const lengthA = a.area_name ? a.area_name.length : 0;
            const lengthB = b.area_name ? b.area_name.length : 0;
            return lengthA - lengthB;
          },
        },
        {
          title: 'Status',
          dataIndex: 'Is_Active',
          key: 'Is_Active',
          render: (text) => (
              <span className={text === 'A' ? 'status-btn-green' : 'status-btn-red' }>
                  {text === 'A' ? 'Active' : 'Inactive'}
              </span>
          ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                 <Link to={"/admin/locations/edit-location/"+record.id} className="me-1 btn btn-sm bg-success-light">
                    <Button
                        type="text"
                        icon={<EditIcon />}
                        
                    />
                 </Link>
                <Button
                    type="text"
                    icon={<img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />}
                    onClick={() => handleDelete(record.id)}
                />
                </Space>
            ),
        },
    ];

    const handleDelete=(cid)=>{
        // setGridboxesdata((oldValues) => oldValues.filter((item) => item.id !== pid));
          const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
        fetch(authService.getApiUrl()+"area/"+cid, {
            method: "DELETE",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => res.json())
            .then((result) => {
              alert(result.data);
              loadLocations();
            })
          .catch((err) => {
          });
    }
  
    useEffect(() => {
        loadLocations();
    }, []);

      // useEffect(() => {
      //     if (organization) {
      //       loadLocations();
      //     }
      //   }, [organization]);
      
        const loadLocations = () => {
            const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
        
          // Construct the URL with the organization_id as a query parameter
          const url = new URL(authService.getApiUrl() + 'area');
          //url.searchParams.append('organization_id', organization); // Append the organization_id as a query parameter
        
          fetch(url, {
            method: 'GET', // Set method to GET
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${token}`, // Include Authorization header with Bearer token if required
            },
          })
            .then((res) => res.json())
            .then((result) => {
              setLocationData(result.data); // Set the category data in state
            })
            .catch((err) => {
              console.log(err.message); // Handle any errors
            });
        };
        
        const handleInputChange = (event) => {
          const { name, value } = event.target;
          setFormData({
              ...formData,
              [name]: value,
          });
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
          const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
  
        fetch(authService.getApiUrl() + 'areaFilter', {
        method: 'POST', // Change method to PUT
        body: JSON.stringify({ search: formData.search,
                              // organization_id:organization,
         }), // Include the data to be updated in the body
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
        },
        })
        .then((res) => res.json())
        .then((result) => {
            setLocationData(result.data); // Set the category data in state
        })
        .catch((err) => {
            console.log(err.message); // Handle any errors
        });
  
    };
      
  return (
    <>
        <div className='main-container'>
          <div className='container-fluid'>
            <Breadcrumb/>  
            <div className='category-table'>
                <div className='flex-cont add-cat'>
                    <div className='heading'>
                        <h3>Locations</h3>
                    </div>
                    <div className='add-btn'>
                    <Link to="/admin/locations/add-location">Add</Link>
                    </div>
                </div>
                <div className='filter-search'>
                  <input
                    type='text'
                    className='category-search'
                    placeholder='Search'
                    onChange={handleInputChange}
                    name="search" 
                    id="search"
                  
                  />
                  <Button type='submit' onClick={handleSubmit}>Search</Button>
                </div>
                <div className='hospitals-table' id='hospitals_table'>
                <Table
                    dataSource={locationData}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                    
                />
                </div>
            </div>
            </div>
        </div>
    </>
  );
};

export default Locations;
