// src/Services/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePermissions } from './PermissionsContext';
import { hasPermission } from './checkPermissions';
import loader from '../Admin/Assets/images/loader.gif';

const PrivateRoute = ({ component: Component, isAuthenticated, modulePath, action }) => {
    const permissions = usePermissions();

    // If permissions are still `null`, show a loading indicator or return null (won't render anything)
    if (permissions === null) {
        // return <div>Loading...</div>; // You can replace this with a spinner
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <img src={loader} alt="Loading..." />
            </div>
    }

    // Regular checks after permissions are loaded
    if (!isAuthenticated) {
        return <Navigate to="/admin" />;
    }

    if (!hasPermission(permissions, modulePath, action)) {
        return <Navigate to="/admin/404" />;
    }

    return <Component />;
};

export default PrivateRoute;
