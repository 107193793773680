// Loading.js
import React, { useState} from 'react';
//import '../../Assets/css/Client.css'; // Optional if you want to style the loading screen
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import LeftArrowTailIcon from '../../Assets/Icons/LeftArrowTailIcon';
import CartIcon from '../../Assets/images/CartIcon.png';
import CartSearch from '../../Assets/images/Cart-search.png';
import RightIcon from '../../Assets/images/right-Icon.svg'

const Offers = () => {
  const [activeTab, setActiveTab] = useState('all'); // Set initial active tab
  const [copied, setCopied] = useState(false);
  const [isActive, setIsActive] = useState(false);

   // Text or code to copy
   const codeToCopy = `
   BCH12
 `;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(codeToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 10000); // Reset after 10 seconds
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const toggleActiveClass = () => {
      setIsActive(!isActive);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted');
    // Implement your form submission logic here
  };

  return (
    <>
    <div className='offer-cont'>
        <div className='offer-cont-bg'></div>
        <div className='client-container'>
            <div className={`offer-detail-cont ${isActive ? 'active' : ''}`}>
              <div className="back-heading">
                <div className="left-head">
                  <Link to='/'><LeftArrowTailIcon /></Link>
                  <h2>OFFERS</h2>
                </div>
                <div className='right-search'>
                  <div id='search_icon' className="search-pres" onClick={toggleActiveClass}>
                    <img src={CartSearch} alt='search'></img>
                  </div>
                  <div className='search-cart'>
                    <img src={CartIcon} alt='cart'></img>
                  </div>
                </div>
              </div>
              <div className="dateSearch">
                  <form className="needs-validation clearfix">
                      <div className='flex-cont dateinput-cont'>
                          <div className="form-group offer-rel">
                              {/* <label>Patient Name</label> */}
                              <input
                              type="text"
                              className="form-control"
                              name="offer_name"
                              defaultValue=""
                              required
                              />
                              <span className="search-btn"><img src={CartSearch} alt='search'></img></span>
                          </div>
                          
                      </div>
                  </form>
              </div>
              <div className='offer-tabs'>
                <div className='offer-tab'>
                  <h3
                      className={`tablinks ${activeTab === 'all' ? 'active' : ''}`}
                      onClick={() => setActiveTab('all')}
                  >
                      All
                  </h3>
                  <h3
                      className={`tablinks ${activeTab === 'payment' ? 'active' : ''}`}
                      onClick={() => setActiveTab('payment')}
                  >
                      PAYMENT
                  </h3>
                  <h3
                      className={`tablinks ${activeTab === 'medicine' ? 'active' : ''}`}
                      onClick={() => setActiveTab('medicine')}
                  >
                      MEDICINE
                  </h3>
                  <h3
                      className={`tablinks ${activeTab === 'diagnostic' ? 'active' : ''}`}
                      onClick={() => setActiveTab('diagnostic')}
                  >
                      DIAGNOSTIC
                  </h3>
                </div>
              </div>
              <div className='tabs-content'>
              {activeTab === 'all' && (
                  <div className='offere-cards'>
                    <div className='offer-card'>
                        <div className='offer-top'>
                          <div className='offer-left-img gray-offer-card'>
                            <span>FLAT</span>
                            <div className='offer-percentage'>
                              <h5>50</h5>
                              <div className='percentage-off'>
                                <span>%</span>
                                <h6>OFF</h6>
                              </div>
                            </div>
                          </div>
                          <div className='offer-right-details'>
                            <div className='left-offer-detail'>
                              <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                              <p>Only on Healthcare Products on Orders above $100</p>
                            </div>
                            <div className='right-offer-arrow'>
                                <Link to='/'>
                                  <img src={RightIcon} alt='right-icon'></img>
                                </Link>
                            </div>
                          </div>
                        </div>
                        <div className='offer-bottom'>
                          <h5>CODE: {codeToCopy}</h5>
                          <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                        </div>
                    </div>
                    <div className='offer-card'>
                        <div className='offer-top'>
                          <div className='offer-left-img pink-offer-card'>
                            <span>FLAT</span>
                            <div className='offer-percentage'>
                              <h5>50</h5>
                              <div className='percentage-off'>
                                <span>%</span>
                                <h6>OFF</h6>
                              </div>
                            </div>
                          </div>
                          <div className='offer-right-details'>
                            <div className='left-offer-detail'>
                              <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                              <p>Only on Healthcare Products on Orders above $100</p>
                            </div>
                            <div className='right-offer-arrow'>
                                <Link to='/'>
                                  <img src={RightIcon} alt='right-icon'></img>
                                </Link>
                            </div>
                          </div>
                        </div>
                        <div className='offer-bottom'>
                          <h5>CODE: {codeToCopy}</h5>
                          <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                        </div>
                    </div>
                    <div className='offer-card'>
                        <div className='offer-top'>
                          <div className='offer-left-img yellow-offer-card'>
                            <span>FLAT</span>
                            <div className='offer-percentage'>
                              <h5>50</h5>
                              <div className='percentage-off'>
                                <span>%</span>
                                <h6>OFF</h6>
                              </div>
                            </div>
                          </div>
                          <div className='offer-right-details'>
                            <div className='left-offer-detail'>
                              <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                              <p>Only on Healthcare Products on Orders above $100</p>
                            </div>
                            <div className='right-offer-arrow'>
                                <Link to='/'>
                                  <img src={RightIcon} alt='right-icon'></img>
                                </Link>
                            </div>
                          </div>
                        </div>
                        <div className='offer-bottom'>
                          <h5>CODE: {codeToCopy}</h5>
                          <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                        </div>
                    </div>
                    <div className='offer-card'>
                        <div className='offer-top'>
                          <div className='offer-left-img gray-offer-card'>
                            <span>FLAT</span>
                            <div className='offer-percentage'>
                              <h5>50</h5>
                              <div className='percentage-off'>
                                <span>%</span>
                                <h6>OFF</h6>
                              </div>
                            </div>
                          </div>
                          <div className='offer-right-details'>
                            <div className='left-offer-detail'>
                              <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                              <p>Only on Healthcare Products on Orders above $100</p>
                            </div>
                            <div className='right-offer-arrow'>
                                <Link to='/'>
                                  <img src={RightIcon} alt='right-icon'></img>
                                </Link>
                            </div>
                          </div>
                        </div>
                        <div className='offer-bottom'>
                          <h5>CODE: {codeToCopy}</h5>
                          <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                        </div>
                    </div>
                  </div>
              )}
              {activeTab === 'payment' && (                               
                  <div className='offere-cards'>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img gray-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img pink-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img yellow-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img gray-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                </div>
              )}
              {activeTab === 'medicine' && (
                  <div className='offere-cards'>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img gray-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img pink-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img yellow-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img gray-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                </div>
              )}
              {activeTab === 'diagnostic' && (
                  <div className='offere-cards'>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img gray-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img pink-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img yellow-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                  <div className='offer-card'>
                      <div className='offer-top'>
                        <div className='offer-left-img gray-offer-card'>
                          <span>FLAT</span>
                          <div className='offer-percentage'>
                            <h5>50</h5>
                            <div className='percentage-off'>
                              <span>%</span>
                              <h6>OFF</h6>
                            </div>
                          </div>
                        </div>
                        <div className='offer-right-details'>
                          <div className='left-offer-detail'>
                            <h3>FLAT 50% off* upto $50 Surprice Cashback</h3>
                            <p>Only on Healthcare Products on Orders above $100</p>
                          </div>
                          <div className='right-offer-arrow'>
                              <Link to='/'>
                                <img src={RightIcon} alt='right-icon'></img>
                              </Link>
                          </div>
                        </div>
                      </div>
                      <div className='offer-bottom'>
                        <h5>CODE: {codeToCopy}</h5>
                        <button onClick={copyToClipboard}>{copied ? 'Copied!' : 'Copy Code'}</button>
                      </div>
                  </div>
                </div>
              )}
              </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default Offers;
