import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'antd'; // Import the Button component from Ant Design
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const AddState = () => {
  const navigate = useNavigate(); // Hook to navigate after submission

  // Initialize form data state
  const [formData, setFormData] = useState({
    state_name: '',
    country_id: '', // New state for country selection
  });

  // State to handle submission status and error messages
  const [status, setStatus] = useState({
    success: false,
    message: '',
  });

  // State to store the list of countries
  const [countries, setCountries] = useState([]);

  // Fetch countries when the component is mounted
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const token = authService.getCurrentadmin().token;
        const response = await fetch(authService.getApiUrl() + 'countries', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch countries');
        }

        const result = await response.json();
        setCountries(result.data); // Assuming API returns { data: [...] }
      } catch (err) {
        console.error('Error fetching countries:', err);
      }
    };

    fetchCountries();
  }, []);

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value, // Update the corresponding state property
    });
  };

  // Handle form submission
  const[errors,setErrors]=useState({});
  const handleSubmit = async (event) => {
    event.preventDefault();
    const ValidateErrors=ValidateForm(formData);
    setErrors(ValidateErrors);
    if(Object.keys(ValidateErrors).length===0){
    const token = authService.getCurrentadmin().token;

    try {
      const response = await fetch(authService.getApiUrl() + 'states', {
        method: 'POST',
        body: JSON.stringify({
          state_name: formData.state_name,
          country_id: formData.country_id,
          Is_Active: 'A', // Assuming default value is 'A'
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.statusText}. Details: ${errorText}`);
      }

      const result = await response.json();
      console.log('State added:', result);

      // Set the status message
      setStatus({
        success: true,
        message: 'State added successfully!',
      });

      // Redirect to the states page after 1 second to allow the user to see the message
      setTimeout(() => {
        navigate('/admin/states');
      }, 1000);

    } catch (err) {
      console.error('Error adding state:', err.message);
      setStatus({
        success: false,
        message: `Error: ${err.message}`,
      });
    }
  }else{
    console.log("Form submission failed due to validation errors.");
  }
  };
  const ValidateForm = () => {
    const errors = {};
    const nameRegex = /^[a-zA-Z]+[\-'\s]?[a-zA-Z ]+$/;
 
 
    if (!formData.state_name.trim()) {
      errors.state_name= 'State Name is required';
    } else if (formData.state_name.length < 4) {
      errors.state_name = 'State Name must be at least 4 characters';
    } else if (!nameRegex.test(formData.state_name)) {
      errors.state_name= 'State Name can only contain letters';
    }
    if(!formData. country_id){
      errors. country_id='Select Country';
    }
    return errors;
  };
  return (
    <div className='main-container'>
      <div className='container-fluid'>
        <Breadcrumb/>
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Add State</h3>
            </div>
          </div>
          <div className='card'>
            <div className='card-body custom-edit-service'>
              <form className='needs-validation clearfix' onSubmit={handleSubmit}>
                <div className='addtest-fields mb-3'>
                  <div className='flex-cont'>
                    <div className='half-cont'>
                      <div className='form-group'>
                        <label>
                          State Name 
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='state_name'
                          value={formData.state_name}
                          onChange={handleInputChange}
                     
                        />
                          {errors.state_name && <span className='errors' >{errors.state_name}</span>}
                      </div>
                    </div>
                    <div className='half-cont'>
                      <div className='form-group'>
                        <label>
                          Country <span className='text-danger'>*</span>
                        </label>
                        <select
                          className='form-select form-control'
                          name='country_id'
                          value={formData.country_id}
                          onChange={handleInputChange}
                
                        >
                          <option value=''>--Select--</option>
                          {countries.map((country) => (
                            <option key={country.id} value={country.id}>
                              {country.country_name}
                            </option>
                          ))}
                        </select>
                        {errors.country_id && <span className='errors' >{errors.country_id}</span>}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='submit-section'>
                  <Button type='primary' htmlType='submit' onSubmit={ValidateForm()}>
                    Submit
                  </Button>
                </div> */}
                   <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    name="form_submit"
                    onSubmit={ValidateForm()}
                  >
                    Submit
                  </button>
                </div>
                {status.message && (
                  <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'}`}>
                    {status.message}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddState;
