import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = () => {
  const location = useLocation();

  // Split the current path into segments, remove 'admin' and any leading slashes or empty segments
  const pathnames = location.pathname.split('/').filter(x => x && x !== 'admin');

  // Remove the last segment if it's a number
  if (!isNaN(pathnames[pathnames.length - 1])) {
    pathnames.pop();
  }
  //alert(pathnames);

  return (
    <div className={pathnames[0]=='lab-management'?'main-banner management-page':'main-banner'}>
      <div className='flex-cont'>
        <div className='breadcrum-cont'>
          {/* Static Home link */}
          <span><Link to="/admin/dashboard">Home</Link></span>

          {pathnames.map((value, index) => {
            const to = `/admin/${pathnames.slice(0, index + 1).join('/')}`;

            return (
              <span key={index} className='breadcrumbcss'>
                {/* Separator only after the first element */}
                <span className='seperator'></span>
                {index + 1 === pathnames.length ? (
                  // Last segment (current page) - not clickable
                  <span className='active-category text-capitalize' >{value.replace(/-/g, ' ')}</span>
                ) : (
                  // Clickable breadcrumb links for intermediate segments
                  <Link to={to} className='text-capitalize'>{value.replace(/-/g, ' ')}</Link>
                )}
              </span>
            );
          })}
        </div>
        <div className='back-btn'>
          <Link to="#" onClick={() => window.history.back()}>Back</Link>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
