import React, { useState, useEffect, useRef ,useContext} from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Space,Select} from 'antd';
import EditIcon from './EditIcon';
import Trash from '../../Assets/images/recycle-bin.png';
import Close from '../../Assets/images/close.png';
import Rolesview from './Rolesview';
// import UserRights from '../Menumanagement/UserRights';
import ViewIcon from '../Menumanagement/View';
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { usePermissions } from '../../../Services/PermissionsContext'; 
import { hasPermission } from '../../../Services/checkPermissions';   
import { OrganizationContext } from "../../../Services/OrganizationContext";
import authService from "../../../Services/authService";


const UserMangement = () => {
    const permissions = usePermissions(); 
    const lastpath = window.location.pathname.split("/").pop(); 
    const initialTab = lastpath === "user-management" ? "users" : lastpath;
    const [activeTab, setActiveTab] = useState(initialTab);
    const { organization } = useContext(OrganizationContext);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isActive, setActive] = useState(false);
    const [users, setUsers] = useState([]);
    const dateRef = useRef(null);
    const [selectedRole, setselectedRole] = useState([]);
    const [filteredUserAccess, setFilteredUserAccess] = useState([]); 
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({ search: '' }); 

    const [status, setStatus] = useState("Status");
    useEffect(() => {
        if (organization) {
           loadUsers(status);
           fetchUserAccess();
        }
    }, [organization,status]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const fetchUserAccess = () => {
    const token = authService.getCurrentadmin().token; 
    const url = new URL(authService.getApiUrl() + "rolepermissionsdata");
    url.searchParams.append("organization_id", organization); 

    fetch(url, {
        method: "GET", 
        headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`, 
        },
        })
        .then((res) => res.json())
        .then((result) => {
            setFilteredUserAccess(result.data); 
        })
        .catch((err) => {
        console.log(err.message); 
        });
    };

    
    const handleSearch = async () => {
        const token = authService.getCurrentadmin().token; 
        fetch(authService.getApiUrl() + "rolesFilters", {
            method: "POST", 
            body: JSON.stringify({
            Rolesearch: formData.search,
            organization_id: organization,
            }), 
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`, 
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setFilteredUserAccess(result.data); 
            })
            .catch((err) => {
            console.log(err.message); 
      });
      
    };
  
    const userhandleSearch = async () => {
        const token = authService.getCurrentadmin().token;
        fetch(authService.getApiUrl() + "userfilter", {
            method: "POST", 
            body: JSON.stringify({
                search: formData.search, 
                organization_id: organization, 
            }), 
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`, 
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setUsers(result.data); 
            })
            .catch((err) => {
            console.log(err.message); 
      });
      
    };
    const showModal = (record) => {
        setselectedRole(record);
        setIsModalVisible(true);
    };
    
   
    const closeModal = (record) => {
        setIsModalVisible(false);
        setselectedRole(record); 
    };

    const handleStatusChange = (value) => {
        const token = authService.getCurrentadmin().token; 
    
        fetch(authService.getApiUrl() + "userstatuschange", {
          method: "PUT", 
          body: JSON.stringify({
            is_active: value=='Active'?'1':'0',
            user_id: selectedRows,
            organization_id:organization,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`, 
          },
        })
          .then((res) => res.json())
          .then((result) => {
            loadUsers();
          })
          .catch((err) => {
            console.log(err.message); 
          });
      };

    const loadUsers = () => {
        const token = authService.getCurrentadmin().token;
      const url = new URL(authService.getApiUrl() + "user");
      url.searchParams.append("organization_id", organization); 
  
      fetch(url, {
        method: "GET", 
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`, 
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setUsers(result.data); 
        })
        .catch((err) => {
          console.log(err.message); 
        });
    };


    const dataAdmin = [
        {
            title: "#",
            dataIndex: "id",
            key: "index",
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: "Role Name",
            dataIndex: "role_name",
            key: "role_name",
        },
        {
            title: "Modules",
            dataIndex: "modules",
            key: "modules",
            render: (modules) => (
                <span>
                    {modules.map((module) => module.module_name).join(", ")}
                </span>
            ),
        },
        {
            title: "Sub Modules",
            dataIndex: "modules",
            key: "submodules",
            render: (modules) => (
                <span>
                    {modules
                        .flatMap((module) => module.submodules) 
                        .filter(Boolean) 
                        .join(", ")}
                </span>
            ),
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "is_active",
            render: (isActive) => (
                <span className={isActive === 'A' ? "status-btn-green" : "status-btn-red"}>
                    {isActive === 'A' ? "Active" : "Inactive"}
                </span>
            ),
        },        
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <Space size="middle">
                       {hasPermission(permissions, 'users', 'edit') && 
                            <Link
                            to={"/admin/user-management/edit-roles-permissions/" + record.role_id}
                            className="me-1 btn btn-sm bg-success-light"
                            >
                            <Button type="text" icon={<EditIcon />} />
                            </Link>
                        }
                         <Button
                        type="text"
                        icon={<ViewIcon />}
                        onClick={() => showModal(record)} 
                    />
                        {hasPermission(permissions, 'users', 'delete') && 
                            <Button
                            type="text"
                            icon={
                                <img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />
                            }
                            onClick={() => handleDelete(record.id)}
                            />
                        }
                                </Space>
                            ),
                        },
                    ];
    const handleDelete = (key) => {
        console.log('Deleting key:', key);       
    };

    const handleNotifyClick = () => {
        setDropdownVisible(!isDropdownVisible);
        setActive(!isActive);
    };

    const rowSelection = {
        onChange: (selectedRowKeys, newSelectedRowKeys) => {
          console.log(
            `Selected Row Keys: ${selectedRowKeys}`,
            "Selected Rows: ",
            selectedRows
          );
          setSelectedRows(selectedRowKeys);
        },
      };

    // Checkbox column
    // const checkboxColumn = {
    //     title: <Checkbox checked={selectAllChecked} onChange={handleSelectAll} />, // "Select All" checkbox in header
    //     key: 'checkbox',
    //     render: (_, record) => (
    //         <Checkbox
    //             checked={selectedRowKeys.includes(record.key)}
    //             onChange={(e) => {
    //                 const newSelectedRowKeys = e.target.checked
    //                     ? [...selectedRowKeys, record.key]
    //                     : selectedRowKeys.filter((key) => key !== record.key);
    //                 setSelectedRowKeys(newSelectedRowKeys);
    //                 setSelectAllChecked(newSelectedRowKeys.length === users.length); // Sync "Select All" checkbox
    //             }}
    //         />
    //     ),
    //     width: 50,
    // };

    
  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      render: (text) => <span className='text-capitalize'>{text}</span>,
      sorter: (a, b) => a.user_name.length - b.user_name.length,
    },
    {
        title: "Email",
        dataIndex: "email",
        render: (text) => <span className='text-capitalize'>{text}</span>,
        sorter: (a, b) => a.email.length - b.email.length,
    },
    {
        title: "Role",
        dataIndex: "role",
        render: (text) => <span className='text-capitalize'>{text}</span>,
        sorter: (a, b) => a.role.length - b.role.length,
    },
    {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (isActive) => (
          <span className={isActive === 1 ? "status-btn-green" : "status-btn-red"}>
            {isActive === 1 ? "Active" : "Inactive"}
          </span>
        ),
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <Space size="middle">
          {hasPermission(permissions, 'users', 'edit') && 
            <Link
              to={"/admin/user-management/edit-user/" + record.id}
              className="me-1 btn btn-sm bg-success-light"
            >
              <Button type="text" icon={<EditIcon />} />
            </Link>
          }
          {hasPermission(permissions, 'users', 'delete') && 
            <Button
              type="text"
              icon={
                <img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />
              }
              onClick={() => handleDelete(record.id)}
            />
          }
        </Space>
      ),
    },
  ];

const rolespermissions = selectedRole
        ? [
              {
                  id: selectedRole.role_id,
                  rolename: selectedRole.role_name,
                  modules: selectedRole.modules,
                  active: selectedRole.is_active === "A" ? "Active" : "Inactive"
              }
          ]
        : [];


    return (
        <>
            <div className="main-container">
                <div className="container-fluid">
                    <Breadcrumb/>
                    <div className="category-table">
                        <div className="flex-cont add-cat">
                            <div className="heading">
                                <h3>User Mangement</h3>
                            </div>
                            
                        </div>
                        <div className='flex-cont user-tabs-container'>
                        
                        {/* Tabs */}
                        <div className="search-heading usertabs">
                            {hasPermission(permissions, 'users', 'view') && 
                            <Link to='/admin/user-management/users' >
                                <h3
                                    className={`tablinks ${activeTab === 'users' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('users')}
                                >
                                    Users
                                </h3>
                                </Link>
                            }
                            {hasPermission(permissions, 'roles-permissions', 'view') && 
                            <Link to='/admin/user-management/roles-permissions' >
                            <h3
                                className={`tablinks ${activeTab === 'roles-permissions' ? 'active' : ''}`}
                                onClick={() => setActiveTab('roles-permissions')}
                            >
                                Roles & Permissions
                            </h3>
                            </Link>
                            }
                            {/* <h3
                                className={`tablinks ${activeTab === 'Test Agent' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Test Agent')}
                            >
                                Test Agent
                            </h3>
                            <h3
                                className={`tablinks ${activeTab === 'Order Acceptance' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Order Acceptance')}
                            >
                                Order Acceptance
                            </h3> */}
                        </div>
                        {/* Tab Content */}
                        {activeTab === 'users' && (
                        <div className='action-container usermanagement-search'>
                             <div className='filter-search'>
                             <input
                                    type="text"
                                    className="category-search"
                                    placeholder="Search"
                                    onChange={handleInputChange}
                                    name="search"
                                    id="search"
                                />
                                <button type="submit" onClick={userhandleSearch}>
                                    Search
                                </button>

                            </div>
                            <div className="add-btn">
                            {hasPermission(permissions, 'users', 'add') && 
                                <Link to="/admin/user-management/add-user">+ Add</Link>
                            }
                            </div>
                            <Select
                                defaultValue={status}
                                style={{ width: 150, marginLeft: 10,float:'right' }}
                                onChange={handleStatusChange}
                            >
                                <option value="" selected='selected'>--Select--</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </Select>
                        </div>
                        
                        )}
                        {activeTab === 'roles-permissions' && (
                        <div className='action-container usermanagement-search'>
                           <div className='filter-search'>
                                <input
                                    type="text"
                                    className="category-search"
                                    placeholder="Search"
                                    onChange={handleInputChange}
                                    name="search"
                                    id="search"
                                />
                                <Button type="submit" onClick={handleSearch}>
                                    Search
                                </Button>
                            </div>

                            <div className="add-btn">
                            {hasPermission(permissions, 'roles-permissions', 'add') && 
                                <Link to="/admin/user-management/add-roles-permissions">+ Add</Link>
                            }
                            </div>
                            <div ref={dateRef} className={`filter-drop-down ${isActive ? 'active' : ''}`} onClick={handleNotifyClick}>
                                <div className='filter-element'>
                                <h3 className='filter-title'>Action</h3>
                                </div>
                                <div className='filter-dropdown' style={{ display: isDropdownVisible ? 'block' : 'none' }}>
                                <ul>
                                    <li>Active</li>
                                    <li>Inactive</li>
                                    <li>Remove</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        )}
                        
                        </div>
                        <div className="hospitals-table" id="hospitals_table">
                            {activeTab === 'users' && (
                                <Table
                                rowSelection={rowSelection}
                                    dataSource={users}
                                    columns={columns}
                                    pagination={{ pageSize: 5 }}
                                     rowKey="id"
                                />
                            )}
                          {activeTab === 'roles-permissions' && (
                            <div className="hospitals-table" id="hospitals_table">
                                  <Table
                                    dataSource={filteredUserAccess}
                                    columns={dataAdmin}
                                    pagination={{ pageSize: 5 }}
                                    // rowSelection={{
                                    //     selectedRowKeys,
                                    //     onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
                                    // }}
                                />
                            </div>
                        )}

                            {/* {activeTab === 'Test Agent' && (
                                <Table
                                    dataSource={dataAgent}
                                    columns={columns}
                                    pagination={{ pageSize: 5 }}
                                    rowSelection={{
                                        selectedRowKeys,
                                        onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
                                    }}
                                />
                            )} */}
                        </div>
                    </div>
                </div>
                {isModalVisible && setselectedRole && (
                <div className='user-popup'>
                    <div className="modal" onClick={closeModal}>
                        <div className='modal-dialog modal-lg'>
                            <div className="modal-content">
                                <div className='popup-headings'>
                                    <h3>Role & Permissions Details</h3>
                                    <button type="button" className="close" onClick={closeModal}>
                                        <img src={Close} alt="close" title="close" />
                                    </button>
                                </div>
                                <div className='user-table first-table'>
                                    <div className='user-role'>
                                        <Rolesview data={rolespermissions} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            </div>
        </>
    );
};

export default UserMangement;
