
// export default Categories;
import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Table, Button, Space, Switch,Select } from "antd";
import EditIcon from "../Usermanagement/EditIcon";
import Trash from "../../Assets/images/recycle-bin.png";
import Logs from "../Menumanagement/Logs";
import ViewIcon from "../Menumanagement/View";
import Close from "../../Assets/images/close.png";
import UserData from "./UserData1";
import UserRights from "../Menumanagement/UserRights";
import Filter from "../../Assets/images/share.png";
import { OrganizationContext } from "../../../Services/OrganizationContext";
import authService from "../../../Services/authService";
import TabMenu from "./TabMenu";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { usePermissions } from '../../../Services/PermissionsContext'; // Import the permissions context
import { hasPermission } from '../../../Services/checkPermissions';   // Import the helper function

const Categories = () => {
  const navigate = useNavigate();
  const permissions = usePermissions(); // Get global permissions
  const [activeTab, setActiveTab] = useState("parentcategory"); // Set initial active tab
  const dateRef = useRef(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isActive, setActive] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]); // State for selected rows
  const { organization } = useContext(OrganizationContext);
  const [categoryData, setCategoryData] = useState([]);
  const [activeMenuItem, setActiveMenuItem] = useState([]);
  const [formData, setFormData] = useState({
    category_name: "",
  });
 
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [status, setStatus] = useState("Status");
  useEffect(() => {
    if (organization) {
      loadCategory(status);
    }
  }, [organization,status]);

  const loadCategory = (statusFilter) => {
      const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    // Construct the URL with the organization_id as a query parameter
    const url = new URL(authService.getApiUrl() + "category");
    url.searchParams.append("organization_id", organization); // Append the organization_id as a query parameter

    fetch(url, {
      method: "GET", // Set method to GET
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`, // Include Authorization header with Bearer token if required
      },
    })
      .then((res) => res.json())
      .then((result) => {
        // setCategoryData(result.data); // Set the category data in state
        // const filteredData = result.data.filter((item) =>
        //   statusFilter === "All" || (statusFilter === "Active" ? item.Is_Active === "A" : item.Is_Active === "I")
        // );
        setCategoryData(result.data); // Update state with filtered data
      
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });
  };

  const handleNotifyClick = () => {
    setDropdownVisible(!isDropdownVisible);
    setActive(!isActive);
  };

  const handleDelete = (cid) => {
    // setGridboxesdata((oldValues) => oldValues.filter((item) => item.id !== pid));
      const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
    fetch(authService.getApiUrl() + "category/" + cid, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        alert(result.data);
        loadCategory();
      })
      .catch((err) => {});
  };

  const handleMenuItemClick = () => {
    setActiveMenuItem(!activeMenuItem);
  };

  const handleStatusChange = (value) => {

    //let categoryArray = Array.isArray(selectedRows) ? selectedRows : selectedRows.toString().split(',').map(Number);

    //console.log(value + '/' + selectedRows); // Logs the converted array
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    fetch(authService.getApiUrl() + "categoryStatuschange", {
      method: "PUT", // Change method to PUT
      body: JSON.stringify({
        Is_Active: value=='Active'?'A':'I',
        category_id: selectedRows,
        organization_id:organization,
      }), // Include the data to be updated in the body
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
      },
    })
      .then((res) => res.json())
      .then((result) => {
        loadCategory();
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });
    //setStatus(value); // Update the status filter state
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category_name",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.category_name.length - b.category_name.length,
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text, record) => (
        <>
          <Link className="avatar mx-2" to="#">
          <img
            src={authService.getApiDomainUrl() + record.image}
            alt="Category"
            style={{ width: 50, height: 50 }} 
          />
          </Link>
        </>
      ),
    },

    {
      title: "Status",
      dataIndex: "Is_Active",
      key: "Is_Active",
      render: (text) => (
        <span className={text === "A" ? "status-btn-green" : "status-btn-red"}>
          {text === "A" ? "Active" : "Inactive"}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <Space size="middle">
          {hasPermission(permissions, 'category', 'edit') && 
            <Link
              to={"/admin/lab-management/edit-category/" + record.id}
              className="me-1 btn btn-sm bg-success-light"
            >
              <Button type="text" icon={<EditIcon />} />
            </Link>
          }
          {hasPermission(permissions, 'category', 'delete') && 
            <Button
              type="text"
              icon={
                <img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />
              }
              onClick={() => handleDelete(record.id)}
            />
          }
        </Space>
      ),
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
      const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    fetch(authService.getApiUrl() + "categoryFilter", {
      method: "POST", // Change method to PUT
      body: JSON.stringify({
        category_name: formData.category_name,
        organization_id: organization,
      }), // Include the data to be updated in the body
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCategoryData(result.data); // Set the category data in state
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  
  // Define row selection configuration
  const rowSelection = {
    onChange: (selectedRowKeys, newSelectedRowKeys) => {
      console.log(
        `Selected Row Keys: ${selectedRowKeys}`,
        "Selected Rows: ",
        selectedRows
      );
      setSelectedRows(selectedRowKeys);
    },
  };

  
  return (
    <>
      <div className="main-container">
        <div className="container-fluid">
          <Breadcrumb />
          <TabMenu name="category" />
          <div className="category-table">
            <div className="flex-cont">
              <div className="heading">
                <h3>Categories</h3>
              </div>

              <div className="flex-cont add-cat category-filter">
                <div className="add-btn">
                {hasPermission(permissions, 'category', 'add') && 
                  <Link to="/admin/lab-management/add-category">
                    Add Category
                  </Link>
                }
                </div>
                <div className="date-filter category-filter">
                <div className="add-btn">
                {hasPermission(permissions, 'category', 'add') && 
                  <Link to="/admin/lab-management/import-category">
                    Import Category
                  </Link>
                }
                </div>
                {/* <div className="add-btn">
                     <Link to="/admin/lab-management/import-category">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18" viewBox="0 0 20 20">
                              <defs>
                                 <clipPath id="reportsvg">
                                    <rect id="Rectangle_39" data-name="Rectangle 39" width="20" height="20" transform="translate(5770 1086)" fill="#fff"></rect>
                                 </clipPath>
                                  </defs>
                                  <g id="reports_1" data-name="reports 1" transform="translate(-5770 -1086)" clipPath="url(#reportsvg)">
                                  <path className="svg-stroke-color" id="Path_99" data-name="Path 99" d="M5774.489,1089.638h11.585v12.731h-11.585v-9.835" transform="translate(-0.281 -0.004)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                                  <path className="svg-stroke-color" id="Path_100" data-name="Path 100" d="M5777.506,1093.44h5.309" transform="translate(-0.281 -0.004)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"></path>
                                  <path className="svg-stroke-color" id="Path_101" data-name="Path 101" d="M5777.506,1093.44h5.309" transform="translate(-0.281 2.56)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"></path>
                                  <path className="svg-stroke-color" id="Path_102" data-name="Path 102" d="M5777.506,1093.44h5.309" transform="translate(-0.281 5.125)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"></path>
                                  </g>
                          </svg>
                            Import Test</Link>
                    </div> */}
                  <div
                    ref={dateRef}
                    className={`filter-drop-down ${isActive ? "active" : ""}`}
                    onClick={handleNotifyClick}
                  >
                    <div className="filter-element">
                      <h3 className="filter-title">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="18"
                          height="18"
                          viewBox="0 0 20 20"
                        >
                          <defs>
                            <clipPath id="reportsvg">
                              <rect
                                id="Rectangle_39"
                                data-name="Rectangle 39"
                                width="20"
                                height="20"
                                transform="translate(5770 1086)"
                                fill="#fff"
                              ></rect>
                            </clipPath>
                          </defs>
                          <g
                            id="reports_1"
                            data-name="reports 1"
                            transform="translate(-5770 -1086)"
                            clipPath="url(#reportsvg)"
                          >
                            <path
                              className="svg-stroke-color"
                              id="Path_99"
                              data-name="Path 99"
                              d="M5774.489,1089.638h11.585v12.731h-11.585v-9.835"
                              transform="translate(-0.281 -0.004)"
                              fill="none"
                              stroke="#333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              className="svg-stroke-color"
                              id="Path_100"
                              data-name="Path 100"
                              d="M5777.506,1093.44h5.309"
                              transform="translate(-0.281 -0.004)"
                              fill="none"
                              stroke="#333"
                              strokeLinecap="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              className="svg-stroke-color"
                              id="Path_101"
                              data-name="Path 101"
                              d="M5777.506,1093.44h5.309"
                              transform="translate(-0.281 2.56)"
                              fill="none"
                              stroke="#333"
                              strokeLinecap="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              className="svg-stroke-color"
                              id="Path_102"
                              data-name="Path 102"
                              d="M5777.506,1093.44h5.309"
                              transform="translate(-0.281 5.125)"
                              fill="none"
                              stroke="#333"
                              strokeLinecap="round"
                              strokeWidth="2"
                            ></path>
                          </g>
                        </svg>
                        Export/Import
                      </h3>
                    </div>
                    <div
                      className="filter-dropdown"
                      style={{ display: isDropdownVisible ? "block" : "none" }}
                    >
                      <ul>
                        <li className="active">Export Category</li>
                        <li>Import Category</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Tabs */}
            <div className="search-heading tabs"></div>
            <div className="filter-search">
              <input
                type="text"
                className="category-search"
                placeholder="Search"
                onChange={handleInputChange}
                name="category_name"
                id="category_name"
              />
              <Button type="submit" onClick={handleSubmit}>
                Search
              </Button>
            </div>
            <Select
                defaultValue={status}
                style={{ width: 150, marginLeft: 10,float:'right' }}
                onChange={handleStatusChange}
              >
                <option value="" selected='selected'>--Select--</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
            </Select>

            {/* Tab Content */}
            <div className="hospitals-table" id="hospitals_table">
              <Table
                rowSelection={rowSelection} // Enable row selection
                dataSource={categoryData}
                columns={columns} // Reuse columns for simplicity
                pagination={{ pageSize: 10 }}
                rowKey="id" 
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;

