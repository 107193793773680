import React, { useEffect } from 'react';
import { Routes, Route ,useLocation } from 'react-router-dom';
import AdminRoutes from './AdminRoutes';
import ClientRoutes from './ClientRoutes';

const App = () => {
  return (
    <Routes>
      <Route path="/*" element={<ClientRoutes />} />
      <Route path="admin/*" element={<AdminRoutes />} />
      {/* Add more routes as needed */}
    </Routes>
  );
};

export default App;
