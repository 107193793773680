import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Backbtn from '../../Assets/images/Back-btn.png';
import ProfileImg from '../../Assets/images/Mask group.png';
import Close from '../../Assets/images/close.png';
import authService from '../../../Services/authService';
import AddressIcon from '../../Assets/Icons/AddressIcon';

const UserAddress = () => { 
    const [activeTab, setActiveTab] = useState(null);
    const [categories, setCategories] = useState([]); 
    const [familyMembers, setFamilyMembers] = useState([]); 
    const [Address, setAddress] = useState([]); 
    const [formData, setFormData] = useState({
        gender: '',
        name: '',
        age:'',
        relation:''
    });

    useEffect(() => {

        //loadFamilydetails();
        loadAddress();

    }, []); 

    
    const openTab = (cityName) => {
        setActiveTab(cityName);
    };
    
    const closeTab = () => {
    setActiveTab(null);
    }; 



    // const loadFamilydetails = () => {

    //     const url = new URL(authService.getApiUrl() + 'userfamilylist' );
      
    //     fetch(url, {
    //       method: 'POST',
    //       body: JSON.stringify({ 
    //         user_id:authService.getCurrentuser().id,
    //       }), 
    //       headers: {
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json; charset=UTF-8',
    //       },
    //     })
    //       .then((res) => {
    //           return res.json();
    //       })
    //       .then((result) => {
    //         //const data = result.data;
    //         setFamilyMembers(result.data);

    //       })
    //       .catch((err) => {
    //         console.log(err.message);
    //       });
    // };

    const loadAddress = () => {

        const url = new URL(authService.getApiUrl() + 'Addresslist' );
      
        fetch(url, {
          method: 'POST',
          body: JSON.stringify({ 
            user_id:authService.getCurrentuser().id,
          }), 
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
          .then((res) => {
              return res.json();
          })
          .then((result) => {
            //const data = result.data;
            setAddress(result.data);

          })
          .catch((err) => {
            console.log(err.message);
          });
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        fetch(authService.getApiUrl() + 'addfamily', {
            method: 'POST', // Change method to PUT
            body: JSON.stringify({  user_id: authService.getCurrentuser().id,
                                    name:formData.name,
                                    age:formData.age,
                                    gender:formData.gender,
                                    relation:formData.relation,

            }), // Include the data to be updated in the body
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            })
            .then((res) => res.json())
            .then((result) => {
              console.log(result.data)
              //loadFamilydetails();
              setActiveTab(null);
            })
            .catch((err) => {
                console.log(err.message); // Handle any errors
            });
    }

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
          ...formData,
          [name]:  value,
        });
    };

      
    return (
        <div className='homepageWrapper'>
            <div className='client-container'>
                <div className='add-member-cont'>
                    <div className='back-heading-add'>
                        <div className='back-btn-add'>
                            <Link to='#' onClick={() => window.history.back()}><img src={Backbtn} alt='back-btn' title='back-btn'></img></Link>
                        </div>
                        <h1>Address</h1>
                    </div>
                    <div className='saved-addresses'>
                        {Address.map((data, index) => (
                            <div className='member'>
                                <div className='mem-image'>
                                    <AddressIcon />
                                    
                                </div>
                                <div className='user-details'>
                                    <h3 className='user-name'>{data.building_name}</h3>
                                    
                                        <p >{data.landmark}, 
                                       {data.area}, 
                                         {data.area}, {data.city_name}, {data.state_name}, {data.pincode}</p>
                                        <p>Phone Number : {data.phone_number}</p>
                                    
                                </div>
                            </div>
                        ))}
       
                    </div>
                </div>
            </div>
            <div className='add-mem-btn-cont'>
                <div className="client-container">
                <Link to='/profile/addaddress'>
                  <button className='add-mem-btn'>Add New Member</button>
                </Link>
                </div>
            </div>
     
        </div>
    );
};

export default UserAddress;
