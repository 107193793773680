import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import TabMenu from './TabMenu';

const ImportCategory = () => {
  const navigate = useNavigate();
  const { organization } = useContext(OrganizationContext);

  const [error, setError] = useState('');

  const [file, setFile] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile(e.target.files[0]);
  };
  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const token = authService.getCurrentadmin().token; 
    const base64String = file?await convertImageToBase64(file):"";
    fetch(authService.getApiUrl() + 'categoryimport', {
    method: 'POST', 
    body: JSON.stringify({ 
                           image:base64String,
                           organization_id: organization,
     }),
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
    },
    })
    .then((res) => res.json())
    .then((result) => {
        navigate(`/admin/lab-management/category`);
    })
    .catch((err) => {
        console.log(err.message); 
    });

};

return (
    <>
      <div className='main-container'>
        <div className='container-fluid'>
          <Breadcrumb/>
          <TabMenu name="category"/>
          <div className='category-table'>
            <div className='flex-cont add-cat'>
              <div className='heading'>
                <h3>Import Categories</h3>
              </div>
            </div>
            <div className="card">
              <div className="card-body custom-edit-service">
                <form className="needs-validation clearfix" onSubmit={handleSubmit} key="addform">
                  <div className="addtest-fields mb-3">
                    <div className="flex-cont">

                      <div className="full-cont">
                        <div className='upload-img-cont'>
                          <label>
                            Upload CSV File <span className="text-danger">*</span>
                          </label>
                          <input
                            type="file"
                            id="file"
                            accept=".csv"
                            name="file"
                            onChange={handleImageChange}
                            className="form-control"
                            
                          />
                          {error && <p style={{ color: 'red' }}>{error}</p>}
                          <small className="text-secondary" style={{float:'right'}}>
                            Download sample file format <b><a href={authService.getApiDomainUrl()+'uploads/category/category.csv'}>Download</a></b>
                          </small>
                        </div>
                      </div>
                    </div>

                    
                      <div className="submit-section">
                        <button
                          className="btn btn-primary submit-btn"
                          type="submit"
                          name="form_submit"
                        >
                          Submit
                        </button>
                      </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportCategory;
