import React from 'react';
import { Link } from 'react-router-dom';
import { usePermissions } from '../../../Services/PermissionsContext'; // Import the permissions context
import { hasPermission } from '../../../Services/checkPermissions';   // Import the helper function

const TabMenu = ({ name }) => {
    const permissions = usePermissions(); // Get global permissions
    if (permissions.length === 0) {
        return <div>No permissions available</div>; // Handle case when there are no permissions
    }

    return (
        <div className='management-tabs'>
            <ul className='management-tablinks'>
               {hasPermission(permissions, 'labtests', 'view') && 
                    <li className={`action-item ${name === 'labtest' ? 'active-tab' : ''}`}>
                        <Link to="/admin/lab-management">Lab Tests</Link>
                    </li>
                }
                {hasPermission(permissions, 'category', 'view') && 
                <li className={`action-item ${name === 'category' ? 'active-tab' : ''}`}>
                    <Link to="/admin/lab-management/category">Categories</Link>
                </li>
                }
                {hasPermission(permissions, 'packages', 'view') && 
                <li className={`action-item ${name === 'packages' ? 'active-tab' : ''}`}>
                    <Link to="/admin/lab-management/packages">Packages</Link>
                </li>
                }
                {/* <li className='action-item'>
                    <Link to="/">Addons</Link>
                </li>
                <li className='action-item'>
                    <Link to="/">Taxes</Link>
                </li> */}
                {/* <li className='action-item'>
                    <Link to="/">Discounts</Link>
                </li> */}
            </ul>
        </div>
    );
};

export default TabMenu;
