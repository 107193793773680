import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'antd'; // Import the Button component from Ant Design
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const AddCountry = () => {
  const navigate = useNavigate(); // Hook to navigate after submission
  
  // Initialize form data state
  const [formData, setFormData] = useState({
    country_name: '',
  });
  
  // State to handle submission status and error messages
  const [status, setStatus] = useState({
    success: false,
    message: '',
  });

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value, // Update the corresponding state property
    });
  };

  // Handle form submission
  const[errors,setErrors]=useState({});
  const handleSubmit = async (event) => {
    event.preventDefault();
    const ValidationErrors=FormValidate(formData);
    setErrors(ValidationErrors);
    if(Object.keys(ValidationErrors).length ===0){
    const token = authService.getCurrentadmin().token;
  
    try {
      const response = await fetch(authService.getApiUrl() + 'countries', {
        method: 'POST',
        body: JSON.stringify({
          country_name: formData.country_name,
          Is_Active: "A",
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.statusText}. Details: ${errorText}`);
      }
  
      const result = await response.json();
      console.log('Country added:', result);
      setStatus({
        success: true,
        message: 'Country added successfully!',
      });
      // Navigate to the desired route
      navigate('/admin/countries');
    } catch (err) {
      console.error('Error adding country:', err.message);
      setStatus({
        success: false,
        message: `Error: ${err.message}`,
      });
    }
  }else{
    console.log("form not submitted due to errors");
  }
  };
  const FormValidate=()=>{
    const errors={};
    const countryreg=/^[a-z A-Z]+$/
    if(!formData.country_name){
      errors.country_name='Country name is required';
    }else if(!countryreg.test(formData.country_name)){
      errors.country_name='Country contains only letters';
    }
  return errors;
  }
  return (
    <div className='main-container'>
      <div className='container-fluid'>
        <Breadcrumb/>
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Add Country</h3>
            </div>
          </div>
          <div className="card">
            <div className="card-body custom-edit-service">
              <form className="needs-validation clearfix" onSubmit={handleSubmit}>
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Country Name 
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="country_name"
                          value={formData.country_name}
                          onChange={handleInputChange}
                        />
                        {errors.country_name&&(<span className='error-message'>{errors.country_name}</span>)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <Button type="submit" htmlType="submit"
                  className="btn btn-primary submit-btn"  name="form_submit"
                  onSubmit={FormValidate()}>Submit</Button>
                </div>
                {status.message && (
                  <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'}`}>
                    {status.message}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCountry;
