import React, { useState, useEffect, useRef,useContext } from 'react';
import Logo from '../Assets/images/logo.png';
import Notification from '../Assets/images/notification.png';
import ProfileImg from '../Assets/images/settings.png';
// import Popup from '../Components/popup';
import close from '../Assets/images/close.png';
import Search from '../Assets/images/search.png';
import Hospital from '../Assets/images/hospital.png';
import { OrganizationContext } from '../../Services/OrganizationContext';
import authService from '../../Services/authService';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Icon from '../Assets/menuicons/Icon';
import { useLocation } from 'react-router-dom';


const DashMenu = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const dropdownRef = useRef(null);
  const profileRef = useRef(null);
  const sidebarRef = useRef(null);
  const [organizations, setOrganizations] = useState([]);
  const { organization, setOrganization } = useContext(OrganizationContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Get the current URL path
  const currentPath = location.pathname;

  // Split the path into segments
  const pathSegments = currentPath.split('/').filter(segment => segment);

  // Get the second segment (index 1)
  const secondSegment = pathSegments[1] || 'No second segment';


  useEffect(() => {
    loadOrganizations();
    const storedOrganization = authService.getAdminOrganizationID();
    if (storedOrganization) {
      setOrganization(storedOrganization);
    }
  }, []);


  const loadOrganizations = () => {
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorization
    
      // Construct the URL with the organization_id as a query parameter
      const url = new URL(authService.getApiUrl() + 'organizations');
      //url.searchParams.append('organization_id', organization); // Append the organization_id as a query parameter
    
      fetch(url, {
        method: 'GET', // Set method to GET
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`, // Include Authorization header with Bearer token if required
        },
      })
      .then((res) => {
        if (res.status === 401) {
          // If status is 401, redirect to the login page
          alert("Unauthorized! Redirecting to login page.");
          authService.logout();
          window.location.href="/admin"; // Replace with your login route
        } else {
          return res.json(); // Proceed with parsing JSON if the response is okay
        }
      })
        .then((result) => {
          setOrganizations(result.data); // Set the category data in state
        })
        .catch((err) => {
          console.log(err.message); // Handle any errors
        });
    
  };

  const handleDropdownClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isActive, setActive] = useState(false);

  const handleNotifyClick = () => {
    setDropdownVisible(!isDropdownVisible);
    setActive(!isActive);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
      setActive(false);
    }
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setSetVisible(false);
      setDctive(false);
    }
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      // Handle clicks outside the sidebar if needed
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isSetVisible, setSetVisible] = useState(false);
  const [areDctive, setDctive] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleProfileClick = () => {
    setSetVisible(!isSetVisible);
    setDctive(!areDctive);
  };

  const handleToggleMenuClick = () => {
    document.body.classList.toggle('sidebar-open');
    setSidebarOpen(!isSidebarOpen);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const menuItems = [
    'Item 1',
  ];

  const closeModal=()=>{
    setPopupVisible(false);
  }

  // const handleOrganizationChange = (e) => {
  //   const organizationId = e.target.value;
  //   authService.setAdminorganizationID(organizationId);
  //   setOrganization(organizationId);
  //   //window.location.reload();
  // };

  const handleOrganizationChange = (organizationId) => {
    //const organizationId = e.target.value;
    authService.setAdminorganizationID(organizationId);
    setOrganization(organizationId);
    setPopupVisible(false);
  };

  const handleLogout=()=>{
    authService.logout();  // Clear the session from auth service
    sessionStorage.clear();  // Clear sessionStorage (including user data)
    localStorage.clear();    // Clear localStorage if needed
    window.location.href = "/admin";  // Redirect to login page or another route
  }

  return (
    <>
      <nav>
        <div className='navbar ce-header'>
          <div className='container'>
            <div className='flex-cont'>
              <div className='logo-menu'>
                <div className={`toogle-menu ${isSidebarOpen ? 'is-active' : ''}`} id='Toogle-menu' onClick={handleToggleMenuClick}>
                  <span>toggle menu</span>
                </div>
                <div className='logo-img'>
                  <img src={Logo} alt='site-logo' title='site-logo' />
                </div>
                {authService.getCurrentadmin() && authService.getCurrentadmin().UserInfo.role_id === 2 && (
                  <div className='dropdown' onClick={handleDropdownClick}>
                    <input type='hidden' name='selected_hospital_id' id='selected_hospital_id' value='' />
                    <span className='branch-dropdown'>
                      
                      {/* <input  placeholder='Select branch'/> */}
                      <select
                        disabled
                        onChange={handleOrganizationChange}
                        value={organization}
                      >
                        <option value='0' key='0'>--Select--</option>
                        {organizations.map((item, i) => (
                          <option value={item.id} key={i}>{item.name}</option>
                        ))}
                      </select>
                      <span className='abs-btn' onClick={handleDropdownClick}></span>
                    </span>
                  </div>
                )}

              </div>
              <div className='notify'>
                <div ref={dropdownRef} className={`notify-cont ${isActive ? 'active' : ''}`} onClick={handleNotifyClick}>
                  <span><img src={Notification} alt='notification' title='notification' /></span>
                  <div className='notify-dropdown' style={{ display: isDropdownVisible ? 'block' : 'none' }}>
                    <ul>
                      <li>This is a notification</li>
                      <li>This is a notification</li>
                      <li>This is a notification</li>
                    </ul>
                  </div>
                </div>
                <div ref={profileRef} className={`profile-cont ${areDctive ? 'active' : ''}`} onClick={handleProfileClick}>
                  <div className='profile-img'>
                    <img src={ProfileImg} alt='profile-img' title='profile-img' />
                  </div>
                  <div className='profile-dropdown' style={{ display: isSetVisible ? 'block' : 'none' }}>
                    <ul>
                      <li>Edit Profile</li>
                      <li onClick={handleLogout}>Logout</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {isPopupVisible && 
          <div className="modal">
          <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className='top-heading'>
                <div className='popup-headings'>
                  <h3>Select Outlet</h3>
                  <button type="button" className="close" onClick={closeModal}>
                    <img src={close} alt="close" title="close" />
                  </button>
                </div>
                <div className='p-2'>
                  <div className='search-bar'>
                    <span className='search-icon'>
                      <img src={Search} alt='search' title='search' />
                    </span>
                    <input type='text' placeholder='Search' className='search-Input' id='header_text' />
                    <span className='clear'>Clear</span>
                  </div>
                </div>
              </div>
              <div className='hospitals'>
                <div className='All-cont'>
                  <div className='hospital-detail'>
                    <img src={Hospital} alt='hospital' title='hospital' />
                    <h3>All Hospitals</h3>
                  </div>
                </div>
                <div className='all-hospitals'>
                  {organizations.map((org, index) => (
                    <div className='hospital-cont' key={index} onClick={() => handleOrganizationChange(org.id)}>
                      <div className='hospital-detail'>
                        <img src={Hospital} alt='hospital' title='hospital' />
                        <h3>{org.name || "Pranaam"}</h3>
                      </div>
                      <div className='hsp-id'>
                        <p>[ id : {org.id} ]</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop" onClick={closeModal}></div>
        </div>
      // <Popup showModal={isPopupVisible} closeModal={handleClosePopup} organizations={organizations}/>
      }






      <div className='sidebar sidebar-menu' ref={sidebarRef}>
        <div className='scroll-content'>
          <ul>
            {authService.getCurrentadmin() && authService.getCurrentadmin().sidemenu ? (
            authService.getCurrentadmin().sidemenu.map((item, index) => {
              const link = (item.menu.sub_menu && item.menu.sub_menu.length > 0 && item.menu.sub_menu[0].path) 
                            ? item.menu.path+'/'+item.menu.sub_menu[0].path : item.menu.path;
              return (
              <>

                {item.menu.path === 'organizations' && (
                  <span className='menu-heading'>
                    <div className="profile-img">
                      <img src={ProfileImg} alt="profile-img" title="profile-img"></img>
                    </div>
                    Master Setup
                  </span>
                )}

                {/* {item.menu.sub_menu && item.menu.sub_menu.length > 0 ? (
                  <li 
                    key={index}
                    className={`sidebar-dropdown table-icon ${activeIndex === index ? 'dropmenu' : ''}`}
                    onClick={() => handleDropdown(index)}
                  >
                    <a id='left_dashboard'>
                      <Icon name={item.menu.icon} />
                      <span className='menu-title'>{item.menu.name}</span>
                    </a>
                    <ul className='sidebar-submenu' style={{display: 'none'}}>
                      {item.menu.sub_menu.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Link to="/admin/orders">
                            <span className='menu-title'>{subItem.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : ( */}
                  <li className={`dashboard ${secondSegment === item.menu.path ? 'active' : ''}`} key={index}>
                    <a id='left_dashboard' href={'/admin/' + link}>
                      <Icon name={item.menu.icon} />
                      <span className='menu-title'>{item.menu.name}</span>
                    </a>

                  </li>
                 {/* )} */}
              </>
               );
              })
              ) : (
                <li></li>
              )}


            {/* <li className='dashboard active'>
              <a id='left_dashboard' href='/admin/dashboard'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 20 20">
                  <defs>
                    <clipPath id="dashboardsvg">
                      <rect id="Rectangle_1" data-name="Rectangle 1" width="20" height="20" transform="translate(5770 966)" strokeLinecap="round" strokeWidth="1"></rect>
                    </clipPath>
                  </defs>
                  <g className="svg-stroke-color" id="dashboard_1" data-name="dashboard 1" transform="translate(-5770 -966)" clipPath="url(#dashboardsvg)">
                    <path id="Path_5" data-name="Path 5" d="M0,2.929V0H6V6H0" transform="translate(5778.5 977.511) rotate(90)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                    <path id="Path_18" data-name="Path 18" d="M0,2.929V0H6V6H0" transform="translate(5781.5 974.511) rotate(-90)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                    <path id="Path_17" data-name="Path 17" d="M0,2.929V0H6V6H0" transform="translate(5781.5 977.511)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                    <path id="Path_19" data-name="Path 19" d="M6,3.071V6H0V0H6" transform="translate(5772.5 968.511)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                  </g>
                </svg>
                <span className='menu-title'>Dashboard</span> 
              </a>
            </li>

            <li 
            className='dashboard'
            >
              <Link to="/admin/categories" id='left_dashboard'>

                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18" viewBox="0 0 20 20">
                <defs>
                  <clipPath id="menusvg">
                    <rect
                      id="Rectangle_36"
                      data-name="Rectangle 36"
                      width="20"
                      height="20"
                      fill="#fff"
                    ></rect>
                  </clipPath>
                </defs>
                <g id="menu_1" data-name="menu 1" clipPath="url(#menusvg)">
                  <g
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    transform="translate(2.09 14.5)"
                    fill="#fff"
                    stroke="#333"
                    strokeWidth="1"
                  >
                    <circle className="svg-fill-color" cx="1" cy="1" r="1" stroke="none"></circle>
                    <circle
                      className="svg-stroke-color"
                      cx="1"
                      cy="1"
                      r="0.5"
                      fill="none"
                    ></circle>
                  </g>
                  <path
                    className="svg-stroke-color"
                    id="Path_85"
                    data-name="Path 85"
                    d="M5820.286,1075.157h-11.3"
                    transform="translate(-5802.376 -1059.657)"
                    fill="none"
                    stroke="#333"
                    strokeLinecap="round"
                    strokeWidth="2"
                  ></path>
                  <path
                    className="svg-stroke-color"
                    id="Path_86"
                    data-name="Path 86"
                    d="M5823.8,1075.157h-14.82"
                    transform="translate(-5805.895 -1065.157)"
                    fill="none"
                    stroke="#333"
                    strokeLinecap="round"
                    strokeWidth="2"
                  ></path>
                  <path
                    className="svg-stroke-color"
                    id="Path_87"
                    data-name="Path 87"
                    d="M5823.8,1075.157h-14.82"
                    transform="translate(-5805.895 -1070.657)"
                    fill="none"
                    stroke="#333"
                    strokeLinecap="round"
                    strokeWidth="2"
                  ></path>
                </g>
                </svg>
                <span className='menu-title'>Categories</span>
                </Link>
            </li>
            <li 
            className='dashboard'
            >
              <Link to="/admin/addtest" id='left_dashboard' >

              <svg
                fill="#212121"
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#212121"
              >
                <title>lab</title>
                <path d="M19.332 19.041c0 0-1.664 2.125-3.79 0-2.062-2-3.562 0-3.562 0l-4.967 9.79c-0.144 0.533 0.173 1.081 0.706 1.224h16.497c0.533-0.143 0.85-0.69 0.707-1.224l-5.591-9.79zM26.939 28.33l-7.979-13.428v-0.025l-0.014-7.869h0.551c0.826 0 1.498-0.671 1.498-1.499 0-0.827-0.672-1.498-1.498-1.498h-7.995c-0.827 0-1.498 0.671-1.498 1.498 0 0.828 0.671 1.499 1.498 1.499h0.482l-0.016 7.871-6.908 13.451c-0.428 1.599 0.521 3.242 2.119 3.67h17.641c1.6-0.428 2.549-2.071 2.119-3.67zM24.553 30.998l-17.108-0.019c-1.065-0.286-1.697-1.382-1.412-2.446l6.947-13.616 0.021-8.908h-1.498c-0.275 0-0.499-0.224-0.499-0.5s0.224-0.499 0.499-0.499h7.995c0.275 0 0.498 0.224 0.498 0.499 0 0.276-0.223 0.5-0.498 0.5h-1.498l0.025 8.875 7.939 13.666c0.286 1.067-0.347 2.163-1.411 2.448zM16.48 2.512c0 0.552 0.448 1 1 1s1-0.448 1-1-0.447-1-1-1-1 0.447-1 1zM17.48 0.012c0.828 0 1.5-0.671 1.5-1.5s-0.672-1.5-1.5-1.5-1.5 0.671-1.5 1.5 0.672 1.5 1.5 1.5zM13.48 2.512c0.553 0 1-0.448 1-1s-0.447-1-1-1-1 0.448-1 1 0.447 1 1 1z"></path>
              </svg>
                <span className='menu-title'>Wellness Test</span>
              </Link>
            </li>

            {menuItems.map((item, index) => (
            <li 
            key={index}
            className={`sidebar-dropdown table-icon ${activeIndex === index ? 'dropmenu' : ''}`}
            onClick={() => handleDropdown(index)}
            >
              <a id='left_dashboard'>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18" viewBox="0 0 20 20">
                  <defs>
                    <clipPath id="tablessvg">
                      <rect id="Rectangle_15" data-name="Rectangle 15" width="20" height="20" transform="translate(5770 1035)" fill="#fff"></rect>
                    </clipPath>
                  </defs>
                  <g id="tables_1" data-name="tables 1" transform="translate(-5770 -1035)" clipPath="url(#tablessvg)">
                    <path className="svg-stroke-color" id="Path_65" data-name="Path 65" d="M5780,1042.981v8.648" transform="translate(0 -0.461)" fill="none" stroke="#333" strokeLinecap="round" strokeWidth="2"></path>
                    <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(5771 1038.303)" fill="none" strokeWidth="2">
                      <ellipse className="svg-fill-stroke-none" cx="9" cy="2.5" rx="9" ry="2.5" stroke="none"></ellipse>
                      <ellipse className="svg-stroke-color" cx="9" cy="2.5" rx="8" ry="1.5" fill="none"></ellipse>
                    </g>
                    <path className="svg-stroke-color" id="Path_66" data-name="Path 66" d="M5776.367,1052.393h6.981" transform="translate(0.142 -0.697)" fill="none" stroke="#333" strokeLinecap="round" strokeWidth="2"></path>
                  </g>
                </svg>
                <span className='menu-title'>Orders</span>
              </a>
              <ul className='sidebar-submenu' style={{display: 'none'}}>
                <li>

                  <Link to="/admin/orders">
                    <span className='menu-title'>All Orders</span>
                  </Link>

                </li>
                <li>
           
                  <Link to="#">
                    <span className='menu-title'>Completed Orders</span>
                    </Link>
                
                </li>
              </ul>
            </li>
            
            ))}

            <li 
            className='dashboard'
            >
              <Link to="/admin/reports" id='left_dashboard'>
          
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18" viewBox="0 0 20 20">
                <defs>
                    <clipPath id="reportsvg">
                        <rect id="Rectangle_39" data-name="Rectangle 39" width="20" height="20" transform="translate(5770 1086)" fill="#fff"></rect>
                    </clipPath>
                </defs>
                <g id="reports_1" data-name="reports 1" transform="translate(-5770 -1086)" clipPath="url(#reportsvg)">
                    <path className="svg-stroke-color" id="Path_99" data-name="Path 99" d="M5774.489,1089.638h11.585v12.731h-11.585v-9.835" transform="translate(-0.281 -0.004)" fill="none" stroke="#333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                    <path className="svg-stroke-color" id="Path_100" data-name="Path 100" d="M5777.506,1093.44h5.309" transform="translate(-0.281 -0.004)" fill="none" stroke="#333" strokeLinecap="round" strokeWidth="2"></path>
                    <path className="svg-stroke-color" id="Path_101" data-name="Path 101" d="M5777.506,1093.44h5.309" transform="translate(-0.281 2.56)" fill="none" stroke="#333" strokeLinecap="round" strokeWidth="2"></path>
                    <path className="svg-stroke-color" id="Path_102" data-name="Path 102" d="M5777.506,1093.44h5.309" transform="translate(-0.281 5.125)" fill="none" stroke="#333" strokeLinecap="round" strokeWidth="2"></path>
                </g>
                </svg>
                <span className='menu-title'>Reports</span>
                </Link>
            </li>
            <span className='menu-heading'>
              <div className="profile-img">
                <img src={ProfileImg} alt="profile-img" title="profile-img"></img>
                </div>
                Master Setup
            </span>
            <li 
            className='dashboard'
            >
              <Link to="/admin/locations" id='left_dashboard'>

              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill="#212121"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fill="#212121"
                    d="M288 896h448q32 0 32 32t-32 32H288q-32 0-32-32t32-32z"
                  ></path>
                  <path
                    fill="#212121"
                    d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416zM512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544z"
                  ></path>
                  <path
                    fill="#212121"
                    d="M512 512a96 96 0 1 0 0-192 96 96 0 0 0 0 192zm0 64a160 160 0 1 1 0-320 160 160 0 0 1 0 320z"
                  ></path>
                </g>
              </svg>
                <span className='menu-title'>Locations</span>
                </Link>
            </li>
            <li 
            className='dashboard'
            >
              <Link to="/admin/organizations" id='left_dashboard'>
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="#212121"
                stroke="#212121"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M22 4H12V2H5v2H2v18H0v1h11v-5h2v5h11v-1h-2zm-8 18v-5h-4v5H3V5h3V3h5v2h10v17zM5 11h4V7H5zm1-3h2v2H6zm4 3h4V7h-4zm1-3h2v2h-2zm4 3h4V7h-4zm1-3h2v2h-2zM5 16h4v-4H5zm1-3h2v2H6zm4 3h4v-4h-4zm1-3h2v2h-2zm4 3h4v-4h-4zm1-3h2v2h-2z"></path>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                </g>
              </svg>
                <span className='menu-title'>Organizations</span>
                </Link>
            </li>
            <li 
            className='dashboard'
            >
              <Link to="/admin/countries" id='left_dashboard'>
              <svg
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    clipRule="evenodd"
                    d="M10 7.88974C11.1046 7.88974 12 6.98912 12 5.87814C12 4.76716 11.1046 3.86654 10 3.86654C8.89543 3.86654 8 4.76716 8 5.87814C8 6.98912 8.89543 7.88974 10 7.88974ZM10 6.5822C10.3866 6.5822 10.7 6.26698 10.7 5.87814C10.7 5.4893 10.3866 5.17408 10 5.17408C9.6134 5.17408 9.3 5.4893 9.3 5.87814C9.3 6.26698 9.6134 6.5822 10 6.5822Z"
                    fill="#000000"
                    fillRule="evenodd"
                  ></path>
                  <path
                    clipRule="evenodd"
                    d="M5.15 5.62669C5.15 3.0203 7.37393 1 10 1C12.6261 1 14.85 3.0203 14.85 5.62669C14.85 6.06012 14.8114 6.53528 14.7269 7.03578L18 7.8588L25.7575 5.90818C26.0562 5.83306 26.3727 5.90057 26.6154 6.09117C26.8581 6.28178 27 6.57423 27 6.88395V23.9826C27 24.4441 26.6877 24.8464 26.2425 24.9584L18.2425 26.97C18.0833 27.01 17.9167 27.01 17.7575 26.97L10 25.0193L2.24254 26.97C1.94379 27.0451 1.6273 26.9776 1.38459 26.787C1.14187 26.5964 1 26.3039 1 25.9942V8.89555C1 8.43402 1.3123 8.03172 1.75746 7.91978L5.2731 7.03578C5.18863 6.53528 5.15 6.06012 5.15 5.62669ZM10 2.70986C8.20779 2.70986 6.85 4.06691 6.85 5.62669C6.85 7.21686 7.5125 9.57287 9.40979 11.3615C9.74241 11.6751 10.2576 11.6751 10.5902 11.3615C12.4875 9.57287 13.15 7.21686 13.15 5.62669C13.15 4.06691 11.7922 2.70986 10 2.70986ZM5.80904 8.97453L3.22684 9.62382C3.09349 9.65735 3 9.77726 3 9.91476V24.3212C3 24.5165 3.18371 24.6598 3.37316 24.6121L8.77316 23.2543C8.90651 23.2208 9 23.1009 9 22.9634V13.2506C7.40353 12.024 6.39235 10.4792 5.80904 8.97453ZM11 13.2506V22.9634C11 23.1009 11.0935 23.2208 11.2268 23.2543L16.6268 24.6121C16.8163 24.6598 17 24.5165 17 24.3212V9.91477C17 9.77726 16.9065 9.65735 16.7732 9.62382L14.191 8.97453C13.6076 10.4792 12.5965 12.024 11 13.2506ZM25 22.9634C25 23.1009 24.9065 23.2208 24.7732 23.2543L19.3732 24.6121C19.1837 24.6598 19 24.5165 19 24.3212V9.91477C19 9.77726 19.0935 9.65736 19.2268 9.62382L24.6268 8.26599C24.8163 8.21835 25 8.36159 25 8.55693V22.9634Z"
                    fill="#000000"
                    fillRule="evenodd"
                  ></path>
                </g>
              </svg>
                <span className='menu-title'>Countries</span>
                </Link>
            </li>
            <li 
            className='dashboard'
            >
              <Link to="/admin/cities" id='left_dashboard'>
              <svg
                fill="#000000"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M12 6L12 24L19 24L19 12L24 12L24 6L12 6 z M 15 8L17 8L17 10L15 10L15 8 z M 19 8L21 8L21 10L19 10L19 8 z M 15 12L17 12L17 14L15 14L15 12 z M 21 14L21 39L28 39L28 20L34 20L34 14L21 14 z M 15 16L17 16L17 18L15 18L15 16 z M 24 16L26 16L26 18L24 18L24 16 z M 29 16L31 16L31 18L29 18L29 16 z M 15 20L17 20L17 22L15 22L15 20 z M 24 20L26 20L26 22L24 22L24 20 z M 30 22L30 39L42 39L42 22L30 22 z M 24 24L26 24L26 26L24 26L24 24 z M 33 24L35 24L35 26L33 26L33 24 z M 37 24L39 24L39 26L37 26L37 24 z M 8 26L8 39L19 39L19 26L8 26 z M 11 28L13 28L13 30L11 30L11 28 z M 15 28L17 28L17 30L15 30L15 28 z M 24 28L26 28L26 30L24 30L24 28 z M 33 28L35 28L35 30L33 30L33 28 z M 37 28L39 28L39 30L37 30L37 28 z M 6 30.919922L-0.029296875 45L50.140625 45L44 31.839844L44 41L6 41L6 30.919922 z M 11 32L13 32L13 34L11 34L11 32 z M 15 32L17 32L17 34L15 34L15 32 z M 24 32L26 32L26 34L24 34L24 32 z M 33 32L35 32L35 34L33 34L33 32 z M 37 32L39 32L39 34L37 34L37 32 z"></path>
                </g>
              </svg>
                <span className='menu-title'>Cities</span>
                </Link>
            </li>
            <li 
            className='dashboard'
            >
              <Link to="/admin/states" id='left_dashboard'>
                <svg
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    clipRule="evenodd"
                    d="M10 7.88974C11.1046 7.88974 12 6.98912 12 5.87814C12 4.76716 11.1046 3.86654 10 3.86654C8.89543 3.86654 8 4.76716 8 5.87814C8 6.98912 8.89543 7.88974 10 7.88974ZM10 6.5822C10.3866 6.5822 10.7 6.26698 10.7 5.87814C10.7 5.4893 10.3866 5.17408 10 5.17408C9.6134 5.17408 9.3 5.4893 9.3 5.87814C9.3 6.26698 9.6134 6.5822 10 6.5822Z"
                    fill="#000000"
                    fillRule="evenodd"
                  ></path>
                  <path
                    clipRule="evenodd"
                    d="M5.15 5.62669C5.15 3.0203 7.37393 1 10 1C12.6261 1 14.85 3.0203 14.85 5.62669C14.85 6.06012 14.8114 6.53528 14.7269 7.03578L18 7.8588L25.7575 5.90818C26.0562 5.83306 26.3727 5.90057 26.6154 6.09117C26.8581 6.28178 27 6.57423 27 6.88395V23.9826C27 24.4441 26.6877 24.8464 26.2425 24.9584L18.2425 26.97C18.0833 27.01 17.9167 27.01 17.7575 26.97L10 25.0193L2.24254 26.97C1.94379 27.0451 1.6273 26.9776 1.38459 26.787C1.14187 26.5964 1 26.3039 1 25.9942V8.89555C1 8.43402 1.3123 8.03172 1.75746 7.91978L5.2731 7.03578C5.18863 6.53528 5.15 6.06012 5.15 5.62669ZM10 2.70986C8.20779 2.70986 6.85 4.06691 6.85 5.62669C6.85 7.21686 7.5125 9.57287 9.40979 11.3615C9.74241 11.6751 10.2576 11.6751 10.5902 11.3615C12.4875 9.57287 13.15 7.21686 13.15 5.62669C13.15 4.06691 11.7922 2.70986 10 2.70986ZM5.80904 8.97453L3.22684 9.62382C3.09349 9.65735 3 9.77726 3 9.91476V24.3212C3 24.5165 3.18371 24.6598 3.37316 24.6121L8.77316 23.2543C8.90651 23.2208 9 23.1009 9 22.9634V13.2506C7.40353 12.024 6.39235 10.4792 5.80904 8.97453ZM11 13.2506V22.9634C11 23.1009 11.0935 23.2208 11.2268 23.2543L16.6268 24.6121C16.8163 24.6598 17 24.5165 17 24.3212V9.91477C17 9.77726 16.9065 9.65735 16.7732 9.62382L14.191 8.97453C13.6076 10.4792 12.5965 12.024 11 13.2506ZM25 22.9634C25 23.1009 24.9065 23.2208 24.7732 23.2543L19.3732 24.6121C19.1837 24.6598 19 24.5165 19 24.3212V9.91477C19 9.77726 19.0935 9.65736 19.2268 9.62382L24.6268 8.26599C24.8163 8.21835 25 8.36159 25 8.55693V22.9634Z"
                    fill="#000000"
                    fillRule="evenodd"
                  ></path>
                </g>
              </svg>
                <span className='menu-title'>States</span>
                </Link>
            </li> */}
          </ul>
          
        </div>
      </div>


      {/* footer */}
      <div className='footer-cont'>
      


        <div className="flex-cont">
          <div className="veegam-cont">
            <span>veegam.com © 2024. All Rights Reserved.</span>
          </div>
          <div className="footer-menu">
            <ul className="footer-menu-cont">
              <li><Link to="/">Terms & Conditions</Link></li>
              <li><Link to="/">Privacy Policy</Link></li>
            </ul>
          </div>
        </div>
      </div>
 
    </>
  );
};

export default DashMenu;
