import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import { Button } from 'antd';
import Trash from '../../Assets/images/recycle-bin.png';
import Plus from '../../Assets/images/plus.png';
import TabMenu from './TabMenu';
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const EditPackages = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const { organization } = useContext(OrganizationContext);
  const [packageTests, setPackageTests] = useState([{ category_id: '', lab_test_id: '' }]);
  const [formData, setFormData] = useState({
    package_name: '',
    description: '',
    price: '',
    organization_id: organization ? organization.id : '',
    Is_Active: '',
  });
  const [catorgbasedtests, setCatorgBasedTests] = useState([[]]); 

  useEffect(() => {
    if (organization) {
      loadCategories();
      loadPackageDetails();
    }
  }, [organization]);

  const loadCategories = async () => {
    const token = authService.getCurrentadmin().token; 
    const url = new URL(authService.getApiUrl() + 'category');
    url.searchParams.append('organization_id', organization);

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      setCategories(result.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value,type, checked  } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const loadPackageDetails = async () => {
    const token = authService.getCurrentadmin().token;
    try {
      const response = await fetch(`${authService.getApiUrl()}labtestpackage/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch package details');
      }
      
      const result = await response.json();
      const packageData = result.data;
      setFormData({
        package_name: packageData.package_name,
        description: packageData.description,
        price: packageData.price,
        organization_id: packageData.organization_id,
        // Is_Active: packageData.Is_Active === 'A',
        Is_Active: formData.Is_Active ? 'A' : 'I',
      });
      
      const tests = packageData.package_tests || [];
      setPackageTests(tests);
      setCatorgBasedTests(new Array(tests.length).fill([])); // Initialize based on the number of tests
      tests.forEach((test, index) => loadLabTests(test.category_id, index)); // Load tests for each package test
    } catch (error) {
      console.error('Error fetching package details:', error);
    }
  };

  const loadLabTests = async (categoryId, index) => {
    const token = authService.getCurrentadmin().token;
    try {
      const response = await fetch(authService.getApiUrl() + 'catorgbasedtests', {
        method: 'POST',
        body: JSON.stringify({ organization_id: organization, category_id: categoryId }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });
      
      const result = await response.json();
      setCatorgBasedTests((prevTests) => {
        const updatedTests = [...prevTests];
        updatedTests[index] = result.data || []; 
        return updatedTests;
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const handlePackageTestChange = (index, field, value) => {
    setPackageTests((prevTests) => {
      const updatedPackageTests = [...prevTests];
      updatedPackageTests[index][field] = value;
      // When category changes, reset the lab_test_id and fetch the new lab tests
      if (field === 'category_id') {
        updatedPackageTests[index].lab_test_id = ''; // Reset lab_test_id
        loadLabTests(value, index); // Load new lab tests based on the selected category
      }

      return updatedPackageTests;
    });
  };

  const handleAddTest = () => {
    setPackageTests((prevTests) => [...prevTests, { category_id: '', lab_test_id: '' }]);
    setCatorgBasedTests((prevTests) => [...prevTests, []]); 
  };

  const handleRemoveTest = (index) => {
    setPackageTests((prevTests) => {
      const updatedPackageTests = [...prevTests];
      updatedPackageTests.splice(index, 1);
      return updatedPackageTests;
    });

    setCatorgBasedTests((prevTests) => {
      const updatedCatorgBasedTests = [...prevTests];
      updatedCatorgBasedTests.splice(index, 1);
      return updatedCatorgBasedTests;
    });
  };

  const [errors,setErrors]=useState({});
  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors=FormValidation(formData);
    setErrors(validationErrors);

    if(Object.keys(validationErrors).length===0){
    const token = authService.getCurrentadmin().token;
    try {
      const response = await fetch(authService.getApiUrl() + 'labtestpackage/' + id, {
        method: 'PUT',
        body: JSON.stringify({
          organization_id: organization,
          package_name: formData.package_name,
          description: formData.description,
          price: formData.price,
          Is_Active: formData.Is_Active ? 'A' : 'I',
          package_tests: packageTests,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to update package');
      }

      navigate('/admin/lab-management/packages');
    } catch (err) {
      console.log('Error:', err.message);
    }
  }else{
    console.log("form did not submit due to validation errors");
  }
  };

  const FormValidation=()=>{
    const errors={};
    const nameRex=/^[a-z A-Z-]+$/;
     if(!formData.package_name){
      errors.package_name='Package name is required';
     }else if(formData.package_name.length<4){
      errors.package_name='Package name must consists of 4 letters';
     }else if(!nameRex.test(formData.package_name)){
      errors.package_name='Package name must contains letter only';
     }
     if(!formData.description){
      errors.description='Description required';
     }
     if(!formData.price){
      errors.price='Price is required';
     }

     packageTests.forEach((test) => {
      if (!test.category_id) {
        errors.category_id = 'Category is required';
      }
      if (!test.lab_test_id) {
        errors.lab_test_id= 'Lab test is required';
      }
    });
    
  return errors;
  }

  return (
    <div className='main-container'>
      <div className='container-fluid'>
      <Breadcrumb/>
      <TabMenu name="packages"/>
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Edit Package</h3>
            </div>
          </div>
          <div className="card">
            <div className="card-body custom-edit-service">
              <form className="needs-validation clearfix" onSubmit={handleSubmit}>
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="full-cont">
                      <div className="form-group">
                        <label>
                          Package Name
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="package_name"
                          value={formData.package_name}
                          onChange={(e) => handleInputChange(e)}
                        />
                          {errors.package_name && (<span className="error-message">{errors.package_name}</span>)}

                      </div>
                    </div>

                    {packageTests.map((test, index) => (
                      <div className="row-cont" key={index}>
                        <div className="half-cont">
                          <div className="form-group">
                            <label>
                              Category <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select form-control"
                              name="category_id"
                              value={test.category_id}
                              onChange={(e) => handlePackageTestChange(index, 'category_id', e.target.value)}
                              
                            >
                              <option value="">--Select--</option>
                              {categories.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.category_name}
                                </option>
                              ))}
                            </select>
                            {errors.category_id && (<span className="error-message">{errors.category_id}</span>)}
                          </div>
                        </div>
                        <div className="half-cont">
                          <div className="form-group">
                            <label>
                              Lab Test <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select form-control"
                              name="test_name"
                              value={test.lab_test_id}
                              onChange={(e) => handlePackageTestChange(index, 'lab_test_id', e.target.value)}
                             
                            >
                              <option value="">--Select--</option>
                              {Array.isArray(catorgbasedtests[index]) && catorgbasedtests[index].length > 0 ? (
                                catorgbasedtests[index].map((labTest) => (
                                  <option key={labTest.id} value={labTest.id}>
                                    {labTest.test_name}
                                  </option>
                                ))
                              ) : (
                                <option disabled>No Lab Tests Available</option>
                              )}
                            </select>
                            {errors.lab_test_id && (<span className="error-message">{errors.lab_test_id}</span>)}
                          </div>
                        </div>
                        <div className="action-icon">
                        <Button
                            type="text"
                            icon={<img src={Plus} alt="Add" style={{ width: 18, height: 18 }} />}
                            onClick={handleAddTest}
                        />
                        </div>
                        <div className="action-icon">
                        <Button
                            type="text"
                            icon={<img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />}
                            onClick={() => handleRemoveTest(index)}
                        />
                        </div>
                      </div>
                    ))}
                    
                  </div>
                </div>
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="full-cont">
                      <div className="form-group">
                        <label>
                          Description
                          <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          name="description"
                          rows="4"
                          // required
                          value={formData.description}
                          onChange={(e) => handleInputChange(e)}
                        />
                            {errors.description && (<span className="error-message">{errors.description}</span>)}
                      </div>
                    </div>

                    <div className="full-cont">
                      <div className="form-group">
                        <label>
                          Price
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          name="price"
                          value={formData.price}
                          onChange={(e) => handleInputChange(e)}
                        />
                       {errors.price&& (<span className="error-message">{errors.price}</span>)}
                      </div>
                    </div>
                    <div className="full-cont special-case-cont">
                        <div className='form-group'>
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              name="Is_Active"
                              checked={formData.Is_Active}
                              onChange={handleInputChange}
                              value="A"
                              
                            />
                            <span className="checkmark"></span>
                            <label>Status</label>
                          </label>
                          {/* {errors.Is_Active && <span className='errors' >{errors.Is_Active}</span>} */}
                        </div>
                      </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit" onSubmit={FormValidation()}>
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPackages;
