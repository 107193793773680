import React from 'react';

const ProgressBar = ({ label, value, maxValue }) => {
  // Calculate the percentage for the progress bar
  const percentage = (value / maxValue) * 100;
  
  return (
    <div className="progress-bar-container">
      <div className="progress-bar-label">{label}</div>
      <div className="progress-bar">
        <div 
          className="progress-bar-fill" 
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <div className="progress-bar-value">₹{value}</div>
    </div>
  );
};

export default ProgressBar;
