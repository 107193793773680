import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ProfileImg from '../../Assets/images/User icon.png';
import CartIcon from '../../Assets/images/CartIcon.png';
import CartSearch from '../../Assets/images/Cart-search.png';
import Prescriptionicon from '../../Assets/images/prescriptionicon.png';
import CallLog from '../../Assets/images/call_log.png';
import Ads from '../../Assets/images/Ad.png';
import Recommended from '../../Assets/images/Recommended.png';
import Recommended1 from '../../Assets/images/Recommended1.png';
import liver from '../../Assets/images/liver.svg';
import fever from '../../Assets/images/fever.svg';
import fullbody from '../../Assets/images/fullbody.svg';
import hairfall from '../../Assets/images/hairfall.svg';
import healthpackage from '../../Assets/images/healthpackage.svg';
import kidney from '../../Assets/images/kidney.svg';
import studies from '../../Assets/images/studies.svg';
import thyroid from '../../Assets/images/thyroid.svg';
import vitamin from '../../Assets/images/vitamin.svg';
import womenhealth from '../../Assets/images/womenhealth.svg';
import Combosaver from '../../Assets/images/combosaver.svg';
import diabaties from '../../Assets/images/diabaties.svg';
import Technicians from '../../Assets/images/technicians.svg';
import Reports from '../../Assets/images/reports.svg';
import Ratings from '../../Assets/images/ratings.svg';
import Location from '../../Assets/images/distance.svg';
import Recommended_center from '../../Assets/images/recommended-center.png';
import Recommended_center1 from '../../Assets/images/recommended-center1.png';
import Recommended_center2 from '../../Assets/images/recommended-center2.png';
import Recommended_center3 from '../../Assets/images/recommended-center3.png';
import Stars from '../../Assets/images/stars.png';
import authService from '../../../Services/authService';
import { useNavigate } from 'react-router-dom';

const MainHome = () => {

    const navigate = useNavigate();
    const sliderRef = useRef(null);
    const [counted, setCounted] = useState(false);
    const counterRef = useRef(null);
    const [Hospitals, setHospitals] = useState([]);

    const [formData, setFormData] = useState({
        search: '',
    });

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        cssEase: 'ease-in-out', // Optional, for smoother easing
        // autoplay: true, // Enables autoplay
        // autoplaySpeed: 3000, // Delay between transitions (in ms)

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '20px', // Adjust as needed for larger screens
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {

        //load organizations
        loadOrganizations();

        const handleScroll = () => {
            if (counterRef.current) {
                const oTop = counterRef.current.getBoundingClientRect().top - window.innerHeight;
                if (!counted && window.scrollY > oTop) {
                    animateCounters();
                    setCounted(true);
                }
            }
        };
    
        const animateCounters = () => {
            const counters = document.querySelectorAll('.count');
            counters.forEach((counter) => {
                const countTo = parseFloat(counter.getAttribute('data-count'));
                const isDecimal = countTo % 1 !== 0;
                let increment = isDecimal ? countTo / 100 : Math.max(1, countTo / 100);
        
                // Set a minimum increment for smoother updates
                increment = increment < 0.01 ? 0.01 : increment;
        
                const updateCount = () => {
                    let currentCount = parseFloat(counter.innerText);
                    currentCount = isNaN(currentCount) ? 0 : currentCount;
        
                    if (currentCount < countTo) {
                        currentCount += increment;
                        if (currentCount > countTo) currentCount = countTo; // Ensure it doesn't go over the target
                        
                        // Update counter display
                        counter.innerText = isDecimal ? currentCount.toFixed(1) : Math.floor(currentCount);
                        requestAnimationFrame(updateCount);
                    } else {
                        // Display the final count properly formatted and stop the loop
                        counter.innerText = isDecimal ? countTo.toFixed(1) : countTo;
                    }
                };
        
                // Start the counting animation
                updateCount();
            });
        };
        
        
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        

    }, [counted]);  
    
    const loadOrganizations = () => {

        const url = new URL(authService.getApiUrl() + 'organizationslimit' );
      
        fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
          .then((res) => {
              return res.json();
          })
          .then((result) => {
            setHospitals(result.data);
            //setFormData(data);
          })
          .catch((err) => {
            console.log(err.message);
          });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });

    };


    const handleFormSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        if (formData.search.trim()) {
            // Redirect to the desired path with the input text as a parameter
            navigate(`/lab-tests/hospitals?search=`+`${encodeURIComponent(formData.search.trim().replace(/\s+/g, '-'))}`);
        } else {
            alert("Please enter a valid search term.");
        }
    };

    return (
        <div className='homepageWrapper MainhomeWrapper'>
            <div className='top-animation-sec'>
                <div className="top-sticky-sec">
                    <div className='fixed-container'>
                        <div className='clientprofile-sec'>
                            <div className='profile-title'>
                                <Link to='/'>
                                    <img src={Location} alt='Location-img' />
                                    <div className="Address-cont">
                                        <h3 className='client-name'>Nizampet</h3>
                                        <span>Hyderabad, Telangana</span>
                                    </div>
                                </Link>
                            </div>
                            <div className='cart-cont'>
                                <Link to='/'>
                                    <img src={CartIcon} alt='cart-icon' />
                                    <span className='count-t'>2</span>
                                </Link>
                            </div>
                        </div>
                        <div className='GlobalSearch'>
                            <form className='needs-validation clearfix' onSubmit={handleFormSubmit}>
                                <div className='flex-cont dateinput-cont'>
                                    <div className='form-group offer-rel'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            onChange={handleInputChange}
                                            name="search" 
                                            id="search"
                                            defaultValue={formData.search}
                                            placeholder='Search Medicine & Diagnosis'
                                            required
                                        />
                                        
                                        <button type='submit' className='search-btn' style={{border:'none'}}>
                                            <img src={CartSearch} alt='search' />
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='nonsticky-sec'>
                    <div className='fixed-container'>
                        <div className='easy-way'>
                            <h3>Easy ways to book tests</h3>
                            <div className='ways-cont'>
                                <div className='way-card'>
                                    <Link to='/'>
                                        <span>Upload Prescription</span>
                                        <img src={Prescriptionicon} alt='prescription-icon' />
                                    </Link>
                                </div>
                                <div className='way-card'>
                                    <Link to='/'>
                                        <span>Call us to Place order</span>
                                        <img src={CallLog} alt='calllog-icon' />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='gallery-slider'>
                            <Slider {...settings} ref={sliderRef}>
                                {[...Array(6)].map((_, index) => (
                                    <div className='slide-container' key={index}>
                                        <Link to='/'>
                                            <img src={Ads} alt={`image${index + 1}`} title={`image${index + 1}`} />
                                        </Link>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <div className='client-container'>               
                
                <div className='recommended-cont'>
                    <div className='recommended-head'>
                        <h3>Recommended </h3>
                        <div className='see-all'>
                            <Link to='/'>
                                SEE ALL
                            </Link>
                        </div>
                    </div>
                    <div className='recomended-cards'>
                        <div className='recommended-card'>
                            <figure>
                                <Link to=''>
                                <div className='img-wrap'>
                                    <img src={Recommended} alt='alt-ads'></img>
                                </div>
                                <figcaption>
                                    <span className='discount-patch'>70% Discount</span>
                                    <h3>Vital Screening Package</h3>
                                    <span className='category'>Thyrocare</span>
                                </figcaption>
                                </Link>
                            </figure>
                        </div>
                        <div className='recommended-card'>
                            <figure>
                                <Link to=''>
                                <div className='img-wrap'>
                                    <img src={Recommended1} alt='alt-ads'></img>
                                </div>
                                <figcaption>
                                    <span className='discount-patch'>70% Discount</span>
                                    <h3>Vital Screening Package</h3>
                                    <span className='category'>Thyrocare</span>
                                </figcaption>
                                </Link>
                            </figure>
                        </div>
                        <div className='recommended-card'>
                            <figure>
                                <Link to=''>
                                <div className='img-wrap'>
                                    <img src={Recommended} alt='alt-ads'></img>
                                </div>
                                <figcaption>
                                    <span className='discount-patch'>70% Discount</span>
                                    <h3>Vital Screening Package</h3>
                                    <span className='category'>Thyrocare</span>
                                </figcaption>
                                </Link>
                            </figure>
                        </div>
                        <div className='recommended-card'>
                            <figure>
                                <Link to=''>
                                <div className='img-wrap'>
                                    <img src={Recommended1} alt='alt-ads'></img>
                                </div>
                                <figcaption>
                                    <span className='discount-patch'>70% Discount</span>
                                    <h3>Vital Screening Package</h3>
                                    <span className='category'>Thyrocare</span>
                                </figcaption>
                                </Link>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='recommended-cont'>
                    <div className='recommended-head'>
                        <h3>Recommended </h3>
                        <div className='see-all'>
                            <Link to='/lab-tests/hospitals?search=all'>
                                SEE ALL
                            </Link>
                        </div>
                    </div>
                    <div className='recomended-cards'>
                      {Hospitals.map((data, index) => (
                        <div className='recommended-card' key={index}>
                            <figure>
                              <Link to={'/lab-tests/'+encodeURIComponent(data.name.replace(/ /g, '-'))+'-'+data.id}>
                                <div className='img-wrap'>
                                    <img src={authService.getApiDomainUrl() + data.image} title={data.image} alt={data.image}></img>
                                    <div className='star-rating'>
                                        <img src={Stars} alt='star' title='star'></img>
                                        <span className='ratting-count'>4.6</span>
                                        <span>(456)</span>
                                    </div>
                                </div>
                                <figcaption>
                                    <span className='discount-patch'>1 KM</span>
                                    <h3>{data.name}</h3>
                                    <span className='category'>{data.area_name}</span>
                                </figcaption>
                                </Link>
                            </figure>
                        </div>
                        ))}
                        {/* <div className='recommended-card'>
                            <figure>
                                <Link to=''>
                                <div className='img-wrap'>
                                    <img src={Recommended_center1} alt='alt-ads'></img>
                                    <div className='star-rating'>
                                        <img src={Stars} alt='star' title='star'></img>
                                        <span className='ratting-count'>4.1</span>
                                        <span>(256)</span>
                                    </div>
                                </div>
                                <figcaption>
                                    <span className='discount-patch'>1.1 KM</span>
                                    <h3>Lucid Medical Diagnostics</h3>
                                    <span className='category'>Pragatinagar</span>
                                </figcaption>
                                </Link>
                            </figure>
                        </div>
                        <div className='recommended-card'>
                            <figure>
                                <Link to=''>
                                <div className='img-wrap'>
                                    <img src={Recommended_center2} alt='alt-ads'></img>
                                    <div className='star-rating'>
                                        <img src={Stars} alt='star' title='star'></img>
                                        <span className='ratting-count'>3.8</span>
                                        <span>(156)</span>
                                    </div>
                                </div>
                                <figcaption>
                                    <span className='discount-patch'>1.5 KM</span>
                                    <h3>Lucid Medical Diagnostics</h3>
                                    <span className='category'>Pragatinagar</span>
                                </figcaption>
                                </Link>
                            </figure>
                        </div>
                        <div className='recommended-card'>
                            <figure>
                                <Link to=''>
                                <div className='img-wrap'>
                                    <img src={Recommended_center3} alt='alt-ads'></img>
                                    <div className='star-rating'>
                                        <img src={Stars} alt='star' title='star'></img>
                                        <span className='ratting-count'>3.2</span>
                                        <span>(356)</span>
                                    </div>
                                </div>
                                <figcaption>
                                    <span className='discount-patch'>2.1 KM</span>
                                    <h3>Lucid Medical Diagnostics</h3>
                                    <span className='category'>Pragatinagar</span>
                                </figcaption>
                                </Link>
                            </figure>
                        </div> */}
                    </div>
                    {/* <div className='doctor-health-cont'>
                        <div className='recommended-head'>
                            <h3>Doctor’s Health Checks </h3>
                            <div className='see-all'>
                                <Link to='/'>
                                    SEE ALL
                                </Link>
                            </div>
                        </div>
                        <div className='check-cards'>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={liver} alt='liver'></img>
                                    <h3>Liver</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={fever} alt='fever'></img>
                                    <h3>Fever</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={fullbody} alt='fullbody'></img>
                                    <h3>Full body Checkup</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={hairfall} alt='hairfall'></img>
                                    <h3>Hairfall</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={healthpackage} alt='healthpackage'></img>
                                    <h3>Health Packages</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={kidney} alt='kidney'></img>
                                    <h3>kidney</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={studies} alt='studies'></img>
                                    <h3>Blood Studies</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={thyroid} alt='thyroid'></img>
                                    <h3>Thyroid</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={vitamin} alt='vitamin'></img>
                                    <h3>Vitamin</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={womenhealth} alt='womenhealth'></img>
                                    <h3>Women's Health</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={Combosaver} alt='Combosaver'></img>
                                    <h3>Combo Saver Packs</h3>
                                </Link>
                            </div>
                            <div className='checkup-card'>
                                <Link to='/'>
                                    <img src={diabaties} alt='diabaties'></img>
                                    <h3>Diabaties</h3>
                                </Link>
                            </div>
                            
                        
                        </div>
                    </div> */}
                </div>
                <div className='fullfill-cont'>
                    <div className='recommended-head'>
                        <h3>Fulfilled by Vithyou </h3>
                    </div>
                    <div className='counter' id="counter" ref={counterRef}>
                        <div className='count-cont'>
                            <img src={Technicians} alt='Technicians'></img>
                            <h3 className='count plus' data-count="120">0</h3>
                            <p>Best Technicians</p>
                        </div>
                        <div className='count-cont'>
                            <img src={Reports} alt='Reports'></img>
                            <h3 className='count percent' data-count="99">0</h3>
                            <p>On-Time Reports</p>
                        </div>
                        <div className='count-cont'>
                            <img src={Ratings} alt='Ratings'></img>
                            <h3>
                            <span className='count count-per' data-count="4">0</span>
                            <span className='count-per'>/5</span>
                            </h3>
                            <p>Customer Ratings</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainHome;
