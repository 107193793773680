import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, message } from 'antd'; // Import Ant Design components
import authService from '../../../Services/authService'; // Import your auth service
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const EditState = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the state ID from the URL

  // State for form data and list of countries
  const [formData, setFormData] = useState({
    state_name: '',
    country_id: '', // Initialize as empty, will be auto-filled
  });
  const [countries, setCountries] = useState([]); // Holds the list of countries
  const [loading, setLoading] = useState(true); // To manage loading state
  const [error, setError] = useState(''); // To manage error state
const[errors,setErrors]=useState("")
  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission for updating state data
  const handleSubmit = async (event) => {
    event.preventDefault();
    const ValidateErrors=ValidateForm(formData);
    setErrors(ValidateErrors);
    if(Object.keys(ValidateErrors).length===0){
      const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    try {
      const response = await fetch(`${authService.getApiUrl()}states/${id}`, {
        method: 'PUT', // Update the state data with PUT
        body: JSON.stringify({
          state_name: formData.state_name,
          country_id: formData.country_id, // Ensure correct country is sent
          Is_Active: "A",
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
        },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Error updating state');
      }

      message.success('State updated successfully'); // Show success message
      navigate('/admin/states'); // Redirect to the states list page after successful update
    } catch (err) {
      message.error('Error updating state: ' + err.message); // Display error message
    }
  }else{
    console.log("Form submission failed due to validation errors.");
  }
  };

  // Load both state data and countries data
  useEffect(() => {
    const fetchData = async () => {
      const token = authService.getCurrentadmin().token;
      try {
        // Fetch the specific state data
        const stateResponse = await fetch(`${authService.getApiUrl()}states/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!stateResponse.ok) {
          const error = await stateResponse.json();
          throw new Error(error.message || 'Error fetching state data');
        }

        const stateResult = await stateResponse.json();
        const stateData = stateResult.data;

        setFormData({
          state_name: stateData.state_name || '',
          country_id: stateData.country_id || '', // Keep country_id empty for now
        });

        // Fetch the list of countries
        const countriesResponse = await fetch(`${authService.getApiUrl()}countries`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!countriesResponse.ok) {
          const error = await countriesResponse.json();
          throw new Error(error.message || 'Error fetching countries');
        }

        const countriesResult = await countriesResponse.json();
        const fetchedCountries = countriesResult.data || [];

        setCountries(fetchedCountries); // Set the countries list for dropdown

        // Automatically set the first country as default if no country_id was provided
        if (!stateData.country_id && fetchedCountries.length > 0) {
          setFormData((prevData) => ({
            ...prevData,
            country_id: fetchedCountries[0].id, // Set the default country_id to the first country
          }));
        }

      } catch (err) {
        setError('Error loading data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Show a loading spinner or message while data is loading
  if (loading) {
    return <div>Loading...</div>;
  }

  // Show error message if any
  if (error) {
    return <div>{error}</div>;
  }
  const ValidateForm = () => {
    const errors = {};
    const nameRegex = /^[a-zA-Z]+[\-'\s]?[a-zA-Z ]+$/;
 
 
    if (!formData.state_name.trim()) {
      errors.state_name= 'State Name is required';
    } else if (formData.state_name.length < 4) {
      errors.state_name = 'State Name must be at least 4 characters';
    } else if (!nameRegex.test(formData.state_name)) {
      errors.state_name= 'State Name can only contain letters';
    }
    if(!formData. country_id){
      errors. country_id='Select Country';
    }
    return errors;
  };
  return (
    <div className='main-container'>
      <div className='container-fluid'>
        <Breadcrumb/>
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Edit State</h3>
            </div>
          </div>
          <div className="card">
            <div className="card-body custom-edit-service">
              <form className="needs-validation clearfix" onSubmit={handleSubmit}>
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          State Name 
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="state_name"
                          value={formData.state_name}
                          onChange={handleInputChange}
                        
                        />
                        {errors.state_name && <span className='errors' >{errors.state_name}</span>}
                      </div>
                    </div>
                    <div className='half-cont'>
                      <div className="form-group">
                        <label>
                          Country <span className='text-danger'>*</span>
                        </label>
                        <select
                          className='form-select form-control'
                          name='country_id'
                          value={formData.country_id}
                          onChange={handleInputChange}
                   
                        >
                          <option value=''>--Select--</option>
                          {countries.length > 0 ? (
                            countries.map((country) => (
                              <option key={country.id} value={country.id}>
                                {country.country_name}
                              </option>
                            ))
                          ) : (
                            <option value="">No countries available</option>
                          )}
                        </select>
                        {errors.country_id && <span className='errors' >{errors.country_id}</span
                        >}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                  name="form_submit"
                  onSubmit={ValidateForm()}
                >
                  Submit
                </button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditState;
