import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useParams } from "react-router";
import TabMenu from './TabMenu';

const EditTest = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id.toString();
  const { organization } = useContext(OrganizationContext);
  const [categories, setCategories] = useState([]); 
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    category_id: '',
    test_name: '',
    short_description: '',
    description: '',
    preparation_description: '',
    price: '',
    Is_Active: '', 
    visit_type: '', // Use a string or number for radio values
  });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const [editorState, setEditorState] = useState(RichTextEditor.createEmptyValue());

  const handleDescriptionChange = (value) => {
    setEditorState(value);
    setFormData({
      ...formData,
      description: value.toString('html'),
    });
  };

  useEffect(() => {
    if (organization) {
      loadCategories();
      loadData();
    }
  }, [organization]);

  const loadData = () => {
    const token = authService.getCurrentadmin().token;
    const url = new URL(authService.getApiUrl() + 'labtests/' + id);
  
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          authService.logout();
          window.location.href = "/admin";
        } else {
          return res.json();
        }
      })
      .then((result) => {
        const data = result.data;
        //setFormData(result.data);
        setFormData({
          ...data,
          Is_Active: data.Is_Active === "A",  // Convert "A" to true and "I" to false
          visit_type: data.visit_type.toString(),  // Convert visit_type to string
        });
  
        setEditorState(RichTextEditor.createValueFromString(data.description, 'html'));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  


  const loadCategories = () => {
    const token = authService.getCurrentadmin().token;
    const url = new URL(authService.getApiUrl() + 'activecategory');
    url.searchParams.append('organization_id', organization);
  
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCategories(result.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const ValidateErrors=ValidateForm(formData);
    setErrors(ValidateErrors);
    
    if(Object.keys(ValidateErrors).length===0){
    const token = authService.getCurrentadmin().token;
    const base64String = file ? await convertImageToBase64(file) : "";
    fetch(authService.getApiUrl() + 'labtests/'+id, {
      method: 'PUT',
      body: JSON.stringify({
        test_name: formData.test_name,
        image: base64String,
        description: formData.description,
        organization_id: organization,
        category_id: formData.category_id,
        Is_Active: formData.Is_Active ? "A" : "I",
        short_description: formData.short_description,
        preparation_description: formData.preparation_description,
        price: formData.price,
        visit_type: formData.visit_type,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        navigate(`/admin/lab-management`);
      })
      .catch((err) => {
        console.log(err.message);
      });
    }else{
      console.log("Form submission failed due to validation errors:");
    }
  };
  const[errors,setErrors]=useState({});
  const ValidateForm = () => {
    const errors = {};
    const nameRegex = /^[a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]*$/
    const priceRegex = /^[0-9]+$/;
 
    if (!formData.test_name.trim()) {
      errors.test_name = 'Test Name is required';
    
    } else if (!nameRegex.test(formData.test_name)) {
     
    }
if(!formData.category_id){
  errors.category_id='Category Id is required';
}
    if (!formData.price) {
      errors.price = 'Price is required';
    } else if (!priceRegex.test(formData.price)) {
      errors.price = 'Price must contain only numbers';
    }

    if (!formData.short_description) {
      errors.short_description = 'Short description is required';
    }

    const descriptionContent = editorState.toString('html').trim();
    if (descriptionContent === '<p><br></p>' || descriptionContent === '') {
      errors.description = 'Description is required';
    }
    if (!formData.preparation_description) {
      errors.preparation_description = 'Preparation description is required';
    }

    if (!formData.visit_type) {
      errors.visit_type = 'Visit type is required';
    }
  //   if(!formData.Is_Active){
  // errors.Is_Active='Status is must and should'
  //   }
  
    return errors;
 
  };
  return (
    <>
      <div className='main-container'>
        <div className='container-fluid'>
          <Breadcrumb/>
          <TabMenu name="labtest"/>
          <div className='category-table'>
            <div className='flex-cont add-cat'>
              <div className='heading'>
                <h3>Edit Test</h3>
              </div>
            </div>
            <div className="card">
              <div className="card-body custom-edit-service">
                <form className="needs-validation clearfix" onSubmit={handleSubmit} key="addform">
                  <div className="addtest-fields mb-3">
                    <div className="flex-cont">
                      <div className="half-cont">
                        <div className="form-group">
                          <label>
                            Test Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="test_name"
                            id="test_name"
                            // required
                            value={formData.test_name}
                            onChange={handleInputChange}
                          />
                           {errors.test_name && <span className='errors' >{errors.test_name}</span>}
                        </div>
                      </div>
                      <div className="half-cont">
                        <div className="form-group">
                          <label>
                            Category <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select form-control"
                            name="category_id"
                            // required
                            value={formData.category_id}
                            onChange={handleInputChange}
                          >
                            <option value="">--Select--</option>
                            {categories.map((item, i) => (
                              <option value={item.id} key={i}>{item.category_name}</option>
                            ))}
                          </select>
                          {errors.category_id && <span className='errors' >{errors.category_id}</span>}
                        </div>
                      </div>
                      <div className="half-cont">
                        <div className="form-group row">
                          <label>
                            Price
                            <span className="text-danger">*</span>
                          </label>
                          <div className="inline-flex">
                            <span className="input-group-text">&#8377;</span>
                            <input
                              type="text"
                              className="form-control"
                              name="price"
                              value={formData.price}
                              onChange={handleInputChange}
                            />
                            <span className="input-group-text">.00</span>
                          </div>
                          {errors.price && <span className='errors' >{errors.price}</span>}
                        </div>
                      </div>
                      <div className="half-cont">
                        <div className='upload-img-cont'>
                          <label>
                            Upload Image <span className="text-danger">*</span>
                          </label>
                          <input
                            type="file"
                            id="images"
                            accept="image/jpeg, image/png, image/gif"
                            name="image"
                            onChange={handleFileChange}
                            className="form-control"
                          />
                          <small className="text-secondary">
                            Recommended image size is <b>300 × 300 px</b>
                          </small>
                        </div>
                        {formData.image && (
                        <div id="uploadPreview">
                          <ul className="upload-wrap">
                            <li>
                              <div className="upload-images">
                                <img
                                  alt="Organization"
                                  src={authService.getApiDomainUrl() + formData.image}
                                  style={{ maxWidth: '100px', maxHeight: '100px' }}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                      </div>
                      <div className="full-cont">
                        <div className="form-group">
                          <label>
                            Short Description
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            placeholder="Enter message"
                            name="short_description"
                            value={formData.short_description}
                            onChange={handleInputChange}
                            rows="4"
                          />
                            {errors.short_description && <span className='errors' >{errors.short_description}</span>}
                        </div>
                      </div>
                    </div>

                    <div className="service-fields mb-3">
                      <div className="row">
                        <div className="full-cont">
                          <div className="form-group">
                            <label>
                              Description <span className="text-danger">*</span>
                            </label>
                            <RichTextEditor
                              value={editorState}
                              onChange={handleDescriptionChange}
                              placeholder="Enter description here..."
                              editorStyle={{ height: '150px' }}
                            />
                             {errors.description && <span className='errors' >{errors.description}</span>}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex-cont">
                      <div className="full-cont">
                        <div className="form-group">
                          <label>
                            Preparation 
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter message"
                            name="preparation_description"
                            value={formData.preparation_description}
                            onChange={handleInputChange}
                          />
                            {errors.preparation_description&& <span className='errors' >{errors.preparation_description}</span>}
                        </div>
                      </div>
                      
                      {/* Radio buttons for Home and Hospital Visit */}
                      <div className="half-cont">
                        <div className="form-group">
                          <label>Visit Type
                            <span className="text-danger">*</span>
                          </label>
                          <div className='radio-sec'>
                            <div className='radio-btn'>
                              <label>
                                <input
                                  type="radio"
                                  name="visit_type"
                                  value="1"
                                  checked={formData.visit_type === '1'}
                                  onChange={handleInputChange}
                                />
                                Home Visit
                              </label>
                            </div>
                            <div className='radio-btn'>
                              <label>
                                <input
                                  type="radio"
                                  name="visit_type"
                                  value="2"
                                  checked={formData.visit_type === '2'}
                                  onChange={handleInputChange}
                                />
                                Hospital Visit
                              </label>
                            </div>
                          </div>
                          {errors.visit_type&& <span className='errors' >{errors.visit_type}</span>}
                        </div>
                      </div>

                      <div className="full-cont special-case-cont">
                        <div className='form-group'>
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              name="Is_Active"
                              checked={formData.Is_Active}
                              onChange={handleInputChange}
                              value="A"
                            />
                            <span className="checkmark"></span>
                            <label>Status </label>
                          </label>
                        </div>
                      </div>

                    </div>
                    
                      <div className="submit-section">
                        <button
                          className="btn btn-primary submit-btn"
                          type="submit"
                          name="form_submit"
                        >
                          Submit
                        </button>
                      </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTest;
