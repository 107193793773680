import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Space } from 'antd';
import Trash from '../../Assets/images/recycle-bin.png';
import Plus from '../../Assets/images/plus.png';
import TabMenu from './TabMenu';
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const 
AddPackages = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [catorgbasedtests, setCatorgBasedTests] = useState([]);
  const { organization } = useContext(OrganizationContext);
  const [editorState, setEditorState] = useState(RichTextEditor.createEmptyValue());
  const [packageTests, setPackageTests] = useState([{ category_id: '', lab_test_id: '' }]);
  const [formData, setFormData] = useState({
    package_name: '',
    description: '',
    price: '',
    organization_id: organization ? organization.id : '',
    Is_Active: '',
  });
const[errors,setErrors]=useState("");

const ValidateForm=()=>{
  const errors={};
  // const nameRegex =/^[a-z A-Z -]+$/;
  const priceRegex = /^[0-9]+$/;
  if (!formData. package_name) {
    errors. package_name = 'Package Name is required';
  
  
  }
  if (!formData.price) {
    errors.price = 'Price is required';
  } else if (!priceRegex.test(formData.price)) {
    errors.price = 'Price must contain only numbers';
  }
  if (!formData.description) {
    errors.description = 'Description is required';
  }
  packageTests.forEach((test, index) => {
    if (!test.category_id) {
      errors[`category_id_${index}`] = 'Category is required';
    }
    if (!test.lab_test_id) {
      errors[`lab_test_id_${index}`] = 'Lab test is required';
}
})
  return errors;

}
  useEffect(() => {
    if (organization ) {
      loadCategories();
    }
  }, [organization]);

  const loadCategories = async () => {
    const token = authService.getCurrentadmin().token; 
    const url = new URL(authService.getApiUrl() + 'category');
    url.searchParams.append('organization_id', organization);
  
    fetch(url, {
      method: 'GET', 
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, 
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCategories(result.data); 
      })
      .catch((err) => {
        console.log(err.message); 
      });
  };
  
  const loadLabTests = (categoryId, index) => {
    const token = authService.getCurrentadmin().token;
  
    fetch(authService.getApiUrl() + 'catorgbasedtests', {
      method: 'POST',
      body: JSON.stringify({ organization_id: organization, category_id: categoryId }),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.json())
    .then((result) => {
      const updatedTests = [...catorgbasedtests];
      updatedTests[index] = result.data;
      setCatorgBasedTests(updatedTests);
    })
    .catch((err) => {
      console.log(err.message);
    });
  };
    
  const handlePackageTestChange = (index, field, value) => {
    const updatedPackageTests = [...packageTests];
    updatedPackageTests[index][field] = value;

    setPackageTests(updatedPackageTests);

    if (field === 'category_id') {
      loadLabTests(value, index);
    }
  };
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
 
  const handleAddTest = () => {
    setPackageTests([...packageTests, { category_id: '', lab_test_id: '' }]);
    setCatorgBasedTests([...catorgbasedtests, []]);
  };
  
  const handleRemoveTest = (index) => {
    const updatedPackageTests = [...packageTests];
    updatedPackageTests.splice(index, 1);
    setPackageTests(updatedPackageTests);

    const updatedCatorgBasedTests = [...catorgbasedtests];
    updatedCatorgBasedTests.splice(index, 1);
    setCatorgBasedTests(updatedCatorgBasedTests);
  };
 
  const handleSubmit = async (event) => {
    event.preventDefault(); 
    const ValidateErrors=ValidateForm(formData);
    setErrors(ValidateErrors);
    if(Object.keys(ValidateErrors).length===0){
    const token = authService.getCurrentadmin().token; 
    
    try {
      const response = await fetch(authService.getApiUrl() + 'labtestpackage', {
        method: 'POST',
        body: JSON.stringify({
          organization_id: organization,
          package_name: formData.package_name,
          description: formData.description,
          price: formData.price,
          // Is_Active: "A", 
          Is_Active: formData.Is_Active ? 'A' : 'I',
          package_tests: packageTests, 
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`, 
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to create package'); 
      }
  
      const result = await response.json();
      
      navigate('/admin/lab-management/packages');
      
    } catch (err) {
      console.log('Error:', err.message);
    }
  }
  else{
    console.log("Form submission failed due to validation errors.");
  }
  };
  
  return (
    <>
      <div className='main-container'>
        <div className='container-fluid'>
        <Breadcrumb/>
        <TabMenu name="packages"/>
          <div className='category-table'>
            <div className='flex-cont add-cat'>
              <div className='heading'>
                <h3>Add Package</h3>
              </div>
            </div>
            <div className="card">
              <div className="card-body custom-edit-service">
                <form className="needs-validation clearfix" onSubmit={handleSubmit} key="addform">
                  <div className="addtest-fields mb-3">
                    <div className="flex-cont">
                      <div className="full-cont">
                        <div className="form-group">
                          <label>
                            Package Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="package_name"
                            id="package_name"
                       
                            value={formData.package_name}
                            onChange={handleInputChange}
                          />
                           {errors.package_name && <span className='error-message' >{errors.package_name}</span>}
                        </div>
                      </div>
                      {packageTests.map((test, index) => (
                    <div className="row-cont" key={index}>
                      <div className="half-cont">
                        <div className="form-group">
                          <label>
                            Category <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select form-control"
                            name="category_id"
                            value={test.category_id}
                            onChange={(e) =>
                              handlePackageTestChange(index, 'category_id', e.target.value)
                            }
                         
                          >
                            <option value="">--Select--</option>
                            {categories.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.category_name}
                              </option>
                            ))}
                                         
                          </select>
                          {/* {errors.category_id && <p style={{ color: 'red' }}>{errors.category_id}</p>} */}
                          {errors[`category_id_${index}`] && (
                                <span className='error-message' >{errors[`category_id_${index}`]}</span>
                            )}
                        </div>
                  
                      </div>
                      <div className="half-cont">
                            <div className="form-group">
                              <label>
                                Labtest <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-select form-control"
                                name="test_name"
                                value={test.lab_test_id}
                                onChange={(e) => handlePackageTestChange(index, 'lab_test_id', e.target.value)}
                             
                              >
                                <option value="">--Select--</option>
                                {catorgbasedtests[index] &&
                                  catorgbasedtests[index].map((test) => (
                                    <option key={test.id} value={test.id}>
                                      {test.test_name}
                                    </option>
                                  ))}
                              </select>
                             
                              {/* {errors.lab_test_id && <p style={{ color: 'red' }}>{errors.lab_test_id}</p>} */}
                              {errors[`lab_test_id_${index}`] && (
                                <span className='error-message' >{errors[`lab_test_id_${index}`]}</span>
                            )}
                            </div>
                          </div>
                      <div className="action-icon">
                        <Button
                          type="text"
                          icon={<img src={Plus} alt="Add" style={{ width: 18, height: 18 }} />}
                          onClick={handleAddTest}
                        />
                      </div>
                      <div className="action-icon">
                        <Button
                          type="text"
                          icon={<img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />}
                          onClick={() => handleRemoveTest(index)}
                        />
                      </div>
                    </div>
                  ))}

                      <div className="full-cont">
                        <div className="form-group row">
                          <label>
                            Price
                            <span className="text-danger">*</span>
                          </label>
                          <div className="inline-flex">
                            <span className="input-group-text">&#8377;</span>
                            <input
                              type="text"
                              className="form-control"
                              name="price"
                              value={formData.price}
                              onChange={handleInputChange}
                            />
                            <span className="input-group-text">.00</span>
                          </div>
                          {errors.price &&<span className='error-message' >{errors.price}</span>}
                        </div>
                      </div>
                     
                      <div className="full-cont">
                        <div className="form-group">
                          <label>
                            Short Description
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            placeholder="Enter message"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            rows="4"
                          />
                            {errors.description && <span className='error-message' >{errors.description}</span>}
                        </div>
                      </div>
                    </div>
                    <div className="flex-cont">
                      {/* Radio buttons for Home and Hospital Visit */}
                      <div className="full-cont special-case-cont">
                        <div className='form-group'>
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              name="Is_Active"
                              checked={formData.Is_Active}
                              onChange={handleInputChange}
                              value="A"
                              
                            />
                            <span className="checkmark"></span>
                            <label>Status</label>
                          </label>
                          {/* {errors.Is_Active && <span className='errors' >{errors.Is_Active}</span>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <button
                      className="btn btn-primary submit-btn"
                      type="submit"
                      onChange={ValidateForm()}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPackages;

