import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    { name: '10 AM - 12 PM', online: 4000, cash: 2400, amt: 2400 },
    { name: '12 PM - 2 PM', online: 3000, cash: 1398, amt: 2210 },
    { name: '2 PM - 4 PM', online: 2000, cash: 9800, amt: 2290 },
    { name: '4 PM - 6 PM', online: 2780, cash: 3908, amt: 2000 },

  ];

const MyBarChart = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className='graph-cont'>
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }} // Increase top margin for labels
        barSize={isMobile ? 20 : 58}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        {/* <YAxis /> */}
        <Tooltip />
        <Legend />
        <Bar dataKey="online" fill="#5d79ff" radius={[10, 10, 0, 0]}/>
        <Bar dataKey="cash" fill="#febd59" radius={[10, 10, 0, 0]}/>
      </BarChart>
    </ResponsiveContainer>
    </div>
  );
};

export default MyBarChart;
