import React from 'react';

const UserData = ({ data }) => {
    return (
        <table cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                {data.map((row, index) => (
                        <td key={index}><strong>Test: </strong>{row.name}</td>                  
                ))}
                </tr>
                <tr>
                {data.map((row, index) => (
                        <td key={index}><strong>Image: </strong><br></br>{row.image}</td>
                ))}
                </tr>
                 <tr>
                {data.map((row, index) => (
                        <td key={index}><strong>Short Description: </strong>{row.shortDescription}</td>
                ))}
                </tr>
                <tr>
                {data.map((row, index) => (
                        <td key={index}><strong>Description: </strong>{row.description}</td>
                ))}
                 </tr>
                 <tr>
                {data.map((row, index) => (
                        <td key={index}><strong>Preparation Description: </strong>{row.preparationDescription}</td>
                ))}
                 </tr>
                 <tr>
                {data.map((row, index) => (
                        <td key={index}><strong>Price: </strong>{row.price}</td>
                ))}
                </tr>
                <tr>
                {data.map((row, index) => (
                        <td key={index}>
                        <strong>Status: </strong>
                        <span style={{ color: row.active === "Active" ? "green" : "red" }}>
                            {row.active}
                        </span>
                    </td>
                ))}
                </tr>

            </tbody>
        </table>
    );
};

export default UserData;
