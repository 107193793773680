import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import LeftArrowTailIcon from '../../Assets/Icons/LeftArrowTailIcon';
import SecureIcon from '../../Assets/images/Icon.png'
import LockIcon from '../../Assets/images/LockIcon.png';
import Labspecialist from '../../Assets/images/Labspecialist.png';
import PhoneIcon from '../../Assets/images/PhoneIcon.png';
import Ads from '../../Assets/images/Ad.png';

const UploadPrescription = () => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null); // Reference to the file input

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    console.log("File Selected:", selectedFile);
  };

  const handleSelectFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click
    }
  };

  const handleUploadClick = () => {
    if (file) {
      // Perform upload logic here, e.g., send it to the server
      console.log("Uploading file:", file);
    } else {
      console.log("No file selected.");
    }
  };

  return (
    <>
      <div className="clientWrapper">
        <div className='prescription-cont'>
          <div className='client-container'>
            <div className="upload-header">
              <Link to='/'><LeftArrowTailIcon /></Link>
              <h2>Upload Prescription</h2>
            </div>
            <div className='patch'>
              <h4>Flat <span>15% off*</span> on Medicine Order</h4>
            </div>
            <div className='upload-pres-cont'>
              <h3>Have a Prescription?</h3>
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef} // Add ref to file input
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="file-input"
              />
              {/* Button to trigger file input */}
              {file && <p className='selected-file'>Selected File: {file.name}</p>}
              <button className='upload-button' onClick={handleSelectFileClick}>UPLOAD PRESCRIPTION</button>
              {/* {file && <p>Selected File: {file.name}</p>}
              <button onClick={handleUploadClick}>Upload</button> */}
              <div className='secure-note'>
                <img src={SecureIcon} alt='secure-icon'></img>
                <h5>Your Attached Prescription will be secure and Private.</h5>
              </div>
              <div className='why-upload'>
                <h3>Why Upload a Prescription?</h3>
                <div className='why-questions'>
                  <div className='secure-note'>
                    <img src={PhoneIcon} alt='secure-icon'></img>
                    <h5>Your Attached Prescription will be secure and Private.</h5>
                  </div>
                  <div className='secure-note'>
                    <img src={Labspecialist} alt='secure-icon'></img>
                    <h5>Your Attached Prescription will be secure and Private.</h5>
                  </div>
                  <div className='secure-note'>
                    <img src={LockIcon} alt='secure-icon'></img>
                    <h5>Your Attached Prescription will be secure and Private.</h5>
                  </div>
                </div>
                <div className='what-pres'>
                  <Link to='/'>
                    What is a Valid Prescription?
                  </Link>
                </div>
              </div>
              <div className='ads-cont'>
                <Link to='/'>
                  <img src={Ads} alt='Ads'></img>
                </Link>
              </div>
              <div className='continue-cont'>
                
                <button className='upload-button' onClick={handleUploadClick}>Continue {" >"} </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadPrescription;
