import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Spin, Alert } from 'antd'; // Added Spin and Alert for loading and error handling
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const EditCountry = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Destructure id from useParams

  const [formData, setFormData] = useState({ country_name: '' });
  const [loading, setLoading] = useState(false); // Added loading state
  const [error, setError] = useState(''); // Added error state

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const[errors,setErrors]=useState({});
  const handleSubmit = async (event) => {
    event.preventDefault();
    const ValidationErrors=FormValidate(formData);
    setErrors(ValidationErrors);
    if(Object.keys(ValidationErrors).length ===0){
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
    try {
      setLoading(true);
      const response = await fetch(`${authService.getApiUrl()}countries/${id}`, {
        method: 'PUT',
        body: JSON.stringify({
          country_name: formData.country_name,
          Is_Active: "A",
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Error updating country');
      }

      const result = await response.json();
      console.log('Country updated:', result);

      // Redirect to the countries list page after successful update
      navigate('/admin/countries');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }else{
    console.log("form doen not submit due to validatoins");
  }
  };

  // Load country data when component mounts
  useEffect(() => {
    const loadCountry = async () => {
        const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
        
      try {
        setLoading(true);
        const response = await fetch(`${authService.getApiUrl()}countries/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || 'Error fetching country data');
        }

        const result = await response.json();
        console.log('Fetched country data:', result);

        // Directly set formData from the result object
        if (result && result.country_name) {
          setFormData({
            country_name: result.country_name || '',
          });
        } else {
          throw new Error('Unexpected response structure: ' + JSON.stringify(result));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadCountry();
  }, [id]);

  const FormValidate=()=>{
    const errors={};
    const countryreg=/^[a-z A-Z]+$/
    if(!formData.country_name){
      errors.country_name='Country name is required';
    }else if(!countryreg.test(formData.country_name)){
      errors.country_name='Country contains only letters';
    }
  return errors;
  }
  return (
    <div className='main-container'>
      <div className='container-fluid'>
        <Breadcrumb/>
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Edit Country</h3>
            </div>
          </div>
          <div className="card">
            <div className="card-body custom-edit-service">
              {loading && <Spin tip="Loading..." />}
              {error && <Alert message="Error" description={error} type="error" showIcon />}
              <form className="needs-validation clearfix" onSubmit={handleSubmit}>
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Country Name 
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="country_name"
                          value={formData.country_name}
                          onChange={handleInputChange} // Ensure consistent function name
                        />
                        {errors.country_name&&(<span className='error-message'>{errors.country_name}</span>)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <Button type='submit' htmlType='submit' loading={loading} 
                  className="btn btn-primary submit-btn" name="form_submit"
                  onSubmit={FormValidate()}>Submit</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCountry;

