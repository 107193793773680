import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../Assets/css/Client.css';
import Email from '../../Assets/images/Email.png';
import Password from '../../Assets/images/Password.png';
import User from '../../Assets/images/user.png';
import LeftArrowIcon from '../../Assets/Icons/LeftArrowIcon';
import Done from '../../Assets/images/Done.png';

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [agreeToTerms, setAgreeToTerms] = useState(false); // State to manage checkbox
  const [isModalVisible, setIsModalVisible] = useState(false); // State to handle modal visibility

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle password visibility state
  };

  const handleCheckboxChange = (e) => {
    setAgreeToTerms(e.target.checked); // Update state when checkbox is toggled
  };

    const showModal = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

  return (
    <div className="login-container">
      <div className="login-header">
        <button className="back-button">
          <Link to='/'><LeftArrowIcon /></Link>
        </button>
        <h2>SIGN UP</h2>
      </div>

      <form className="login-form">
        <div className="input-group">
          <input type="text" placeholder="Enter your name" />
          <span className="input-icon">
            <img src={User} alt="user-icon" />
          </span>
        </div>

        <div className="input-group">
          <input type="email" placeholder="Enter your email" />
          <span className="input-icon">
            <img src={Email} alt="email-icon" />
          </span>
        </div>

        <div className="input-group">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
          />
          <span className="input-icon">
            <img src={Password} alt="password-icon" />
          </span>
          <button
            className="show-password"
            type='button'
            onClick={togglePasswordVisibility}
          >
            {showPassword ? '🙈' : '👁️'}
          </button>
        </div>

        <div className="checkbox-group">
          <input 
            type="checkbox" 
            id="agreeTerms" 
            checked={agreeToTerms} 
            onChange={handleCheckboxChange} 
          />
          <label htmlFor="agreeTerms">
            I agree to the medidoc <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>
          </label>
        </div>
        <div className="login-btn">
          <button 
            type="submit" 
            onClick={showModal} 
            className="btn disable-btn" 
            disabled={!agreeToTerms}
          >
            SIGN UP
          </button>
        </div>
        <p className="sign-up-text">
          have an account? <Link to='/'>Login</Link>
        </p>
      </form>

      <div className='success-popup'>
        {/* Modal */}
        {isModalVisible && (
                <div className='user-popup'>
                    <div className="modal" onClick={closeModal}>
                        <div className='modal-dialog modal-lg' >
                            <div className="modal-content">
                              <div className='sucess-cont'>                              
                                <img src={Done} alt='done-img'></img>
                                <p>Your account has been successfully registered</p>
                                <div className="login-btn"><Link to='/'><button type="submit" className="btn">LOGIN</button></Link></div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div className='popup-bg'></div> 
                </div>
            )}
      </div>
    </div>
  );
};

export default Signup;
