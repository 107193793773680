import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Backbtn from '../../Assets/images/Back-btn.png';
import ProfileImg from '../../Assets/images/Mask group.png';
import Close from '../../Assets/images/close.png';
import authService from '../../../Services/authService';

const AddMember = () => { 
    const [activeTab, setActiveTab] = useState(null);
    const [categories, setCategories] = useState([]); 
    const [familyMembers, setFamilyMembers] = useState([]); 
    const [formData, setFormData] = useState({
        gender: '',
        name: '',
        age:'',
        relation:''
    });

    useEffect(() => {

        loadFamilydetails();

    }, []); 

    
    const openTab = (cityName) => {
        setActiveTab(cityName);
    };
    
    const closeTab = () => {
    setActiveTab(null);
    }; 



    const loadFamilydetails = () => {

        const url = new URL(authService.getApiUrl() + 'userfamilylist' );
      
        fetch(url, {
          method: 'POST',
          body: JSON.stringify({ 
            user_id:authService.getCurrentuser().id,
          }), 
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
          .then((res) => {
              return res.json();
          })
          .then((result) => {
            //const data = result.data;
            setFamilyMembers(result.data);

          })
          .catch((err) => {
            console.log(err.message);
          });
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        fetch(authService.getApiUrl() + 'addfamily', {
            method: 'POST', // Change method to PUT
            body: JSON.stringify({  user_id: authService.getCurrentuser().id,
                                    name:formData.name,
                                    age:formData.age,
                                    gender:formData.gender,
                                    relation:formData.relation,

            }), // Include the data to be updated in the body
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            })
            .then((res) => res.json())
            .then((result) => {
              console.log(result.data)
              loadFamilydetails();
              setActiveTab(null);
            })
            .catch((err) => {
                console.log(err.message); // Handle any errors
            });
    }

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
          ...formData,
          [name]:  value,
        });
    };

      
    return (
        <div className='homepageWrapper'>
            <div className='client-container'>
                <div className='add-member-cont'>
                    <div className='back-heading-add'>
                        <div className='back-btn-add'>
                            <Link to='#' onClick={() => window.history.back()}><img src={Backbtn} alt='back-btn' title='back-btn'></img></Link>
                        </div>
                        <h1>Family members</h1>
                    </div>
                    <div className='all-family-mem'>
                        {familyMembers.map((data, index) => (
                            <div className='member'>
                                <div className='mem-image'>
                                    {/* <img src={ProfileImg} alt='user-icon' title='user-icon'></img> */}
                                    {data.name.charAt(0).toUpperCase() }
                                </div>
                                <div className='user-details'>
                                    <h3 className='user-name'>{data.name}</h3>
                                    <div className='rel-age'>
                                        <p className='relation'>{data.relation}</p>
                                        <p className='age'>{data.gender} {data.age} Years</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div className='member'>
                            <div className='mem-image'>
                                <img src={ProfileImg} alt='user-icon' title='user-icon'></img>
                            </div>
                            <div className='user-details'>
                                <h3 className='user-name'>Suresh</h3>
                                <div className='rel-age'>
                                    <p className='relation'>Self</p>
                                    <p className='age'>Male 25 Years</p>
                                </div>
                            </div>
                        </div>
                        <div className='member'>
                            <div className='mem-image'>
                                <img src={ProfileImg} alt='user-icon' title='user-icon'></img>
                            </div>
                            <div className='user-details'>
                                <h3 className='user-name'>Suresh</h3>
                                <div className='rel-age'>
                                    <p className='relation'>Self</p>
                                    <p className='age'>Male 25 Years</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='add-mem-btn-cont'>
                <div className="client-container">
                    <button  onClick={() => openTab('sort_by')} className='add-mem-btn'>Add New Member</button>
                </div>
            </div><br></br>
            <div className='test-filter'>
                <div className={`tabcontent1 ${activeTab === 'sort_by' ? 'active' : ''}`} id="sort_by">
                    <div className="black-bg" onClick={closeTab}></div>
                    <div className="add-mem-modal">
                        <strong>Add Member</strong>
                        <form className="needs-validation clearfix" onSubmit={handleSubmit} key="addform">
                        <div className="addtest-fields mb-3">
                           <div className='flex-cont'>
                            <div className="half-cont">
                                <div className="form-group">
                                <label>
                                    Name
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    id="name"
                                    required
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                                </div>
                            </div>
                            <div className="half-cont">
                                <div className="form-group">
                                <label>
                                    Age
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="age"
                                    id="age"
                                    required
                                    value={formData.age}
                                    onChange={handleInputChange}
                                />
                                </div>
                            </div>
                            <div className="half-cont">
                                <div className="form-group">
                                <label>
                                    Relation
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="relation"
                                    id="relation"
                                    required
                                    value={formData.relation}
                                    onChange={handleInputChange}
                                />
                                </div>
                            </div>
                            <div className="half-cont">
                                <div className="form-group">
                                <label>
                                    Gender <span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select form-control"
                                    name="gender"
                                    id="gender"
                                    required
                                    value={formData.gender}
                                    onChange={handleInputChange}
                                >
                                    <option value="">--Select--</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                                </div>
                            </div>                      
                                        
                            <div className="submit-section">
                                <button
                                className="btn btn-primary submit-btn"
                                type="submit"
                                name="form_submit"
                                >
                                Add
                                </button>
                            </div>
                            </div>
                        </div>
                        </form>
                        <div className="close1" onClick={closeTab}>
                            <img src={Close} alt="close" title="close" />
                        </div>
                    </div>
                </div>               
            </div>
        </div>
    );
};

export default AddMember;
