import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Backbtn from "../../Assets/images/Back-btn.png";
import Recommended_center from "../../Assets/images/recommended-center.png";
import Close from "../../Assets/images/close.png";
import Share from "../../Assets/images/share.png";
import Stars from '../../Assets/images/stars.png';
import authService from '../../../Services/authService';


const Bookmark = () => {
  const lastpath = 'teststab';
  const defaultTab = lastpath === 'hospital-detail' ? 'hospitalstab' : lastpath || 'teststab';
  const [activeTab, setActiveTab] = useState(defaultTab);
  // Initialize an array of liked states for each package item
  const [likedItems, setLikedItems] = useState(Array(8).fill(false));
  const [activeTabs, setActiveTabs] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const [counts, setCounts] = useState({});
  const [modalCount, setModalCount] = useState(1);

  useEffect(() => {
    // Ensure the correct tab is activated on initial render
    setActiveTab(defaultTab);
    loadHospitalData();
  }, [defaultTab]);

  const loadHospitalData = () => {

    const url = new URL(authService.getApiUrl() + 'organizationbylabtestsdata' );
    // fetch(url, {
    //   method: 'POST',
    //   body: JSON.stringify({ 
    //     organization_id:organization_id,
    //   }), // Include the data to be updated in the body
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json; charset=UTF-8',
    //   },
    // })
    //   .then((res) => {
    //       return res.json();
    //   })
    //   .then((result) => {
    //     //const data = result.data;
        // setHospitalData([result.data.organization]);
        // setAtLab(result.data.atlab);
        // setAtHome(result.data.athome);
        // setCategory(result.data.category);
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //   });
  };

  const testItems = [
    { id: 1, title: "Hashimoto's thyroiditis1", actualCost: 590, discountedCost: 359, description: "Description 1" },
    { id: 2, title: "Hashimoto's thyroiditis2", actualCost: 590, discountedCost: 359, description: "Description 2" },
    { id: 3, title: "Hashimoto's thyroiditis3", actualCost: 590, discountedCost: 359, description: "Description 3" },
  ];

  const handleAddClick = (id) => {
    setCounts((prev) => ({ ...prev, [id]: (prev[id] || 0) + 1 }));
  };

  const handleIncrement = (id) => {
    setCounts((prev) => ({ ...prev, [id]: (prev[id] || 1) + 1 }));
  };

  const handleDecrement = (id) => {
    setCounts((prev) => ({ ...prev, [id]: Math.max((prev[id] || 1) - 1, 0) }));
  };

  const openTab = (item) => {
    setSelectedItem(item);
    setActiveTabs("active");
    setModalCount(1);
  };

  const closeTab = () => {
    setActiveTabs(null);
    setSelectedItem(null);
    setModalCount(1);
  };

  const handleToggle = (id) => {
    setExpandedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleModalIncrement = () => {
    setModalCount((prev) => prev + 1);
  };

  const handleModalDecrement = () => {
    setModalCount((prev) => Math.max(prev - 1, 1));
  };

  const handleAddTest = (id) => {
    setCounts((prev) => ({ ...prev, [id]: (prev[id] || 0) + modalCount }));
    closeTab();
  };

  // Toggle the liked status of a specific item
  const handleHeartClick = (index) => {
      setLikedItems((prevState) => 
          prevState.map((liked, i) => (i === index ? !liked : liked))
      );
  };

  const [bookmarkedItems, setBookmarkedItems] = useState([]);

  const handleBookmarkClick = (index) => {
      setBookmarkedItems((prev) =>
      prev.includes(index)
          ? prev.filter((item) => item !== index) // Remove bookmark
          : [...prev, index] // Add bookmark
      );
  };

  return (
    <div className="homepageWrapper BookmarkWrapper">
      <div className="client-container">
        <div className="bookmark-cont">
          <div className="back-heading-add">
            <div className="back-btn-add">
              <Link to='#' onClick={() => window.history.back()}>
                <img src={Backbtn} alt="back-btn" title="back-btn" />
              </Link>
            </div>
            <h1>My Wishlist</h1>
          </div>
          <div className='tests-tabs'>
                <h3 className={`tablinks ${activeTab === 'teststab' ? 'active' : ''}`} onClick={() => setActiveTab('teststab')}>
                    Tests
                </h3>
                <h3 className={`tablinks ${activeTab === 'hospitalstab' ? 'active' : ''}`} onClick={() => setActiveTab('hospitalstab')}>
                    Hospitals
                </h3>
            </div>
            
            <div className='tabcontent testscont'>
                <div className='client-container'>
                    {activeTab === 'teststab' && (
                        <div className='tests-cont' id='thyroid'>
                            <h3 className='cathead'>Thyroid</h3>
                            {testItems.map((item, index) => (
                                
                                <div key={item.id} className='test-single'>
                                    <div className='right-img-add'>
                                        <div className='feu-img'>
                                            <img onClick={() => openTab(item)} src={Recommended_center} title='alt-ads' alt='alt-ads' />
                                            <div className='add-btn1'>
                                                {counts[item.id] ? (
                                                    <div className='counter1'>
                                                        <button onClick={() => handleDecrement(item.id)}>-</button>
                                                        <input type='text' value={counts[item.id]} readOnly />
                                                        <button onClick={() => handleIncrement(item.id)}>+</button>
                                                    </div>
                                                ) : (
                                                    <span onClick={() => handleAddClick(item.id)}>ADD&nbsp;&nbsp;+</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='left-information'>
                                        <h3 onClick={() => openTab(item)}>{item.title}</h3>
                                        <div className='price'>
                                            <span className='actualcost'>₹{item.actualCost}</span>
                                            <span className='cost'>Get for ₹{item.discountedCost}</span>
                                        </div>
                                        <p className={`fixed-text ${expandedItems[item.id] ? 'expanded' : ''}`}>
                                            {item.description}
                                        </p>
                                        {!expandedItems[item.id] ? (
                                            <span className="read-more" onClick={() => handleToggle(item.id)}>
                                                Read more
                                            </span>
                                        ) : (
                                            <span className="read-more" onClick={() => handleToggle(item.id)}>
                                                Show less
                                            </span>
                                        )}
                                        <span
                                        id={`bookmark-${index}`}
                                        onClick={() => handleBookmarkClick(index)}
                                        style={{
                                            cursor: 'pointer',
                                            marginLeft: '10px',
                                            color: bookmarkedItems.includes(index) ? '#7d26cd' : 'red',
                                            border: '1px solid #FFF',
                                        }}
                                        >
                                        <i className='fa fa-bookmark-o' aria-hidden='true'></i>
                                        </span>
                                    </div>
                                </div>
                            ))}
                        
                        </div>
                    )}
                    {activeTab === 'hospitalstab' && (
                        <div className='packages-cont'>
                            {likedItems.map((liked, index) => (
                                <div className='package-det' key={index}>
                                    <figure>
                                        
                                            <div className='img-wrap'>
                                                <Link to='/lab-tests/hospital-detail'>
                                                    <img src={Recommended_center} title='alt-ads' alt='alt-ads'></img>
                                                    <div className='star-rating'>
                                                        <img src={Stars} alt='star' title='star'></img>
                                                        <span className='ratting-count'>4.6</span>
                                                        <span>(456)</span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <figcaption>                                
                                                <div className='name-like'>
                                                    <h3><Link to='/lab-tests/hospital-detail'>Lucid Medical Diagnostics</Link></h3>
                                                    <span
                                                        id={`heart-${index}`}
                                                        onClick={() => handleHeartClick(index)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {liked ? (
                                                            <i className="fa fa-heart" aria-hidden="true"></i>
                                                        ) : (
                                                            <i className="fa fa-heart-o" aria-hidden="true"></i>
                                                        )}
                                                    </span>
                                                    <span
                                                    id={`bookmark-${index}`}
                                                    onClick={() => handleBookmarkClick(index)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        marginLeft: '10px',
                                                        color: bookmarkedItems.includes(index) ? '#7d26cd' : 'red',
                                                        border: '1px solid #FFF',
                                                    }}
                                                    >
                                                    <i className='fa fa-bookmark' aria-hidden='true'></i>
                                                    </span>
                                                </div>
                                                <span className='category'>Pragatinagar</span>
                                                <ul className='delevery-msg'>
                                                    <li>4.6Km Distance</li>
                                                    <li>Delivery in 24 h</li>
                                                </ul>
                                            </figcaption>
                                        
                                    </figure>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
      </div>

      

      {selectedItem && (
        <div className={`tabcontent1 ${activeTabs === "active" ? "active" : ""}`}>
          <div className="black-bg" onClick={closeTab}></div>
          <div className="white-modal">
            <div className="close1" onClick={closeTab}>
              <img src={Close} alt="close" />
            </div>
            <div className="inner-content">
              <div className="img-cont">
                <img src={Recommended_center} alt="test-img" />
              </div>
              <div className="test-details-share">
                <h3 className="title">{selectedItem.title}</h3>
                <Link to="">
                  <img src={Share} alt="Share" />
                </Link>
              </div>
              <div className="test-desc">
                <p>{selectedItem.description}</p>
              </div>
            </div>
            <div className="test-add-cont">
              <div className="counter1">
                <button onClick={handleModalDecrement}>-</button>
                <input type="text" value={modalCount} readOnly />
                <button onClick={handleModalIncrement}>+</button>
              </div>
              <button className="add-test-btn" onClick={() => handleAddTest(selectedItem.id)}>
                Add test ₹{modalCount * selectedItem.discountedCost}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bookmark;
