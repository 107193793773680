import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import RichTextEditor from 'react-rte';
import { useParams } from "react-router";
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const AddOrganization = () => {
  
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    state_id: '',
    city_id: '',
    area_id: '',
    description: '',
    alias:'',
    landmark:'',
    fax:'',
    latitude:'',
    longitude:'',
    manager_phone:'',
    owner_phone:'',
    Is_Active: '', 
  });
  const { organization } = useContext(OrganizationContext);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [file, setFile] = useState(null);
  const [editorState, setEditorState] = useState(RichTextEditor.createEmptyValue());
  // errors
  const[errors,setErrors]=useState({});
  useEffect(() => {
     loadStates();

  }, []);



  const loadStates = () => {
      const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
    
      // Construct the URL with the organization_id as a query parameter
      const url = new URL(authService.getApiUrl() + 'states');
      //url.searchParams.append('organization_id', organization); // Append the organization_id as a query parameter
    
      fetch(url, {
        method: 'GET', // Set method to GET
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`, // Include Authorization header with Bearer token if required
        },
      })
        .then((res) => {
          if (res.status === 401) {
            // If status is 401, redirect to the login page
            authService.logout();
            window.location.href="/admin"; 
          } else {
            return res.json(); // Proceed with parsing JSON if the response is okay
          }
        })
        .then((result) => {
          setStates(result.data); // Set the category data in state
        })
        .catch((err) => {
          console.log(err.message); // Handle any errors
        });
    
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile(e.target.files[0]);
  };

  const handleDescriptionChange = (value) => {
    setEditorState(value);
    setFormData({
      ...formData,
      description: value.toString('html'),
    });
  };

 
  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      // Resolve the base64 string when the file is successfully read
      reader.onload = () => resolve(reader.result);
      
      // Reject in case of any errors
      reader.onerror = error => reject(error);
    });
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const ValidationErrors=ValidateForm(formData);
    setErrors(ValidationErrors);

    if(Object.keys(ValidationErrors).length ===0){
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
    const base64String = file?await convertImageToBase64(file):"";
    fetch(authService.getApiUrl() + 'organizations', {
    method: 'POST', // Change method to PUT
    body: JSON.stringify({ name: formData.name,
      image:base64String,
      description:formData.description,
      state_id:formData.state_id,
      city_id:formData.city_id,
      area_id:formData.area_id,
      Is_Active:formData.Is_Active?"A":"I",
      alias:formData.alias,
      landmark:formData.landmark,
      fax:formData.fax,
      latitude:formData.latitude,
      longitude:formData.longitude,
      manager_phone:formData.manager_phone,
      owner_phone:formData.owner_phone,
    }), // Include the data to be updated in the body
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
    },
    })
    .then((res) => res.json())
    .then((result) => {
        //setFormData(result.data); // Set the form data with the result
        navigate(`/admin/organizations`);
        //console.log(result.data)
    })
    .catch((err) => {
        console.log(err.message); // Handle any errors
    });
  }else{
    console.log("form failed to submit due to errors");
  }

  };

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setFormData({ ...formData, state_id: stateId, city_id: '', area_id: '' });
    loadCities(stateId);
  };

  const loadCities = (stateId) => {
    const token = authService.getCurrentadmin().token;

    fetch(authService.getApiUrl() + 'statebasedCity', {
      method: 'POST',
      body: JSON.stringify({ state_id: stateId }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCities(result.data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    setFormData({ ...formData, city_id: cityId, area_id: '' });
    loadAreas(cityId);
  };

  const handleAreaChange = (event) => {
    const areaId = event.target.value;
    setFormData({ ...formData, area_id: areaId });
  };

  const loadAreas = (cityId) => {
    const token = authService.getCurrentadmin().token;

    fetch(authService.getApiUrl() + 'citybasedArea', {
      method: 'POST',
      body: JSON.stringify({ city_id: cityId }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setAreas(result.data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
  const ValidateForm = () => {
    const errors = {};
    const nameRegex = /^[a-z A-Z]+$/;
    const phoneRegex = /^[6,7,8,9][0-9]{0,9}$/;
  
    if (!formData.name) {
      errors.name = 'Organization name is required';
    } else if (formData.name.length < 4) {
      errors.name = 'Organization name must be at least 4 characters';
    } else if (!nameRegex.test(formData.name)) {
      errors.name = 'Organization name can only contain letters';
    }
    if (!formData.alias) {
      errors.alias = 'Alias is required';
    }else if (formData.alias.length < 4) {
      errors.name = 'Organization alias name must be at least 4 characters';
    } else if (!nameRegex.test(formData.alias)) {
      errors.alias = 'Organization alias name can only contain letters';
    }
    if (!formData.manager_phone) {
      errors.manager_phone = 'Manager phone number is required';
    }
    else if(formData.manager_phone.length!==10){
      errors.manager_phone='Phone contains exactly 10 numbers';
    }
    else if (!phoneRegex.test(formData.manager_phone)) {
      errors.manager_phone ='Phone number must starts with 6,7,8,9';
    }

    if (!formData.owner_phone) {
      errors.owner_phone = 'Owner phone number is required';
    } 
     else if(formData.owner_phone.length!==10){
      errors.owner_phone='Phone contains exactly 10 numbers';
    }
    else if (!phoneRegex.test(formData.owner_phone)) {
      errors.owner_phone = 'Phone number must starts with 6,7,8,9';
    }
   

    if(!formData.state_id){
     errors.state_id='State should be selected'; 
    }
    if(!formData.city_id){
      errors.city_id='City should be selected';
    }
  if(!formData.area_id){
    errors.area_id='Area should be selected';
  }
  if(!formData.landmark){
   errors.landmark='Landmark is required';
  }
  if(!formData.latitude){
    errors.latitude='Latitude is required';
  }
  if(!formData.longitude){
    errors.longitude='Longitude is required';
  }
  if(!formData.description){
    errors.description='Description is required';
  }
  if(!formData.Is_Active){
    errors.Is_Active='Please check the box is required';
  }

    return errors;
  };
  
  return (
    <div className='main-container'>
      <div className='container-fluid'>
        <Breadcrumb/>
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Add Organization</h3>
            </div>
          </div>
          <div className="card">
            <div className="card-body custom-edit-service">
              <form className="needs-validation clearfix" onSubmit={handleSubmit} >
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Organization Name 
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          // required
                        />
                        {errors.name&&(<span className="error-message">{errors.name}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Organization Alias 
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="alias"
                          value={formData.alias}
                          onChange={handleInputChange}
                          // required
                        />
                        {errors.alias&&(<span className="error-message">{errors.alias}</span>)}
                      </div>
                    </div>
                    <div className="full-cont">
                      <div className='upload-img-cont'>
                        <span>Upload Image *</span>
                        <input
                          type="file"
                          accept="image/jpeg, image/png, image/gif"
                          name="image"
                          onChange={handleImageChange}
                          className="form-control"
                          
                        />
                        <small className="text-secondary">
                          Recommended image size is <b>300 × 300 px</b>
                        </small>
                      </div>
                      {formData.image && (
                        <div id="uploadPreview">
                          <ul className="upload-wrap">
                            <li>
                              <div className="upload-images">
                                <img
                                  alt="Organization"
                                  src={authService.getApiDomainUrl() + formData.image}
                                  style={{ maxWidth: '100px', maxHeight: '100px' }}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>

              
                  <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Manager Phone Number 
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="manager_phone"
                          value={formData.manager_phone}
                          onChange={handleInputChange}
                          // required
                        />
                        {errors.manager_phone&&(<span className="error-message">{errors.manager_phone}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Owner Phone Number 
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="owner_phone"
                          value={formData.owner_phone}
                          onChange={handleInputChange}
                          // required
                        />
                        {errors.owner_phone&&(<span className="error-message">{errors.owner_phone}</span>)}
                      </div>
                    </div>
                  
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          State <span className="text-danger">*</span>
                        </label>
                          <select
                              className="form-select form-control"
                              name="state_id"
                              // required
                              value={formData.state_id}
                              onChange={handleStateChange}
                            >
                              <option value="" key="0">--Select--</option>
                              {states.map((item, i) => (
                                <option value={item.id} key={i}>{item.state_name}</option>
                              ))}
                          </select>
                            {errors.state_id&&(<span className="error-message">{errors.state_id}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          City <span className="text-danger">*</span>
                        </label>
                        <select
                              className="form-select form-control"
                              name="city_id"
                              // required
                              value={formData.city_id}
                              onChange={handleCityChange}
                              disabled={!formData.state_id}
                            >
                              <option value="" key="0">--Select--</option>
                              {cities.map((item, i) => (
                                <option value={item.id} key={i}>{item.city_name}</option>
                              ))}
                            </select>
                            {errors.city_id&&(<span className="error-message">{errors.city_id}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Area <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select form-control"
                          name="area_id"
                          value={formData.area_id}
                          onChange={handleAreaChange}
                          // required
                        >
                         <option value="" key="0">--Select--</option>
                              {areas.map((item, i) => (
                                <option value={item.id} key={i}>{item.area_name}</option>
                              ))}
                        </select>
                        {errors.area_id&&(<span className="error-message">{errors.area_id}</span>)}
                      </div>
                    </div>

                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Land Mark <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="landmark"
                          value={formData.landmark}
                          onChange={handleInputChange}
                          // required
                        />
                        {errors.landmark&&(<span className="error-message">{errors.landmark}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Latitude <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="latitude"
                          value={formData.latitude}
                          onChange={handleInputChange}
                          // required
                        />
                        {errors.latitude&&(<span className="error-message">{errors.latitude}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Longitude <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="longitude"
                          value={formData.longitude}
                          onChange={handleInputChange}
                          // required
                        />
                        {errors.longitude&&(<span className="error-message">{errors.longitude}</span>)}
                      </div>
                    </div>
                  </div>
                  <div className="service-fields mb-3">
                    <div className="row">
                      <div className="full-cont">
                        <div className="form-group">
                          <label>
                            Description{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <RichTextEditor
                            value={editorState}
                            onChange={handleDescriptionChange}
                            placeholder="Enter description here..."
                            editorStyle={{ height: '150px' }}
                          />
                         {errors.description&&(<span className="error-message">{errors.description}</span>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="full-cont special-case-cont">
                    <div className='form-group'>
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          name="Is_Active"
                          checked={formData.Is_Active}
                          onChange={handleInputChange}

                        />
                        <span className="checkmark"></span>
                        <label>Status </label>
                      </label>
                      {errors.Is_Active&&(<span className="error-message">{errors.Is_Active}</span>)}
                    </div>
                  </div>
                <div className="submit-section">
                
                <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    name="form_submit"
                    onSubmit={ValidateForm()}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrganization;
