import React, { useState, useEffect, useRef ,useContext} from 'react';
import Filter from '../../Assets/images/share.png'
import { Table } from 'antd';
import Authorization from '../../Assets/images/authorization.png';

const Unautherized = () => {
  const dateRef = useRef(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isActive, setActive] = useState(false);
 
 
  const handleNotifyClick = () => {
    setDropdownVisible(!isDropdownVisible);
    setActive(!isActive);
  };
 
 
  const handleClickOutside = (event) => {
    if (dateRef.current && !dateRef.current.contains(event.target)) {
      setDropdownVisible(false);
      setActive(false);
    }
  };
  return (
    <div>
 
    <div className='main-container'>
      <div className='container-fluid'>
        <div className='unauthorized-cont'>
          <div className='authentication-cont'>
            <div className='authentication-img'>
              <img src={Authorization} alt='authentication-img'></img>
            </div>
            <div className='authetication-msg'>
              <h1>Sorry!</h1>
              <p>You are not authrized to access this page.Please check your login credentials or contact the administrator for access</p>
              {/* <span>Click to </span><button className='authentication-btn'>Login out</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
 
    </div>
  )
 
};
 
export default Unautherized;