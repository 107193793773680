import React from 'react';

const Rolesview = ({ data }) => {
    return (
        <div style={{ width: '100%', margin: '20px 0' }}>
            {data.map((role, roleIndex) => (
                <table
                    key={roleIndex}
                    border="1"
                    cellSpacing="0"
                    cellPadding="10"
                    style={{ width: '100%', textAlign: 'left', marginBottom: '20px' }}
                >
                    <thead>
                        <tr>
                            <td colSpan="2"><strong>Role Name : </strong> {role.rolename}</td>
                        </tr>
                        <tr>
                            <td><strong>Module Name</strong></td>
                            <td><strong>Submodule Name</strong></td>
                        </tr>
                    </thead>
                    <tbody>
                    {role.modules.map((module, moduleIndex) => (
                        <React.Fragment key={moduleIndex}>
                            <tr>
                                <td>{module.module_name}</td>
                                <td>{module.submodules && module.submodules.length > 0 ? module.submodules[0] : 'No submodules available'}</td>
                            </tr>
                            {module.submodules && module.submodules.slice(1).map((submodule, subIndex) => (
                                <tr key={subIndex}>
                                    <td>{module.module_name}</td> 
                                    <td>{submodule}</td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}

                        <tr>
                            <td colSpan="2">
                                <strong>Status : </strong>
                                <span style={{ color: role.active === "Active" ? "green" : "red" ,fontWeight:'bold'}}>
                                    {role.active}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            ))}
        </div>
    );
};

export default Rolesview;
