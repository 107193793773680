import React, { useState, useEffect, useContext } from 'react';
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import authService from '../../../Services/authService';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import { useNavigate } from 'react-router-dom';

const RolesPermissions = () => {
  const { organization } = useContext(OrganizationContext);
  const [modules, setModules] = useState([]);
  const [formData, setFormData] = useState({ role_name: '' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    loadModules();
  }, []);

  const loadModules = async () => {
    setLoading(true);
    const token = authService.getCurrentadmin().token;

    try {
      const url = new URL(authService.getApiUrl() + 'module');
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      });

      const result = await response.json();
      console.log(result);

      if (result.modules) {
        const updatedModules = result.modules.map(module => ({
          ...module,
          permissions: { view: false, add: false, edit: false, delete: false },
          sub_menu: module.sub_menu.map(sub => ({
            ...sub,
            permissions: { view: false, add: false, edit: false, delete: false }
          }))
        }));
        setModules(updatedModules);
      } else {
        setModules([]);
      }
    } catch (err) {
      console.error(err.message);
      setModules([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  

  const handleCheckboxChange = (moduleId, permission) => {
    setModules(prevModules =>
      prevModules.map(module => {
        if (module.id === moduleId) {
          return {
            ...module,
            permissions: {
              ...module.permissions,
              [permission]: !module.permissions[permission],
            },
          };
        }
        return module;
      })
    );
  };

  const handleSubCheckboxChange = (moduleId, subId, permission) => {
    setModules(prevModules =>
      prevModules.map(module => {
        if (module.id === moduleId) {
          return {
            ...module,
            sub_menu: module.sub_menu.map(sub => {
              if (sub.id === subId) {
                return {
                  ...sub,
                  permissions: {
                    ...sub.permissions,
                    [permission]: !sub.permissions[permission],
                  },
                };
              }
              return sub;
            }),
          };
        }
        return module;
      })
    );
  };

  const collectPermissions = () => {
    const permissionsToSave = [];
    
    modules.forEach(module => {
        // Ensure module and its permissions are valid
        const modulePermissions = {
            role_id: null, // Will be filled after role is created
            module_id: module.id,
            sub_menu: module.sub_menu.map(sub => ({
                sub_module_id: sub.id,
                add: sub.permissions.add ? 1 : 0,
                view: sub.permissions.view ? 1 : 0,
                edit: sub.permissions.edit ? 1 : 0,
                delete: sub.permissions.delete ? 1 : 0,
            })),
            add: module.permissions.add ? 1 : 0,
            view: module.permissions.view ? 1 : 0,
            edit: module.permissions.edit ? 1 : 0,
            delete: module.permissions.delete ? 1 : 0,
        };

        // Only push if module_id and necessary permissions are present
        if (modulePermissions.module_id && (modulePermissions.add || modulePermissions.view || modulePermissions.edit || modulePermissions.delete || modulePermissions.sub_menu.some(sub => sub.add || sub.view || sub.edit || sub.delete))) {
            permissionsToSave.push(modulePermissions);
        }
    });
    
    return permissionsToSave;
};

  
  const handleSaveChanges = async () => {
    const token = authService.getCurrentadmin().token;

    // Check if role name is set
    if (!formData.name) {
        setMessage("Role name is required.");
        return;
    }

    // Collect permissions from the modules and submodules
    const permissionsToSave = collectPermissions();

    // Check if at least one permission is selected
    const hasPermissions = permissionsToSave.some(permission =>
        permission.add || permission.view || permission.edit || permission.delete || 
        permission.sub_menu.some(sub => sub.add || sub.view || sub.edit || sub.delete)
    );

    if (!hasPermissions) {
        setMessage("At least one permission must be selected.");
        return;
    }

    try {
        const roleResponse = await fetch(authService.getApiUrl() + 'roles', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                name: formData.name, 
                organization_id: organization, 
                Is_Active: formData.is_active ? "A" : "I",
            }),
        });

        const roleResult = await roleResponse.json();

        if (roleResponse.ok && roleResult.id) {
            const permissionsWithRoleId = permissionsToSave.map(permission => ({
                ...permission,
                role_id: roleResult.id, 
            }));
            permissionsWithRoleId.forEach(permission => {
                if (permission.sub_menu.length === 0) {
                    permission.sub_menu.push({ sub_module_id: 0, add: 0, view: 0, edit: 0, delete: 0 });
                }
            });

            await Promise.all(permissionsWithRoleId.map(async permission => {
                const mainPermissionResponse = await fetch(authService.getApiUrl() + 'useraccess', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json; charset=UTF-8',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        role_id: permission.role_id,
                        module_id: permission.module_id,
                        sub_module_id: 0, 
                        add: permission.add ? 1 : 0,
                        edit: permission.edit ? 1 : 0,
                        delete: permission.delete ? 1 : 0,
                        view: permission.view ? 1 : 0,
                    }),
                });

                if (!mainPermissionResponse.ok) {
                    const errorDetails = await mainPermissionResponse.json();
                    console.error('Failed to save main permission:', errorDetails);
                }

                // Handle submenus
                await Promise.all(permission.sub_menu.map(async subMenu => {
                    const subMenuResponse = await fetch(authService.getApiUrl() + 'useraccess', {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json; charset=UTF-8',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            role_id: permission.role_id,
                            module_id: permission.module_id,
                            sub_module_id: subMenu.sub_module_id || 0, 
                            add: subMenu.add ? 1 : 0,
                            edit: subMenu.edit ? 1 : 0,
                            delete: subMenu.delete ? 1 : 0,
                            view: subMenu.view ? 1 : 0,
                        }),
                    });

                    // Check if submenu was saved successfully
                    if (!subMenuResponse.ok) {
                        const errorDetails = await subMenuResponse.json();
                        console.error('Failed to save submenu permission:', errorDetails);
                    }
                }));
            }));

            setMessage('Saved successfully!');
            window.history.back()
        } else {
            // Handle errors if role creation failed
            const errorDetails = roleResult.message || 'Unknown error';
            setMessage(`Error creating role: ${errorDetails}`);
        }
    } catch (err) {
        console.error('Error saving changes:', err);
        setMessage('Error saving changes.');
    }
};


  
  return (
    <div className="main-container">
      <div className="container-fluid">
        <Breadcrumb />
        <div className="category-table user-add-cont">
          <div className="flex-cont add-cat">
            <div className="heading">
              <h3>Add Roles & Permissions</h3>
            </div>
          </div>

          <div className="card">
            <div className="card-body custom-edit-service">
              {/* Role Form */}
              <form className="needs-validation clearfix">
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Role Name
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          id="name"
                          required
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="full-cont special-case-cont">
                    <div className="form-group">
                    <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              name="is_active"
                              checked={formData.is_active}
                              onChange={handleInputChange}
                              value="A"
                            />
                            <span className="checkmark"></span>
                            <label>Status</label>
                          </label>
                    </div>
                  </div>
              </form>
              {message && <div className="alert alert-info">{message}</div>}
            </div>
          </div>

          {/* Permissions Section */}
          <div className="flex-cont user-add-cont">
            <div className="heading">
              <h4>Permissions <span className="text-danger">*</span></h4>
            </div>
          </div>

          <div className="hospitals-table rights-table">
                        <div className="all-rights">
                            {loading ? (
                                <div>Loading modules...</div>
                            ) : modules.length > 0 ? (
                                modules.map(module => (
                                    <>
                                    <div className="single-right" key={module.module_id}>
                                        <div className="column-div right-name">
                                            <div className='main_module'>{module.name}</div>
                                        </div>
                                            {module.sub_menu.length === 0 && (

                                              <>
                                                   {module.name === 'Dashboard' ? (
                                                    <div className="column-div right-check-box" key="view" style={{ marginRight: '20px' }}>
                                                        <label className="custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                checked={module.permissions.view} // Should be a boolean
                                                                onChange={() => handleCheckboxChange(module.id, 'view')}
                                                            />
                                                            <span className="checkmark"></span>
                                                            <span className="check-text" style={{ marginLeft: '8px' }}>
                                                                View
                                                            </span>
                                                        </label>
                                                    </div>
                                                ) : (
                                                    // For other modules, show all permissions (view, add, edit, delete)
                                                    ['view', 'add', 'edit', 'delete'].map(permission => (
                                                        <div className="column-div right-check-box" key={permission} style={{ marginRight: '20px' }}>
                                                            <label className="custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={module.permissions[permission]} // Should be a boolean
                                                                    onChange={() => handleCheckboxChange(module.id, permission)}
                                                                />
                                                                <span className="checkmark"></span>
                                                                <span className="check-text" style={{ marginLeft: '8px' }}>
                                                                    {permission.charAt(0).toUpperCase() + permission.slice(1)}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    ))
                                                )}

                                              </>
                                            )}
                                    </div>
                                        {module.sub_menu.length > 0 && (
                                            <>
                                                {module.sub_menu.map(sub => (
                                                    <div className="single-right" >
                                                        <div className="column-div right-name">
                                                          <div>{sub.name}</div>
                                                        </div>
                                                        <>
                                                        {['view', 'add', 'edit', 'delete'].map(permission => (
                                                        <div className="column-div right-check-box" key={permission} style={{ marginRight: '20px' }}>
                                                          <label className="custom-checkbox">
                                                            <input
                                                              type="checkbox"
                                                              checked={sub.permissions[permission]} // Should be a boolean
                                                              onChange={() => handleSubCheckboxChange(module.id, sub.id, permission)}
                                                            />
                                                            <span className="checkmark"></span>
                                                            <span className="check-text" style={{ marginLeft: '8px' }}>
                                                              {permission.charAt(0).toUpperCase() + permission.slice(1)}
                                                            </span>
                                                          </label>
                                                        </div>
                                                      ))}
                                                        </>
                                                        
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    
                                    </>
                                ))
                            ) : (
                                <div>No modules available for this organization ID.</div>
                            )}
                        </div>
                    </div>
                                    
          <div className="save-cont">
            <div className="right-cont">
              <button type="button" className="cancel-btn"  onClick={() => window.history.back()}>Cancel</button>
              <button type="button" className="save-btn" onClick={handleSaveChanges}>
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesPermissions;
