import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';

const data = [
  { time: '10 AM - 12 PM', online: 4000, cash: 2400, amt: 3000 },
  { time: '12 PM - 2 PM', online: 3000, cash: 1398, amt: 6000 },
  { time: '2 PM - 4 PM', online: 6000, cash: 9800, amt: 9000 },
  { time: '4 PM - 6 PM', online: 2780, cash: 3908, amt: 12000 },
  { time: '6 PM - 8 PM', online: 1890, cash: 4800, amt: 15000 },
  { time: '8 PM - 10 PM', online: 2390, cash: 3800, amt: 18000 },
  { time: '10 PM - 12 AM', online: 3490, cash: 4300, amt: 21000 },
];

const maxDataValue = Math.max(...data.map(d => d.online + d.cash));
const adjustedMaxValue = maxDataValue * 1.5; // Increase domain for more space

const MyLineChart = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='graph-cont'>
      <ResponsiveContainer width="100%" height={isMobile ? 350 : 450}> {/* Adjust height */}
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }} // Increase top margin for labels
          barSize={isMobile ? 20 : 58}
        >
          <XAxis 
            dataKey="time" 
            tick={{ 
              angle: isMobile ? -45 : 0, 
              textAnchor: isMobile ? 'end' : 'middle',
              fontSize: isMobile ? 8 : 12  // Further reduce font size for labels
            }} 
            interval={0}
          />
          {!isMobile && <YAxis domain={[0, adjustedMaxValue]} />} 
          <Tooltip />
          <Bar dataKey="cash" fill="#5d79ff" stackId="a" />
          <Bar dataKey="online" fill="#febd59" stackId="a" radius={[10, 10, 0, 0]}>
            <LabelList
              dataKey="online"
              position="top"
              content={({ x, y, width, value, index }) => {
                const cash = data[index].cash;
                const total = cash + value;
                const adjustedY = y > 20 ? y - 10 : y + 10; // Reduce space above the bar
                return (
                  <text
                    x={x + width / 2}
                    y={adjustedY}
                    fill="#343434"
                    fontSize={isMobile ? "8px" : "10px"} // Further reduce label font size
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    ₹{total}
                  </text>
                );
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MyLineChart;
