import React, { useState, useEffect, useRef ,useContext} from 'react';
import { Link } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import { Table, Checkbox } from 'antd';
import { OrganizationContext } from "../../../Services/OrganizationContext";
import authService from "../../../Services/authService";
import { useNavigate } from 'react-router-dom';
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const AddUser = () => {
  const navigate = useNavigate();
  const { organization } = useContext(OrganizationContext);
  const [role, setRole] = useState([]);
  const [formData, setFormData] = useState({
    role_id: '',
    user_name:'',
    email:'',
    password:'',
    //address:'',
    phone_number:'',
    is_active:'',
    employee_id:'',
  });

  useEffect(() => {
    if (organization) {
       loadRoles();
    }
  }, [organization]);

    const loadRoles = () => {
      const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    // Construct the URL with the organization_id as a query parameter
    const url = new URL(authService.getApiUrl() + "roles");
    url.searchParams.append("organization_id", organization); // Append the organization_id as a query parameter

    fetch(url, {
      method: "GET", // Set method to GET
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`, // Include Authorization header with Bearer token if required
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setRole(result.data); // Set the category data in state
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });
  };


  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const [editorState, setEditorState] = useState(RichTextEditor.createEmptyValue());

  const handleDescriptionChange = (value) => {
    setEditorState(value);
    setFormData({
      ...formData,
      description: value.toString('html'),
    });
  };

  // Track rights state individually for each row
  const [rights, setRights] = useState([
    { key: '1', rightsName: 'Tests Master', read: false, write: false,  },
    { key: '2', rightsName: 'Customer Management', read: false, write: false },
    { key: '3', rightsName: 'Reports', read: false, write: false },
    { key: '4', rightsName: 'Discount Configuration', read: false, write: false,  },
    { key: '5', rightsName: 'Point of Sale Configuration Details', read: false, write: false },
    { key: '6', rightsName: 'Purchase [Inventory]', read: false, write: false },
  ]);

  const handleRightsChange = (key, type) => {
    const updatedRights = rights.map((right) => {
      if (right.key === key) {
        if (type === 'write') {
          // If "Write" is checked, "Read" must also be checked
          return { ...right, write: !right.write, read: !right.write ? true : right.read };
        }
        if (type === 'read') {
          // Only toggle the "Read" checkbox
          return { ...right, read: !right.read };
        }
      }
      return right;
    });
    setRights(updatedRights);
  };

  const columns = [
    {
      title: 'Rights Name',
      dataIndex: 'rightsName',
      key: 'rightsName',
    },
    {
      title: '',
      dataIndex: 'read',
      key: 'read',
      render: (_, record) => (
        <div>
          <label className="custom-checkbox">
            <input
              type="checkbox"
              checked={record.read}
              onChange={() => handleRightsChange(record.key, 'read')}
            />
            <span className="checkmark"></span> {/* This is the custom checkmark */}
            <span className="check-text" style={{ marginLeft: '8px' }}>Read</span>
          </label>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'write',
      key: 'write',
      render: (_, record) => (
        <div>
          <label className="custom-checkbox">
            <input
              type="checkbox"
              checked={record.write}
              onChange={() => handleRightsChange(record.key, 'write')}
            />
            <span className="checkmark"></span> {/* This is the custom checkmark */}
            <span className="check-text" style={{ marginLeft: '8px' }}>Write</span>
          </label>
        </div>
      ),
    },
  ];

    const handleSubmit = async (event) => {
      event.preventDefault();
        const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

      fetch(authService.getApiUrl() + 'user', {
      method: 'POST', // Change method to PUT
      body: JSON.stringify({ user_name: formData.user_name,
                            role_id:formData.role_id,
                            phone_number:formData.phone_number,
                            password:formData.password,
                            employee_id:formData.employee_id,
                            email:formData.email,
                            is_active:formData.is_active ? "1" : "0",
                            organization_id:organization,
}), // Include the data to be updated in the body
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
      },
      })
      .then((res) => res.json())
      .then((result) => {
        console.log(result.data)
        window.history.back()
      })
      .catch((err) => {
          console.log(err.message); // Handle any errors
      });

  };

  return (
    <>
      <div className="main-container">
        <div className="container-fluid">
          <Breadcrumb/>
          <div className="category-table user-add-cont">
            <div className="flex-cont add-cat">
              <div className="heading">
                <h3>Add User</h3>
              </div>
            </div>
            <form className="needs-validation clearfix" onSubmit={handleSubmit} key="addform">
            <div className="card">
              <div className="card-body custom-edit-service">
                {/* Add Test Form */}
                
                  <div className="addtest-fields mb-3">
                    <div className="flex-cont">
                      <div className="half-cont">
                        <div className="form-group">
                          <label>
                            Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="user_name"
                            id="user_name"
                            required
                            value={formData.user_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="half-cont">
                        <div className="form-group">
                          <label>
                            User Role<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select form-control"
                            name="role_id"
                            required
                            value={formData.role_id}
                            onChange={handleInputChange}
                          >
                            <option value="">--Select--</option>
                            {role.map((item, i) => (
                            <option value={item.id} key={i}>{item.name}</option>
                          ))}
                          </select>
                        </div>
                      </div>
                      <div className="half-cont">
                        <div className="form-group">
                          <label>
                            Email
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            id="email"
                            required
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      {/* <div className="half-cont">
                        <div className="form-group">
                          <label>
                            User Code
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="test_name"
                            id="test_name"
                            required
                            value={formData.test_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div> */}
                                            <div className="half-cont">
                        <div className="form-group">
                          <label>
                            Phone
                            
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            name="phone_number"
                            id="phone_number"
                            
                            value={formData.phone_number}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="half-cont">
                        <div className="form-group">
                          <label>
                            Password
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            name="password"
                            id="password"
                            required
                            value={formData.password}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      {/* <div className="half-cont">
                        <div className="form-group">
                          <label>
                            User Password
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            name="test_name"
                            id="test_name"
                            required
                            value={formData.test_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div> */}
                      <div className="half-cont">
                        <div className="form-group">
                          <label>
                            Employee ID
                            
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="employee_id"
                            id="employee_id"
                            
                            value={formData.employee_id}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="full-cont special-case-cont">
                        <div className='form-group'>
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              name="is_active"
                              checked={formData.is_active}
                              onChange={handleInputChange}
                              value="A"
                            />
                            <span className="checkmark"></span>
                            <label>Status </label>
                          </label>
                        </div>
                      </div>
                      
                    </div>

                    {/* <div className="flex-cont">
                      
                      
                      <div className="half-cont">
                        <div className="form-group">
                          <label>Visit Type
                          <span className="text-danger">*</span>
                          </label>
                          <div className='radio-sec'>
                            <div className='radio-btn'>
                                <label>
                                <input
                                    type="radio"
                                    name="visit_type"
                                    value="home"
                                    checked={formData.visit_type === 'home'}
                                    onChange={handleInputChange}
                                />
                                Home Visit
                                </label>
                            </div>
                            <div className='radio-btn'>
                                <label>
                                <input
                                    type="radio"
                                    name="visit_type"
                                    value="hospital"
                                    checked={formData.visit_type === 'hospital'}
                                    onChange={handleInputChange}
                                />
                                Hospital Visit
                                </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="full-cont special-case-cont">
                        <div className='form-group'>
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              name="is_active"
                              checked={formData.is_active}
                              onChange={handleInputChange}
                            />
                            <span className="checkmark"></span>
                            <label>Status</label>
                          </label>
                        </div>
                      </div>

                      
                    </div> */}
                  </div>

                  
               
                {/* /Add Test Form */}
              </div>
            </div>
            {/* <div className='flex-cont user-add-cont'>
              <div className='heading'>
                <h3>User Group Details</h3>
              </div>
            </div>
            <div className='flex-cont user-add-cont'>
                <div className="half-cont">
                    <div className="form-group radio-checkboxes">
                        <div className='radio-sec'>
                        <div className='radio-btn'>
                            <label>
                            <input
                                type="radio"
                                name="visit_type"
                                value="home"
                                checked={formData.visit_type === 'home'}
                                onChange={handleInputChange}
                            />
                            No Group
                            </label>
                        </div>
                        <div className='radio-btn'>
                            <label>
                            <input
                                type="radio"
                                name="visit_type"
                                value="hospital"
                                checked={formData.visit_type === 'hospital'}
                                onChange={handleInputChange}
                            />
                            Captain Kokapet
                            </label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex-cont user-add-cont'>
              <div className='heading'>
                <h4>User Rights <span className="text-danger">*</span></h4>
              </div>
            </div>
            <div className="hospitals-table rights-table">
              <Table
                dataSource={rights}
                columns={columns}
                pagination={false}
                rowKey="key"
              />
            </div>
            <div className='flex-cont user-add-cont'>
              <div className='heading'>
                <h4>Desktop Report Rights <span className="text-danger">*</span></h4>
              </div>
            </div>
            <div className="hospitals-table rights-table">
              <Table
                dataSource={rights}
                columns={columns}
                pagination={false}
                rowKey="key"
              />
            </div> */}
            <div className='save-cont'>
              <div className='right-cont'>
                <button className='cancle-btn' type='button' onClick={() => window.history.back()}>Cancle</button> 
                <button className='save-btn' type="submit">Save Changes</button> 
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
