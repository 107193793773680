// src/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import authService from './authService'; // Assuming this service checks user authentication

const ClientPrivateRoute = ({ element: Component }) => {
  const isAuthenticated = authService.getCurrentuser() ? true : false;

  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default ClientPrivateRoute;
