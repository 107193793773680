import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import CartIcon from '../../Assets/images/CartIcon.png';
import CartSearch from '../../Assets/images/Cart-search.png';
import Location from '../../Assets/images/distance.svg';
import Recommended_center from '../../Assets/images/recommended-center.png';
import Stars from '../../Assets/images/stars.png';
import authService from '../../../Services/authService';

const Hospitals = () => {

    const location = useLocation(); // React Hook
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get('search');
    const [error, seterror] = useState('');

    // Initialize an array of liked states for each package item
    const [likedItems, setLikedItems] = useState(Array(8).fill(false));
    const [Hospitals, setHospitals] = useState([]);
    const [formData, setFormData] = useState({
        search: searchTerm,
    });

    useEffect(() => {
      if(searchTerm=='all'){
        loadHospitals();
      }else{
        loadSearchbyHospitals();
      }

    }, []); 

    const loadHospitals = () => {

        const url = new URL(authService.getApiUrl() + 'organizationslist' );
      
        fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
          .then((res) => {
              return res.json();
          })
          .then((result) => {
            //const data = result.data;
            setHospitals(result.data);

          })
          .catch((err) => {
            console.log(err.message);
          });
    };

    const loadSearchbyHospitals = () => {

        const url = new URL(authService.getApiUrl() + 'searchglobal' );
      
        fetch(url, {
            method: 'POST', // Change method to PUT
            body: JSON.stringify({ search: formData.search,
                                //    organization_id:id,
             }), // Include the data to be updated in the body
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                //Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
            },
        })
          .then((res) => {
              return res.json();
          })
          .then((result) => {
            //const data = result.data;
            if(result.status!='success'){
                seterror('No Results Found');
            }else{
                seterror('');
            }
            setHospitals(result.data);
     
          })
          .catch((err) => {
            console.log(err.message);


          });
    };

    // Toggle the liked status of a specific item
    const handleHeartClick = (index) => {
        setLikedItems((prevState) => 
            prevState.map((liked, i) => (i === index ? !liked : liked))
        );
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });

    };

    return (
        <div className='homepageWrapper'>
            <div className='client-container'>
                <div className='clientprofile-sec'>
                    <div className='profile-title'>
                        <Link to='/'>
                            <img src={Location} alt='Location-img' />
                            <div className="Address-cont">
                                <h3 className='client-name'>Nizampet</h3>
                                <span>Hyderabad, Telangana</span>
                            </div>
                        </Link>
                    </div>
                    <div className='cart-cont'>
                        <Link to='/'>
                            <img src={CartIcon} alt='cart-icon' />
                            <span className='count-t'>2</span>
                        </Link>
                    </div>
                </div>
                <div className='GlobalSearch'>
                    <form className='needs-validation clearfix' onSubmit={loadSearchbyHospitals}>
                        <div className='flex-cont dateinput-cont'>
                            <div className='form-group offer-rel'>
                                <input
                                    type='text'
                                    className='form-control'
                                    onChange={handleInputChange}
                                    name="search" 
                                    id="search"
                                    defaultValue={formData.search}
                                    placeholder='Search Medicine & Diagnosis'
                                    required
                                />
                                <button type='submit' className='search-btn' style={{border:'none'}}>
                                    <img src={CartSearch} alt='search' />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='packages-cont'>
                    <b style={{color:'red'}}>{error}</b>
                    {Hospitals.map((data, index) => (
                        <div className='package-det' key={index}>
                            <figure>
                                
                                    <div className='img-wrap'>
                                        <Link to={'/lab-tests/'+encodeURIComponent(data.name.replace(/ /g, '-'))+'-'+data.id}>
                                            <img src={authService.getApiDomainUrl() + data.image} title='alt-ads' alt='alt-ads'></img>
                                            <div className='star-rating'>
                                                <img src={Stars} alt='star' title='star'></img>
                                                <span className='ratting-count'>4.6</span>
                                                <span>(456)</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <figcaption>                                
                                        <div className='name-like'>
                                            <h3><Link to={'/lab-tests/'+encodeURIComponent(data.name.replace(/ /g, '-'))+'-'+data.id}>{data.name}</Link></h3>
                                            <span
                                                id={`heart-${index}`}
                                                onClick={() => handleHeartClick(index)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {likedItems[index] ? (
                                                    <i className="fa fa-heart" aria-hidden="true"></i>
                                                ) : (
                                                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                                                )}
                                            </span>
                                        </div>
                                        <span className='category'>{data.area_name}</span><br></br>
                                        <ul className='delevery-msg'>
                                            <li>{data.city_name}</li>
                                            <li>4.6Km Distance</li>
                                            {/* <li>Delivery in 24 h</li> */}
                                        </ul>
                                    </figcaption>
                                
                            </figure>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Hospitals;
