// Loading.js
import React from 'react';
//import '../../Assets/css/Client.css'; // Optional if you want to style the loading screen
import Vithyoulogo from '../../Assets/images/vithyoulogo.png'
const Loading = () => {
  return (
    <div className="loading-screen">
      {/* <div className="spinner"></div> */}
      <img src={Vithyoulogo} alt=""></img>
    </div>
  );
};

export default Loading;
