import React from "react";
import Options from '../../Assets/Icons/Options';
import authService from '../../../Services/authService';


const Address = ({ data, isActive, handleNotifyClick, refreshAddressList }) => {

  const handleaddressChange = (data) => {
    const url = new URL(authService.getApiUrl() + 'updateAddress');
    fetch(url, {
        method: 'POST',
        body: JSON.stringify({ 
            user_id: authService.getCurrentuser().id,
            address_id: data.id
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => res.json())
        .then((result) => {
          refreshAddressList();
        })
        .catch((err) => {
            console.log(err.message);
        });
  
  };

  return (
    <div className="saved-add" onClick={(e) => handleaddressChange(data)}>
      <span className="delivery-head">Delivers To</span>
      <div className="main-add">
        <h4>{data.building_name}</h4>
        <p>
            {data.area}
            {/* , {data.city_id} - {data.state_id} */}
        </p>
        <span className="phone-no">Pincode: {data.pincode}</span>
      </div>
      <div className="add-actions">
        <div
          className={`del-edit-options ${isActive ? "active" : ""}`}
          onClick={handleNotifyClick}
        >
          <span><Options /></span>
          <div
            className="notify-dropdown"
            style={{ display: isActive ? "block" : "none" }}
          >
            <ul>
              <li>Edit</li>
              <li>Delete</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
