import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Backbtn from '../../Assets/images/Back-btn.png';
import ProfileImg from '../../Assets/images/Mask group.png';
import Close from '../../Assets/images/close.png';

const Appointment = () => { 
    const [activeTab, setActiveTab] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const openTab = (cityName) => {
        setActiveTab(cityName);
      };
    
    const closeTab = () => {
    setActiveTab(null);
    }; 
 
    const dates = ["Mon 21", "Tue 22", "Wed 23", "Thu 24", "Fri 25", "Sat 26"];
    const timeSlots = {
    "Mon 21": ["09:00 AM", "10:00 AM", "11:00 AM", "01:00 PM", "03:00 PM"],
    "Tue 22": ["10:00 AM", "02:00 PM", "04:00 PM", "07:00 PM"],
    "Wed 23": ["10:00 AM", "02:00 PM", "03:00 PM", "04:00 PM", "07:00 PM"],
    // Add more slots for other dates...
    };
    
      const handleDateClick = (date) => {
        setSelectedDate(date);
        setSelectedTime(null); // Reset selected time when date changes
      };
    
      const handleTimeClick = (time) => {
        setSelectedTime(time);
      };
    
    return (
        <div className='homepageWrapper'>
            <div className='client-container'>
                <div className='add-member-cont'>
                    <div className='back-heading-add'>
                        <div className='back-btn-add'>
                            <Link to='/'><img src={Backbtn} alt='back-btn' title='back-btn'></img></Link>
                        </div>
                        <h1>Select Appointment Time</h1>
                    </div>
                    <div className="appointment-picker">
                        <div className="date-picker">
                            {dates.map((date) => (
                            <button
                                key={date}
                                className={`date-btn ${selectedDate === date ? "selected" : ""}`}
                                onClick={() => handleDateClick(date)}
                            >
                                {date}
                            </button>
                            ))}
                        </div>
                        {selectedDate && (
                            <div className="time-slot-picker">
                            {timeSlots[selectedDate]?.map((time) => (
                                <button
                                key={time}
                                className={`time-slot-btn ${selectedTime === time ? "selected" : ""}`}
                                onClick={() => handleTimeClick(time)}
                                >
                                {time}
                                </button>
                            )) || <p>No slots available for this date</p>}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='add-mem-btn-cont'>
                <div className="client-container">
                    <div className='address-des'>
                        <div className='address-head'>
                            <h3>Address: Home</h3>
                            <Link to="/">Change</Link>
                        </div>
                        <p>Flat no 102, Jasmin creek apartment, Balaji colony, road no 4, Nizampet - 500090</p>
                    </div>
                    <button  onClick={() => openTab('sort_by')} className='add-mem-btn'>Confirm Appointment</button>
                </div>
            </div>
            
            
        </div>
    );
};

export default Appointment;
