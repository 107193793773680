import React, { useState, useEffect, useRef ,useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../Services/authService';
import LoginBanner from '../Assets/images/login-banner.png';
import SiteLogo from '../Assets/images/logo.png'

const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isAuthenticated=authService.getCurrentadmin()?true:false;

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admin/dashboard');
    }
  }, []);

  const validateForm = () => {
    if (!email || !password) {
      setError("Please fill in both fields.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    console.log("Form validated, sending request...");

    try {
    let responce= await authService.login(email, password);
    // console.log(responce);
      if(responce){
        window.location.href = "/admin/dashboard";
      }else{
        setError("Invalid username or password");
        setLoading(false);
      }
    } catch (error) {
      setError("Invalid username or password");
      setLoading(false);
    }


  };

  return (
      <div className="login-bg" style={{backgroundColor: '#FFFCEF'}}>
        <div className="container">
          <div className="login-sec">
            <div className="main-login-cont">
              <div className="logo-heading">
                <img className="site-logo" src={SiteLogo} alt="site-logo" title="site-logo"></img>
              </div>
              <div className="login-cont">
                <div className="login-left">
                  <img src={LoginBanner} alt="login-banner"></img>
                </div>
                <div className="login-right">
                    <div className="login-right-wrap">
                      <h1>Login</h1>
                      <p className="account-subtitle " style={{marginBottom:'10px'}}>Access to our dashboard</p>
                      <form onSubmit={handleSubmit} className="mt-2">
                        {error && <div className="alert alert-danger">{error}</div>}
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="form-group login-btn">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                            // disabled={loading}
                          >
                            {loading ? "Loading..." : "Login"}
                          </button>
                        </div>
                      </form>
                      {/* Additional UI elements */}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
  );
};

export default Login;
