import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const AddLocation = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    area_name: '',
    city_id: '',
    state_id: '',
  });

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
const[errors,setErrors]=useState("");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // if (name === 'state_id') {
    //   loadCities(value);
    // }
    if (name === 'state_id') {
      setFormData((prevData) => ({ ...prevData, city_id: '' }));
      loadCities(value);
    }
  };




  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const ValidateErrors=ValidateForm(formData);
    setErrors(ValidateErrors);

    if(Object.keys(ValidateErrors).length ===0){
      const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    fetch(authService.getApiUrl() + 'area', {
    method: 'POST', // Change method to PUT
    body: JSON.stringify({ area_name: formData.area_name,
                           city_id:formData.city_id,
                           Is_Active:"A"
     }), // Include the data to be updated in the body
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
    },
    })
    .then((res) => res.json())
    .then((result) => {
        //setFormData(result.data); // Set the form data with the result
        navigate(`/admin/locations`);
    })
    .catch((err) => {
        console.log(err.message); // Handle any errors
    });
  }else{
    console.log("form failed to submit due to errors");
  }
};

  useEffect(() => {
    loadStates();
   
  },[]);



  const loadStates = () => {
    const token = authService.getCurrentadmin().token;
    const url = new URL(authService.getApiUrl() + 'states');

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,

      },
    })
      .then((res) => res.json())
      .then((result) => {
        setStates(result.data || []);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const loadCities = (stateId) => {
    const token = authService.getCurrentadmin().token;

    fetch(authService.getApiUrl() + 'statebasedCity', {
      method: 'POST',
      body: JSON.stringify({ state_id: stateId }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCities(result.data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
  const ValidateForm = () => {
    const errors={};
    const nameRegex = /^[a-z A-Z]+$/;
    // Name validation
    if (!formData. area_name) {
      errors. area_name = 'Area name is required';
    } else if (formData. area_name.length < 4) {
      errors. area_name = 'Area name must be at least 4 characters';
    } else if (!nameRegex.test(formData. area_name)) {
      errors. area_name = 'Area name can only contain letters';
    }
    if(!formData.state_id){
      errors.state_id='Select state';
    }
    if(!formData.city_id){
      errors.city_id='Select city';
    }
    return errors;
  }
  return (
    <div className='main-container'>
      <div className='container-fluid'>
        <Breadcrumb/>
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Add Location</h3>
            </div>
          </div>
          <div className="card">
            <div className="card-body custom-edit-service">
              <form className="needs-validation clearfix" onSubmit={handleSubmit}>
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          State <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select form-control"
                          name="state_id"
                          // required
                          value={formData.state_id}
                          onChange={handleInputChange}
                        >
                          <option value="" key="0">--Select--</option>
                          {states.map((item, i) => (
                            <option value={item.id} key={i}>{item.state_name}</option>
                          ))}
                        </select>
                        {errors.state_id &&(<span className='errors' >{errors.state_id}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          City <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select form-control"
                          name="city_id"
                          // required
                          value={formData.city_id}
                          onChange={handleInputChange}
                        >
                          <option value="" key="0">--Select--</option>
                          {cities.map((item, i) => (
                            <option value={item.id} key={i}>{item.city_name}</option>
                          ))}
                        </select>
                        {errors.city_id&&(<span className='errors' >{errors.city_id}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Area Name 
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="area_name"
                          value={formData.area_name}
                          onChange={handleInputChange}
                          // required
                        />
                         {errors.area_name&&(<span className='errors' >{errors.area_name}</span>)}
                      </div>
                    </div>                         
                  </div>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    name="form_submit"
                    onSubmit={ValidateForm()}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLocation;
